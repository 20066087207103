import { Component } from "react";
import PropTypes from 'prop-types';
import "../styles/pageWithCorousel.css";
class PageWithCorousel extends Component {
  constructor(props) {
    super(props);
    this.pageWithCorouselData = props.input;
    this.linksData = props.pagesLinksData;
  }

  render() {
    const settings = {
      lazyLoad: true,
      className: "center",
      centerPadding: "60px",
      infinite: true,
      speed: 1000,
      slidesToShow: 3,
      slidesToScroll: 1,
      initialSlide: 0,
      adaptiveHeight: false,
      autoplay: true,
      autoplaySpeed: 4000,
      dots: false,
      arrows: true,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 2,
            dots: true,
            arrows: false,
          }
        },
        {
          breakpoint: 600,
          settings: {
            infinite: true,
            speed: 1000,
            slidesToShow: 1,
            slidesToScroll: 1,
            initialSlide: 0,
            adaptiveHeight: false,
            autoplay: true,
            autoplaySpeed: 4000,
            dots: true,
            arrows: false,
          }
        },
      ]
    };

    //  let imageElement;
    console.log(this.pageWithCorouselData);
    console.log(this.pageWithCorouselData.primary.page_header_image);
    let back_boolean = this.pageWithCorouselData.primary.back_colr_boolean1;
    let case_boolean = this.pageWithCorouselData.primary.case_boolean;
    let head_boolean = this.pageWithCorouselData.primary.header_pwc_boolean;

    let component_header = this.pageWithCorouselData.primary.component_header.text;
    let image_header = this.pageWithCorouselData.primary.page_header_image.url;
    let description = this.pageWithCorouselData.primary.page_description.html;
    let pageLinkText = this.pageWithCorouselData.primary.page_link_text.text;
    let carouselHeader = this.pageWithCorouselData.primary.carousel_header.text;
    let buttonText = this.pageWithCorouselData.primary.case_study_button_text.text;
    let pageLinkTextUrl = this.pageWithCorouselData.primary.page_link ? this.pageWithCorouselData.primary.page_link.url : '';
    let pageHeader = this.pageWithCorouselData.primary.page_header.text;
    let pagebackgroundColor = this.pageWithCorouselData.primary.page_background_color;
    pagebackgroundColor = pagebackgroundColor.substring(pagebackgroundColor.indexOf('[') + 1, pagebackgroundColor.length - 1);
    let carouselCardElements = [];
    if (this.pageWithCorouselData.items && this.pageWithCorouselData.items.length > 0) {
      this.pageWithCorouselData.items.forEach((element, key) => {
        //  let cardImageElement  = (

        //card
        //   <img
        //   src={element.card_logo.url}
        //   alt="header image"
        //   className="cards-image-corousel d-md-block"
        // />
        // );

        let descriptionElement = (
          <div><div className="align_coro"><h3 className="card_heading1">{element.card_heading.text}</h3>
            {/* <div className="case_underline"></div> */}
          </div>
            <p className={case_boolean ? 'card_description2' : 'card_description1'}>{element.card_description.text}</p>
            <a className={case_boolean ? 'link2' : 'link1'} href={element?.card_link?.url} style={{ textDecoration: 'none' }} hrefLang="en">
              {element.card_link_text.text}
              {/* {element.card_link.url} */}

            </a>
          </div>
        )

        carouselCardElements.push(<div
          className={case_boolean ? 'col-12 col-md-4 col-sm-6' : 'col-12 col-md-4 col-sm-6'}
          key={key}>
          {/* { cardImageElement} */}
          {descriptionElement}

        </div>);
      });



    }



    //.log(image_header);
    return (


      <div
        // style={{backgroundColor:pagebackgroundColor}}
        id={pageHeader}
        className={back_boolean ? 'back_color' : 'no_back_color'}
      >

        <div id={pageHeader.replaceAll(" ", "_")} className="container
            heading-section-container_pwc"
        >
          <div className={head_boolean ? 'no_component_header' : 'component_header'}>{component_header}</div>
          <div className="rect_style1 px-3">
            <div className="align-image"> <img
              src={image_header}
              alt="header image"
              className="header_image_corousel"
            /></div>
            <div >
              <h2 className="page-heading">{pageHeader}</h2>
            </div>
            <div>

              <div className="header-description"
                dangerouslySetInnerHTML={{
                  __html: description
                }} />
              {/* <div className="pwc_desc">{description}</div> */}
              <a className="styled-link-main" href={pageLinkTextUrl} style={{ textDecoration: 'none' }} hrefLang="en">
                {pageLinkText}
              </a>
            </div>

            <h3 className="carousel-header-title">{carouselHeader}</h3>
            <div className="row head_desc_link justify-content-center">
              {carouselCardElements}
            </div>
          </div>
        </div>
      </div>

    )
  }

}

export default PageWithCorousel;

PageWithCorousel.propTypes = {
  input: PropTypes.object,
  pagesLinksData: PropTypes.array
};
