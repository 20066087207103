import React, { Component } from "react";
import PropTypes from "prop-types";
import { getColor } from "../utils/universalHelperFunctions";
import "../styles/home-page-heading-section.css";
import "../styles/location.css";

class Location extends Component {
  constructor(props) {
    super(props);
    this.locationData = props.input;
    this.state = { cultureFlag: "US"};
  }
  componentDidMount() {
    this.getIPandCountryCode();
  }

  getIPandCountryCode() {
    fetch("https://jsonip.com", {mode: 'cors'})
    .then(responseIPDetials => responseIPDetials.json())
    .then(IPDetails => fetch(`https://api.ipstack.com/${IPDetails.ip}?access_key=c04f9cf5cdbfa908261a073d0d6b3265`, {mode: 'cors'}))
    .then(responseCountryCode => responseCountryCode.json())
    .then(countryCode => this.setState({ cultureFlag: countryCode.country_code }));
  }

  render() {
    let finalCardElement = [];
    let firstCardElement = [];
    let singleCardElement = {};
    let addressMapElement = {};
    let isHeadQurterSection =  this.locationData.primary.is_headquarter_section && this.locationData.primary.is_headquarter_section === "Yes" ? true : false; 

    if(isHeadQurterSection && this.state.cultureFlag === "US") {
      this.locationData.items.forEach((item, key) => {
        if(item.location_heading.text === "World") {
          singleCardElement = this.createLocationCards(key);
          finalCardElement.push(singleCardElement);
        }
      });
      this.locationData.items.forEach((item, key) => {
        if(item.location_heading.text !== "World") {
          singleCardElement = this.createLocationCards(key);
          finalCardElement.push(singleCardElement);
        }
      });
    } else {
      this.locationData.items.forEach((item, key) => {
        singleCardElement = this.createLocationCards(key);
        finalCardElement.push(singleCardElement);
      });
    }

    let sectionBackground = this.locationData.primary.office_section_background_color ? getColor(this.locationData.primary.office_section_background_color) : '#fff';
    let sectionHeaderFont = this.locationData.primary.office_section_header_font_color ? getColor(this.locationData.primary.office_section_header_font_color) : '#000';
    let cardAlignment = this.locationData.primary.card_alignment;

    return (
      <div style={sectionBackground && sectionBackground !== '#fff' ? { backgroundColor: sectionBackground, paddingTop: '50px', paddingBottom: '50px', marginBottom: '50px' } : { marginBottom: '50px' }}>
        {
          this.locationData.primary.office_section_heading && this.locationData.primary.office_section_heading.text && (
          <div className="location-card-section-heading" style={{color: sectionHeaderFont}}>{this.locationData.primary.office_section_heading.text}</div>
          )
        }
        <div className="location-card-parent-container container no-padding" style={{justifyContent: cardAlignment}}>
          {finalCardElement}
        </div>
      </div>
    );
  }

  // to generate and return google maps based on the address URL passed.
  generateAdressMap(addressURL) {
    window.open((
      <iframe
        key="locationMap"
        src={addressURL}
        width="100%"
        height="350"
        frameBorder="0"
        allowFullScreen
        style={{ marginBottom: "-7px", marginTop: '62px' }}
      />
    ));
  }

  createLocationCards(key) {
    // get set final individual card object
    var singleCardElement = {};
    
    //Checking for all the card related property exists or not in the reponse object from Prismic
    let locationDisplayImage = this.locationData.items[key].display_image.url;
    let locationImageAltText = this.locationData.items[key].display_image.alt;
    let locationHeading = this.locationData.items[key].location_heading.text;
    let companyName = this.locationData.items[key].company_name.text;
    let locationAddress = this.locationData.items[key].address.raw.length > 1 ? this.locationData.items[key].address.html : this.locationData.items[key].address.raw[0].text;
    let locationPhone = this.locationData.items[key].phone.text;
    let locationEmail = this.locationData.items[key].email.text;
    let locationFontColor = this.locationData.items[key].office_location_font_color ? getColor(this.locationData.items[key].office_location_font_color) : '#000';
    let officeDescriptionFont = this.locationData.items[key].office_description_font_color ? getColor(this.locationData.items[key].office_description_font_color) : '#4a4a4a';
    let locationLinkFont = this.locationData.items[key].location_link_font_color ? getColor(this.locationData.items[key].location_link_font_color) : '#1783FF';
    let locationLinkText = this.locationData.items[key].location_link_text.text;
    let locationURL = this.locationData.items[key].location_url.text;
    
    singleCardElement = (
      <div key={key} className="card-design-location col-12 col-sm-6 col-md-6 col-lg-4">
        {
          locationDisplayImage && (
            <div className="col-md-12 d-flex justify-content-center">
              <img src={locationDisplayImage} alt={locationImageAltText} id="location-img"/>
            </div>
          )
        }
        <div className="col-md-12 d-flex flex-column no-padding">
          <div className="location-heading d-flex justify-content-center" style={{color: locationFontColor}}>{locationHeading}</div>
          <div className="location-address mb-0" style={{color: officeDescriptionFont}}>{companyName}</div>
          <div 
            className="location-address d-flex justify-content-center flex-column"
            style={{color: officeDescriptionFont}}
            dangerouslySetInnerHTML={{
              __html: locationAddress
            }}/>
          <div className="location-address" style={{color: officeDescriptionFont}}>{locationPhone}</div>
          {
            locationEmail && (<a className="location-email" href={`mailto:${locationEmail}`} style={{color: locationLinkFont}} hrefLang="en">
              {locationEmail}
            </a>)
          }
          {
            locationURL && (
              <a className="location-direction-link d-flex justify-content-center" style={{color: locationLinkFont}} target="_blank" href={locationURL} hrefLang="en">
                {locationLinkText}
              </a>
            )
          }
        </div>
      </div>
    );

    return [singleCardElement];
  }
}

export default Location;

Location.propTypes = {
  input: PropTypes.object.isRequired
};
