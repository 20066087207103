import React, { Component } from 'react';
import { Container, Row, Col } from "reactstrap";
import PropTypes from 'prop-types';
import { createUrl, getColor } from "../utils/universalHelperFunctions";
import "../styles/sub-header.css";

class SubHeader extends Component {
  constructor(props) {
    super(props);
    this.linksData = props.pagesLinksData; 
  }
  iterateColumn(typedData) {
    let subLinkSubTextFontColor = this.props.subMenuData.primary.sub_link_sub_text_font_color ? getColor(this.props.subMenuData.primary.sub_link_sub_text_font_color) : '#fff';
    let subLinkTypeFontColor = this.props.subMenuData.primary.sub_link_type_font_color ? getColor(this.props.subMenuData.primary.sub_link_type_font_color) : '#fff';
    let subNavLinkLabelFontColor = this.props.subMenuData.primary.sub_nav_link_label_font_color ? getColor(this.props.subMenuData.primary.sub_nav_link_label_font_color) : '#fff';
      
    return (typedData.map((typeD,j, arr)=>(
      <div className={`menu-list-row`} key={typeD.type || j} style={{color: subLinkTypeFontColor, paddingRight: j === arr.length - 1 ? '0px': '20px', marginBottom: '35px'}}>
        <h4>{typeD.type}</h4>
        {typeD.data.map((typeDD,i) => (
          <div className="menu-item" key={typeDD.sub_link_sub_text.text}>
            <a className="styled-link" href={typeDD.sub_nav_link && typeDD.sub_nav_link.url ? createUrl(typeDD.sub_nav_link.url, this.linksData):''} hrefLang="en">
              <h5 className="capitalizedText" style={{color: subNavLinkLabelFontColor}}>{typeDD.sub_nav_link_lable.text}</h5>
              <p style={{color: subLinkSubTextFontColor}}>{typeDD.sub_link_sub_text.text}</p>
            </a>
          </div>
        ))} 
      </div>
    )))
  }

  getCountryInfo = () => {
    if (typeof localStorage !== 'undefined') {
        return localStorage.getItem('countryCode')
    }
}

  render() {
    const subMenuData  = this.props.subMenuData.items;
    if(!subMenuData || subMenuData.length===0){
      return null;
    }
    else{
      const gridCount = 12;
      let labelFontColor = this.props.subMenuData.primary.label_font_color ?  getColor(this.props.subMenuData.primary.label_font_color) : '#fff';
      let labelFontColorOnHover = this.props.subMenuData.primary.label_font_color_on_hover ? getColor( this.props.subMenuData.primary.label_font_color_on_hover) : '#fff';
      let navItemBackgroundColor = this.props.subMenuData.primary.nav_item_background_color ? getColor(this.props.subMenuData.primary.nav_item_background_color) : '#1C355E';
      let sunLinkSeperatorLineColor = this.props.subMenuData.primary.sub_link_separator_line_color ? getColor(this.props.subMenuData.primary.sub_link_separator_line_color) : '#6A8DBF';
      let subLinkSubTextFontColor = this.props.subMenuData.primary.sub_link_sub_text_font_color ? getColor(this.props.subMenuData.primary.sub_link_sub_text_font_color) : '#fff';
      let subLinkTypeFontColor = this.props.subMenuData.primary.sub_link_type_font_color ? getColor(this.props.subMenuData.primary.sub_link_type_font_color) : '#fff';
      let subNavLinkLabelFontColor = this.props.subMenuData.primary.sub_nav_link_label_font_color ? getColor(this.props.subMenuData.primary.sub_nav_link_label_font_color) : '#fff';
      let column_count = this.props.subMenuData.primary.nav_column_count ? Math.floor(gridCount/this.props.subMenuData.primary.nav_column_count): 4; 
      
      let isTyped = false;
      for(let i=0;i<subMenuData.length;i++){
        if(subMenuData[i].sub_link_type.text && subMenuData[i].sub_link_type.text != ""){
          isTyped = true;
        }
        else{
          isTyped = false;
          break;
        }
      }     
      if(isTyped){
        
        let types = new Set(subMenuData.map(function (subMenu) {
          return subMenu.sub_link_type.text;
        }));
        if(this.getCountryInfo() !== "IN") {
          types.delete('Walk-in Events');
        }
        let typedData = [];
        console.log('types:', types);
        types.forEach((type)=>{
          
          const td = {type:type,data:[]};
          td.data =  subMenuData.filter(data=>data.sub_link_type.text === type);
          td.column_position = td.data[0].sub_nav_column_position;
          typedData.push(td);
        });
        
        let arr = [];
        if(typedData[0].column_position) {
          for(var i=1; i<=this.props.subMenuData.primary.nav_column_count; i++) {
            let columnWiseTypedData = typedData.filter(item => {
              return item.column_position == i;
            });

            arr.push(<Col className={`menu-list-column col-md-${column_count} no-padding`} key={i}>
              {this.iterateColumn(columnWiseTypedData)}
            </Col>
            );
          }
        }
        return (
          <header className="main-sub-header" style={{boxShadow: "0 2px 4px 0 rgba(0,0,0,0.5)", background: navItemBackgroundColor}}>
            {!typedData[0].column_position
              ? (
                <Container className="menu-list-row">
                  <Row className="noGutters">
                    {typedData.map((typeD,j, arr)=>(
                      <Col className={`menu-list-column col-md-${column_count}`} key={typeD.type} style={{color: subLinkTypeFontColor}}>
                        <h4>{typeD.type}</h4>
                        {typeD.data.map((typeDD,i) => (
                          <div className="menu-item" key={typeDD.sub_nav_link_lable.text}>
                            <a className="styled-link" href={typeDD.sub_nav_link && typeDD.sub_nav_link.url ? createUrl(typeDD.sub_nav_link.url, this.linksData):''} hrefLang="en">
                              <h5 className="capitalizedText" style={{color: subNavLinkLabelFontColor}}>{typeDD.sub_nav_link_lable.text}</h5>
                              <p style={{color: subLinkSubTextFontColor}}>{typeDD.sub_link_sub_text.text}</p>
                            </a>
                            
                          </div> 
                        ))} 
                      </Col>
                    ))}
                  </Row>
                </Container>
              )
              : (
                <Container className="menu-list-row">
                  {arr}
                </Container>
              )
            }
          </header>
        );
      }
      else{
        let partiallyTyped = false;let typedData = [];
        for(let i=0;i<subMenuData.length;i++){
          if(!subMenuData[i].sub_link_type.text || subMenuData[i].sub_link_type.text == ""){
            partiallyTyped = false;
          }
          else{
            partiallyTyped = true;
            break;
          }
        }
        if(partiallyTyped){
          let types = new Set(subMenuData.map(function (subMenu) {
            //if()
            return subMenu.sub_link_type.text||"";
          }));
          typedData = [];
          types.forEach((type)=>{
            
            const td = {type:type,data:[]};
            td.data =  subMenuData.filter(data=>data.sub_link_type.text === type);
            typedData.push(td);
          });
        }   
        return (
          <header className="main-sub-header" style={{boxShadow: "0 2px 4px 0 rgba(0,0,0,0.5)", background: navItemBackgroundColor}}>
            <Container className="menu-list-row">
              <Row className="noGutters">
              {subMenuData.map((subMenu, k, arr)=>(
                <Col className={`menu-list-column col-md-${column_count}`} key={subMenu.sub_nav_link_lable.text} style={{display:subMenu.sub_link_type.text?"none":"inline-block", color: subLinkTypeFontColor}}>
                  <div className="menu-item">
                    <a className="styled-link" href={subMenu.sub_nav_link && subMenu.sub_nav_link.url ? createUrl(subMenu.sub_nav_link.url, this.linksData) : ''} hrefLang="en">
                      <h4 style={{color: subNavLinkLabelFontColor}}>{subMenu.sub_nav_link_lable.text}</h4>
                      <p style={{color: subLinkSubTextFontColor}}>{subMenu.sub_link_sub_text.text}</p>
                    </a>    
                  </div> 
                </Col>
              ))}
              {typedData.map((typeD,j)=>(
                <Col className={`menu-list-column col-md-${column_count}`} key={typeD.type} style={{display:typeD.type?"inline-block":"none"}}>
                  <h4>{typeD.type}</h4>
                  {typeD.data.map((typeDD,i) => (
                    <div className="menu-item" key={typeDD.sub_nav_link_lable.text} style={{display:typeDD.sub_link_type.text?"inline-block":"none"}}>
                      <a className="styled-link" href={typeDD.sub_nav_link && typeDD.sub_nav_link.url ? createUrl(typeDD.sub_nav_link.url, this.linksData):''} hrefLang="en">
                        <h5 style={{color: subNavLinkLabelFontColor}}>{typeDD.sub_nav_link_lable.text}</h5>
                        <p style={{color: subLinkSubTextFontColor}}>{typeDD.sub_link_sub_text.text}</p>
                      </a>      
                    </div> 
                  ))} 
                </Col>
              ))}
              </Row>
            </Container>
          </header>
        );
      }
    }
  }
}

export default SubHeader;

SubHeader.propTypes = {
  subMenuData: PropTypes.shape({
    primary: PropTypes.object,
    items: PropTypes.array,
  }),
  pagesLinksData: PropTypes.array
};


