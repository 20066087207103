import React, { Component } from "react";
import PropTypes from "prop-types";
import "../styles/s-pattern.css";
import VideoOverlay from "./VideoOverlay";
import ImageMagnifierComponent from "./ImageMagnifierComponent";
import { getColor, createUrl } from "../utils/universalHelperFunctions";

class SPattern extends Component {
  constructor(props) {
    super(props);
    this.linksData = props.pagesLinksData;
    this.patternData = props.patternData;
    this.state = {
      isFocused: false,
      activeIndex: '',
    };
    this.handleMouseEnter = this.handleMouseEnter.bind(this);
    this.handleMouseLeave = this.handleMouseLeave.bind(this);
  }
  handleMouseEnter(activeIndex) {
    this.setState({ isFocused: true, activeIndex: activeIndex });
  }
  handleMouseLeave(activeIndex) {
    this.setState({ isFocused: false, activeIndex: activeIndex });
  }
  render() {
    let headerElement = null;
    if (this.patternData.primary && this.patternData.primary.s_pattern_title && this.patternData.primary.s_pattern_title.text) {
      headerElement = (
        <h2 className='col-12 col-md-9 pattern_section_title'>
          {this.patternData.primary.s_pattern_title.text}
        </h2>
      )
    }
    let id = "";
    if (this.patternData.primary.hasOwnProperty("section_breadcrumb_id")) {
      id = this.patternData.primary.section_breadcrumb_id.text;
    }
    return (
      <div id={id}>
        <div className="container">
          <div className="row justify-content-center">
            {headerElement}
          </div>
        </div>
        {
          this.patternData.items.map((pattern, i) => {
            let id = "";
            let hasVideoSection = pattern.s_pattern_video && pattern.s_pattern_video.embed_url ? true : false;
            if (pattern.hasOwnProperty("breadcrumb_id")) {
              id = pattern.breadcrumb_id.text;
            }

            let buttonStyleObj = {
              backgroundColor: pattern.s_pattern_button_color ? getColor(pattern.s_pattern_button_color) : '#3498db',
              boderColor: pattern.s_pattern_button_font_color ? getColor(pattern.s_pattern_button_font_color) : '#fff',
              color: pattern.s_pattern_button_font_color ? getColor(pattern.s_pattern_button_font_color) : '#3498db',
            };
            let buttonStyleHoverObj = {
              backgroundColor: pattern.s_pattern_button_font_color ? getColor(pattern.s_pattern_button_font_color) : '#fff',
              boderColor: pattern.s_pattern_button_color ? getColor(pattern.s_pattern_button_color) : '#3498db',
              color: pattern.s_pattern_button_color ? getColor(pattern.s_pattern_button_color) : '#3498db',
            };
            let buttonFontStyle = {
              display: pattern.button_link && pattern.button_link.url ? "flex" : "none",
              color: pattern.s_pattern_button_font_color ? getColor(pattern.s_pattern_button_font_color) : '#3498db',
            };
            let buttonElement = null;
            if (pattern.button_link && pattern.button_link.url) {
              if (pattern.button_type === "Link") {
                buttonElement = (
                  <a style={buttonFontStyle} className="pattern_button_link" href={pattern.button_link ? createUrl(pattern.button_link.url, this.linksData) : ""} hrefLang="en">
                    {pattern.button_text.text}
                    <i className="fas fa-long-arrow-alt-right media-button-icon"></i>
                  </a>
                );
              } else {
                buttonElement = (
                  <a 
                    style={this.state.activeIndex===i && this.state.isFocused ? buttonStyleHoverObj : buttonStyleObj} 
                    onMouseEnter={ (e) => (this.handleMouseEnter(i))} 
                    onMouseLeave={(e) => (this.handleMouseLeave(i))} 
                    className="pattern-card-button"
                    href={pattern.button_link ? createUrl(pattern.button_link.url, this.linksData) : ""}
                    hrefLang="en"
                  >
                    {pattern.button_text.text}
                  </a>
                )
              }
            }

            return (
              <div key={i} style={{ backgroundColor: pattern.s_pattern_background_color === "Grey" ? "#ecf0f1" : "#FFFFFF" }} className={`${hasVideoSection ? 'video-pattern-container': 'pattern-container'}`} id={id}>
                <div className="align-items-center container">
                  <div className={"row row-eq-height " + (pattern.s_pattern_image_position === "Right" ? pattern.s_pattern_image && pattern.s_pattern_image.url ? 'flex-row-reverse' : 's-pattern-section-direction' : '')}>
                    <div className={`col-12 ${hasVideoSection ? 'col-md-5 video-box': 'col-md-6 img-box'}`} style={pattern.s_pattern_image_position === "Right" ? {paddingLeft: '15px', paddingRight: '10px'}: {paddingRight: '15px'}}>
                      {
                        pattern.s_pattern_image && pattern.s_pattern_image.url ? pattern.require_image_magnifier && pattern.require_image_magnifier === "true" ? <ImageMagnifierComponent imageData={pattern.s_pattern_image} /> : (
                          <ImageMagnifierComponent imageData={pattern.s_pattern_image} />
                        ):null
                      }
                      {
                        (hasVideoSection) ? (
                          <div className={`s-pattern-video-holder col-12 no-padding`} style={{border: '1px solid #D8D8D8'}}>
                            <VideoOverlay iconclass={pattern.s_pattern_video_icon_class && pattern.s_pattern_video_icon_class.text ? pattern.s_pattern_video_icon_class.text : 'far fa-play-circle'} color={pattern.hero_video_icon_color ? getColor(pattern.s_pattern_video_icon_color) : '#000'} videoThumbnail={pattern.s_pattern_video_thumbnail} videoLink={pattern.s_pattern_video_link} videoDetails={pattern.s_pattern_video} pagesLinksData={this.linksData} />
                          </div>
                        ) : ''
                      }
                    </div>
                    <div className={`col-12 ${hasVideoSection ? 'col-md-7' : 'col-md-6'} content-box`}>
                      <div className="">
                        <h3 className="pattern-heading">{pattern.s_pattern_heading.text}</h3>
                        {
                          pattern.s_pattern_bold_heading && pattern.s_pattern_bold_heading.text ? <h1 className="pattern-bold-heading">{pattern.s_pattern_bold_heading.text}</h1> : null
                        }
                        <p className="pattern-sub-heading">{pattern.s_pattern_subheading.text}</p>
                        {buttonElement}
                      </div>
                    </div>
                  </div>
                </div>
              </div >
            )
          }
          )
        }
      </div >
    );


  }
}
export default SPattern;
SPattern.propTypes = {
  patternData: PropTypes.object.isRequired,
  pagesLinksData: PropTypes.array
}
