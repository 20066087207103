import React, { Component } from 'react';
import PropTypes from 'prop-types';
import "../styles/imageWithData.css";
class ImageWithData extends Component {
  constructor(props) {
    super(props);
    this.imageWithData = props.input;
    this.linksData = props.pagesLinksData;
  }


  render() {
    let description = this.imageWithData.primary.description.html;
    return (
      <div className="image-background" >
        <div className="container "  >
          <div className="row">
            <div className="col-lg-8 col-md-8 col-sm-12 col-xs-12">
              <div className=" our-partnership ">{this.imageWithData.primary.header.text}</div>
              <div className=" relationships-are-ev">
                {this.imageWithData.primary.line_description.text}
              </div>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12 group-22">
              <img width="100%"
                src={this.imageWithData.primary.relationship_image.url}
                alt="header image"
                className="img_iwd"

              />
              {/* <div className="specialized">{this.imageWithData.primary.relationship_sub_text_part_1.text} 
                <span className="adobe-experience-man-1">  {this.imageWithData.primary.relationship_sub_text_part_2.text}</span></div>   */}
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <div className="gspann-is-an-adobe-s">
                <div className="header_description_top" dangerouslySetInnerHTML={{
                  __html: description

                }} />
              </div>
            </div>
          </div>
        </div>
      </div>
    );

  }
}


export default ImageWithData;
ImageWithData.propTypes = {
  input: PropTypes.object,
  pagesLinksData: PropTypes.array
};