import React from "react";
import { AvForm, AvField } from "availity-reactstrap-validation";
import "../styles/contact-form.css";
import {
  Col,
  Row,
  Button,
  FormGroup,
} from "reactstrap";

export default class ResourceForms extends React.Component {
  constructor(props) {
    super(props);

    //setting resourceFormData from Slice Zone
    this.resourceFormData = this.props.input;

    // If form submit valid entries, then handleValidSubmit function fires
    this.handleValidSubmit = this.handleValidSubmit.bind(this);

    // If form submit invalid entries, then handleInvalidSubmit function fires
    this.handleInvalidSubmit = this.handleInvalidSubmit.bind(this);
    this.state = { data: {}, contactFormFields: {} };

    this.DummyFormFields = [
      {
        name: "FirstName",
        type: "input",
        placeholder: "First Name 1*",
        id: "fName",
        cssClass: "all-form-input"
      },
      {
        name: "LastName",
        type: "input",
        placeholder: "last Name 2*",
        id: "lName",
        cssClass: "all-form-input"
      },
      {
        name: "Work Email",
        type: "email",
        placeholder: "Email 3*",
        id: "email",
        cssClass: "all-form-input"
      },
      {
        name: "PhoneNo",
        type: "input",
        placeholder: "Phone Number 4*",
        id: "phone",
        cssClass: "all-form-input"
      },
      {
        name: "Message",
        type: "text",
        placeholder: "Message Dummy 5*",
        id: "message",
        cssClass: "all-form-message"
      }
    ];
  }

  //creating connection with Contact Data API for valid form entries.
  handleValidSubmit(event, values) {
    this.setState({ data: values });
    console.log("this. 67 @resource forms", this);
    fetch("https://webapi.gspann.com/api/ContactUs", {
      crossDomain: true,
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(values)
    })
      .then(res => {
        console.log(res);
      })
      .catch(err => {
        console.log(err);
      });
  }

  // showing error in filling invalid entries.
  handleInvalidSubmit(event, errors, values) {
    console.log(errors, values);
  }

  render() {
    //to save the value of Download form Heading
    let downloadFormHeading;

    //checking if resource form data contains download_form_heading property
    // , if yes then setting the value in downloadFormHeading
    if (this.resourceFormData.primary.hasOwnProperty("download_form_heading")) {
      downloadFormHeading = this.resourceFormData.primary.download_form_heading
        .text;
    }

    //to save the value of download Form Description
    let downloadFormDescription;

    //checking if resource form data contains download_form_description property
    // , if yes then setting the value in downloadFormDescription
    if (
      this.resourceFormData.primary.hasOwnProperty("download_form_description")
    ) {
      downloadFormDescription = this.resourceFormData.primary
        .download_form_description.text;
    }

    //to save the value of submit Button Text
    let submitButtonText;

    // checking if resource form data contains submit_button_text property
    // , if yes then setting the value in submitButtonText
    if (this.resourceFormData.primary.hasOwnProperty("submit_button_text")) {
      submitButtonText = this.resourceFormData.primary.submit_button_text.text;
    } else {
      submitButtonText = null;
    }

    let rowElement = [];

    for (let i = 0; i < this.DummyFormFields.length; i++) {
      let columnElements = [];

      for (let j = i; j <= i + 1; j++) {
        columnElements = (
          <Col md={6}>
            <FormGroup>
              <AvField
                type={this.DummyFormFields[i].type}
                name="FirstName"
                id="fName"
                placeholder={this.DummyFormFields[i].placeholder}
                bsSize="lg"
                className={this.DummyFormFields[i].cssClass}
                validate = {{
                    required:{value: true, errorMessage: `Please enter First name`},
                    pattern:{value: "^[A-Z a-z]*$", errorMessage: `Only letters are allowed in the First name`},
                }}
              />
            </FormGroup>
          </Col>
        );
      }

      rowElement.push(columnElements);
    }

    return (
      <div className="slice-container">
        <h6 style={{ fontWeight: "normal", fontSize: ".9rem" }}>
          {downloadFormHeading}
        </h6>
        <h4 style={{ color: "#000000", fontWeight: "normal" }}>
          {downloadFormDescription}
        </h4>
        <AvForm
          className="form-container"
          onValidSubmit={this.handleValidSubmit}
          onInvalidSubmit={this.handleInvalidSubmit}
        >
          <Row form>{rowElement}</Row>

          <Button color="primary" className="submit-btn">
            {submitButtonText}
          </Button>
        </AvForm>
      </div>
    );
  }
}
