import React, { Component } from "react";
import PropTypes from "prop-types";
import Helmet from 'react-helmet';
import {
  Container,
  Row,
  Col
} from "reactstrap";
import { createUrl, getFormattedDate } from "../utils/universalHelperFunctions";
import "../styles/job-position.css";

class JobPosition extends Component {
  constructor(props) {
    super(props);

    this.Country = null;

    this.getLocationName = this.getLocationName.bind(this);
    this.handleTwitterSocialMediaClick = this.handleTwitterSocialMediaClick.bind(this);
    this.handleLinkedInSocialMediaClick = this.handleLinkedInSocialMediaClick.bind(this);
    this.handleSocialMediaClick = this.handleSocialMediaClick.bind(this);
  }

  getLocationName(name) {
    if (name.includes("USA")) {
      return name.slice(0, name.indexOf(","));
    }
    return name;
  }

  handleSocialMediaClick (e) {
    e.preventDefault();
    let url = `https://www.facebook.com/sharer.php?u=${encodeURIComponent(this.shareURL)}&display=popup`;
    window.open(url);
  }

  handleLinkedInSocialMediaClick = (e) => {
    e.preventDefault();
    let source = typeof window !== 'undefined' ? window.location.hostname : 'Gspann';
    let url = `https://www.linkedin.com/shareArticle?mini=true&url=${encodeURIComponent(this.shareURL)}&title=${encodeURIComponent(this.props.input.title.text)}&summary=${encodeURIComponent(this.props.input.description.text)}&source=${encodeURIComponent(source)}`;
    window.open(url);
  }

  handleTwitterSocialMediaClick = (e) => {
    e.preventDefault();
    let url = `https://twitter.com/share?url=${encodeURIComponent(this.shareURL)}&text=${encodeURIComponent(this.props.input.description.text)}`;
    window.open(url);
  }

  render() {
    const { input } = this.props;

    var isUSA = input && input.job_location && input.job_location.text.includes("USA");

    this.Country = isUSA;

    try {
      var customDate = input.job_published_date ? getFormattedDate(input.job_published_date) : '';
    } catch (error) {
      console.log(error); 
    }
    this.shareURL = typeof window !== 'undefined' ? `${window.location.origin}/${input.href}`.toLowerCase() : '';
    let source = typeof window !== 'undefined' ? window.location.hostname : 'Gspann';

    let metaScript = (
      <Helmet>
        <meta property="og:title" content={'Find a Position in Our Latest Jobs Opening'} />
        <meta property="og:description" content={'Job seekers, here’s a golden opportunity for you to work in an organization that promotes openness, collaboration, and inclusion.Got what it takes? Check out this page for opportunities and apply for positions online.'} />
        <meta  name="job-image" property="og:image" content="https://images.prismic.io/gspann/1f4f1bc1-78d1-4b5b-ae1e-118c8d74c6df_job.jpeg?auto=compress,format"/>
        <meta name="twitter:card" content="summary_large_image"/>
        <meta name="twitter:image" content="https://images.prismic.io/gspann/987c7034-aabb-44c4-b1ca-65fef983fc74_Hdpi+-+1440+x+500.jpg?auto=compress,format"/>
      </Helmet>
    );

    return (
      <Container className="job-position-container no-padding">
        {metaScript}
        <Row noGutters>
          <Col md={12}>
            <a className="job-position-link" href={createUrl(input.href, this.props.pagesLinksData)} hrefLang="en">
              <h4 className="job-position">{input.title && input.title.text}</h4>
              <div className="job-date">
                {
                  customDate && (
                    <>
                      <span>{input.job_published_date_field_name} {customDate}</span>
                      <span className="text-seperator">  |  </span>
                      <span>{input.location_field_name} {this.getLocationName(input.job_location.text)}</span>
                      <span className="text-seperator">  |  </span>
                    </>
                  )
                }
                <span>{input.role_type_field_name} {input.job_role_type && input.job_role_type.text}</span>
                <span className="text-seperator">  |  </span>
                <span>{input.job_experience_field_name} {input.experience_required && input.experience_required.text}</span>
              </div>
              <p 
                className="job-description"
                dangerouslySetInnerHTML={{
                  __html:
                  input && input.description ? input.description.raw.length > 1 ? input.description.html : input.description.raw[0].text : '',
                }}
              />
            </a>
            <div>
              <div className="social-media-text">Share this job</div>
              <a
                href=''
                target="_blank"
                onClick={this.handleSocialMediaClick}
                hrefLang="en"
              ><i className="fab fa-facebook-square social-media-icon"></i></a>
              <a
                href=''
                target="_blank"
                onClick={this.handleLinkedInSocialMediaClick}
                hrefLang="en"
              ><i className="fab fa-linkedin ml-2 social-media-icon"></i></a>
              <a
                href=''
                target="_blank"
                onClick={this.handleTwitterSocialMediaClick}
                hrefLang="en"
              ><i className="fab fa-square-x-twitter ml-2 social-media-icon"></i></a>
            </div>
          </Col>
        </Row>
      </Container>
    );
  }
}
export default JobPosition;

JobPosition.propTypes = {
  input: PropTypes.object
};
