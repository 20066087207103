import React from 'react';
import PropTypes from 'prop-types';
import { Container } from 'reactstrap';
import { getColor, createUrl } from "../utils/universalHelperFunctions";
import '../styles/resource-tags.css';
import { useEffect } from 'react';

function ResourceTagItem(props) {
  const { tagDetails } = props;

  useEffect(() => {
    if (localStorage.getItem('fromCarousel')) {
      // 'Adobe'
      // this.handleTagClick('Adobe')

      localStorage.setItem('resourceTagFlag', 'true');
      localStorage.setItem('resourceTag', JSON.stringify('Adobe'));
      if (window.location.pathname.match(/resources\/all-resources/g) && window.location.pathname.match(/resources\/all-resources/g).length > 0) {
        props.selectedFilter([{ type: 'resourceTags', value: 'Adobe' }]);
      } else {
        window.location.href = createUrl(props.input.primary.redirection_link ? props.input.primary.redirection_link.url : 'resources/all-resources/', props.pagesLinksData);
      }
    }
    localStorage.setItem('fromCarousel', '')
  }, [])
  
  useEffect(() => {
    return () => {
      localStorage.setItem('fromCarousel', '')
    }
  }, [])

  const getHashUrl = () => {
    let val =   localStorage.getItem('resourceTag') ? `#${localStorage.getItem("resourceFilter")}#${localStorage.getItem('resourceTag')}` : `#${localStorage.getItem("resourceFilter")}`;
    return val;
  }

  function handleTagClick(event) {
    event.stopPropagation();
    localStorage.setItem('resourceTag', event.target.id);
    if (window.location.pathname.match(/resources\/all-resources/g) && window.location.pathname.match(/resources\/all-resources/g).length > 0) {
      props.selectedFilter([{ type: 'resourceTags', value: event.target.id }]);
    } else {
      let firstPart = "resources/all-resources/";
      window.location.hash = getHashUrl();
      window.location.pathname = `${firstPart}`;      
    }
    window.location.hash = getHashUrl();
  }

  let tagColor = props.input.primary.tag_font_color ? getColor(props.input.primary.tag_font_color) : '#000000';
  let tagBackgroundColor = props.input.primary.tag_background_color ? getColor(props.input.primary.tag_background_color) : '#E3E9EC';
  if (tagDetails.selected) {
    tagBackgroundColor = '#85929E';
    tagColor = '#fff';
  }
  return (
    <a
      className="resource-tag-link"
      role="button"
      tabIndex="-1"
      id={tagDetails.tag_keywords_list}
      style={{ color: tagColor, backgroundColor: tagBackgroundColor, textDecoration: 'none', border: 'none', outline: 'none' }}
      onClick={handleTagClick}
    >
      {tagDetails.tag_keywords_list}
    </a>
  );
}
ResourceTagItem.prototype = {
  tagDetails: PropTypes.object.isRequired,
};

function ResourceTags(props) {
  const found = props.tagsData.some(el => el.tag_keywords_list === 'All Topics');
  let keyWordList = !found ? [{ tag_keywords_list: "All Topics" }, ...props.tagsData] : props.tagsData;
  keyWordList = keyWordList.filter(obj => obj.tag_keywords_list !== null);
  const tagItems = keyWordList.map((element) => <ResourceTagItem input={props.input} tagDetails={element} key={element.tag_keywords_list} pagesLinksData={props.pagesLinksData} selectedFilter={props.selectedFilter} />);
  // console.log("tagItems",tagItems);
  const containerBackgroundColor = props.input.primary.tag_container_background_color ? getColor(props.input.primary.tag_container_background_color) : '#fff';
  const tagColor = props.input.primary.tag_font_color ? getColor(props.input.primary.tag_font_color) : '#000000';
  const gridSize = 'col-12';

  let isNewDesign = process.env.NEW_CASE_STUDY_DESIGN && process.env.NEW_CASE_STUDY_DESIGN === 'true' ? true : false;
  let containerStyle = {};
  if (isNewDesign) {
    containerStyle = props.resourceType && (props.resourceType === "Case Study" || props.resourceType === 'White Paper') ? { paddingTop: '20px' } : {};
  }
  return (
    <div style={{ backgroundColor: containerBackgroundColor }} className={props.classNameData ? props.classNameData : ""}>
      {/* reverting new case study design, uncomment the changes */}
      <Container className="tag-container" style={containerStyle}>
        {
          props.input.primary.tag_section_heading && props.input.primary.tag_section_heading.text ? <h2 className='tag-section-heading' style={props.resourceType ? { color: tagColor } : { color: tagColor }}>{props.input.primary.tag_section_heading.text}</h2> : ''
        }
        <div
          className={`row ${gridSize} no-padding no-margin`}
        >
          {tagItems}
        </div>
      </Container>
    </div>);
}

export default ResourceTags;

ResourceTags.prototype = {
  tagsData: PropTypes.array.isRequired,
};
