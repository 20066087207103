import { Component } from "react";
import PropTypes from "prop-types";
import "../styles/multiCardsWithImages.css";

class AdobeSlice extends Component {
  constructor(props) {
    super(props);
    this.adobeCaseStudyData = props.input;
    this.linksData = props.pagesLinksData;
  }
  render() {
    let carouselCardElements = [];
    this.adobeCaseStudyData.items.forEach((element, key) => {
      let cardImageElement = (
        <img
          src={element.casestudy_link.url}
          alt="header image"
          className="group d-md-block"
        />
      );

      let descriptionElement = (
        <div className="multi-main">
          <div>
            <b>{element.casestudy_heading.text}</b>
          </div>
          <p>{element.casestudy_description.text}</p>
        </div>
      );

      carouselCardElements.push(
        <div className="col-lg-4 col-md-4 col-sm-12" key={key}>
          {cardImageElement}
          {descriptionElement}
        </div>
      );
    });
    return (
      <div>
        <div className="container heading-section-container">
          <div>
            <h2 className="page-heading">
              {this.adobeCaseStudyData.primary.casestudy_heading.text}
            </h2>
          </div>
          <div
            className="header-description-multi"
            dangerouslySetInnerHTML={{
              __html:
                this.adobeCaseStudyData.primary.casestudy_description.text,
            }}
          />

          <div className="multi-items">{carouselCardElements}</div>
        </div>
      </div>
    );
  }
}

export default AdobeSlice;

AdobeSlice.propTypes = {
  input: PropTypes.object,
  pagesLinksData: PropTypes.array,
};
