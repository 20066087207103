import React, { useState, useEffect } from 'react'
import { useStaticQuery, graphql } from "gatsby";
import { Col, Row, Modal, ModalBody, Container, } from "reactstrap";
import { FormDisplaySection2 } from "slices";
import "../styles/image-magnifier-component.css";

function AlertModal(props) {
  
  const {allSlices, onCloseAlert, prismicPopupModal} = props;
  // const { page_content  } = prismicPopupModal || {};
  // const [s] = page_content
  const { body  } = prismicPopupModal || {};
  //const [s] = body
  let emailTemplateData = allSlices.filter((slice) =>
        [
          "PrismicResourceBodyEmailTemplateLinks",
          "PrismicPagePageContentEmailTemplateLinks",
          "PrismicHomepagePageContentEmailTemplateLinks",
          "PrismicJobDescriptionPageBodyEmailTemplateLinks",
        ].includes(slice.__typename)
      );
  // console.log(":: POPUP MODAL ::", page_content);
  // const {
  //   is_active = false,
  //   link_for_image: { url },
  //   modal_image: { url: imageUrl },
  //   modal_content: { text: htmlContent }, 
  //   modal_title: { text: htmlTitle }
  // } = data || {};
  const [open, setOpen] = useState(props.isOpen);

  useEffect(() => {
    if(window.location.pathname !== "/" && window.location.pathname !== "/about-us/" && window.location.pathname === "/jobs/why-gspann/" ){
      setOpen(false);
    }
  }, [])

  const toggle = () => setOpen(isOpen => !isOpen);
  const form = <FormDisplaySection2
    // key={page_content.id}
    // input={page_content}
    key={body.id}
    input={body}
    emailTemplateData={emailTemplateData}
  />

  return (
    // <Modal
    //   className="imageMagnifierModal container"
    //   id="alert-modal"
    //   isOpen={props.isOpen}
    //   toggle={onCloseAlert}
    // >
     
    //   <ModalBody className="modal-body-container">
    //   <button className="close-btn btn" onClick={onCloseAlert}>
    //     <i className="far fa-times fa-1x"></i>
    //   </button>
    //     {form}
    //   </ModalBody>
    // </Modal>
    <Container id="popupform-main-container">
    <Modal
      className="imageMagnifierModal container"
      isOpen={open}
      toggle={toggle}
    >
      <ModalBody className="modal-body-container" id="alert-modal">
      <Row className='col-md-12' style = {{ paddingLeft:"0px",paddingRight:"0px",marginLeft:"0px",marginRight:"0px",}}>
      <Col className='col-md-04' style = {{ width:"30%", padding:"0px"}}>
      {/* <div className='banner-block'> */}
        {/* <img alt="Join Us" src={props.prismicPopupModal? props.prismicPopupModal.body[0].primary.popup_image.url : ""} ></img> */}
      {/* </div> */}
      </Col>
      <Col className='col-md-08'style = {{ width:"70%", padding:"0px"}}>
         <button className="close-btn btn" onClick={toggle}>
          <i className="far fa-times fa-1x"></i>
        </button>
          {/* {form} */}
      </Col>
      </Row>
      </ModalBody>
    </Modal>
    </Container>
  )
}


export default AlertModal