import React, { Component, Suspense } from "react";
import PropTypes from "prop-types";
import TopResources from './../data/TopResources.json';
import {

  BannerTwitter,
  Breadcrumb,
  CallToActionStrip,
  ContentAwardAndPartnerships,
  Experts,
  FilterResources,
  FormDisplaySection2,
  HomePageCardComponent,
  HomePageCardSection,
  HomePageCorousel,
  HomePageHeadingSection,
  HomePagePartnerBanner,
  HomePageResouceSection,
  Location,
  NumbersBanner,
  Policies,
  Positions,
  ResourceOverview,
  SPattern,
  Testimonial,
  WalkinDetails,
  WalkinCardsSection,
  OverlayFormDisplaySection,
  InlineFormDisplaySection,
  MultiCardsCarousel,
  ResourceMediaCards,
  ResourceTags,
  ResourceDescription,
  VirtualWalkinDetails,
  JobDetails,
  PageWithCorousel,
  PageWithDynamicLinks,
  BrandsWeWork,
  AdobeEcosystem,
  ImageWithData,
  CardsWithCertifications,
  LatestJobOpenings,
  MultiCardsWithImages,
  InlineButton,
} from "slices";
import WalkThrough from "../slices/WalkThrough";
import PieChart from "../slices/piechart";
import AdobeJourney from "../slices/AdobeJourney";
import AdobeSlice from "../slices/AdobeSlice";
import AdobeExpertise from "../slices/AdobeExpertise";
import CardsWithHeader from "../slices/CardsWithHeader";
import ResourceOvierviewButton from "../slices/ResourceOvierviewButton";
import NewsPressReleaseList from "../slices/NewsPressReleaseList";
import constants from "../constants";
import UploadLogo from "../slices/UploadLogo";
import CSRSection from "../slices/CSRSection";
import ScrollToTopButton from "../components/ScrollToTop";
import TwoColumnDescription from "../slices/TwoColumnDescription"
import AlertModal from "../slices/AlertModal";
import PopupModal from "../slices/PopupModal";

export default class SliceZone extends Component {
  constructor(props) {
    super(props);
    this.selectedFilter = this.selectedFilter.bind(this);
    this.pageOverViewData = null;
    this.resourceOverviewData = null;
    this.pageHeaderBodyData = null;
    this.pageHeroData = null;
    this.linksData = props.pagesLinksData.concat(props.resourceTilesData);
    this.sortPagesBasedOnREsourceTags =
      this.sortPagesBasedOnREsourceTags.bind(this);
    this.breadcrumbsDataArray;
    this.pageAdobeDynamicLinks = props.pageDynamicData;
    

    this.state = {
      resourceTilesData: props.resourceTilesData,
      homePageFeaturedResourceData: props.homePageFeaturedResourceData,
      homepageEventsData: props.homepageEventsData,
      selectedOptions: "",
      searchWord: "",
      searchTag: "",
      isOpen: false,
      isHidden: false
    };
    this.isAlertClosed = this.isAlertClosed.bind(this);
    this.toggle = this.toggle.bind(this);
    this.handleWindowClose = this.handleWindowClose.bind(this);
  }

  componentDidMount() {
    if (this.props.pageId === 'about-us') {
      document.addEventListener('contextmenu', (e) => {
        e.preventDefault();
      });
    }
  }

  componentWillUnmount() {
    if (this.props.pageId === 'about-us') {
      document.removeEventListener('contextmenu', (e) => {
        e.preventDefault();
      });
    }
  }

  isAlertClosed() {
    let cookiearray = document.cookie.split(';')
    let isclose = false;
    cookiearray.forEach(item => {
      const cookie = item.split('=');
      if (cookie[0].trim() === "isAlertClosed") {
        isclose = cookie[1] === 'true';
      }
    });
    return isclose;
  }
  
  toggle() {
    this.setState({
      isOpen: !this.state.isOpen
    });
  }

  handleWindowClose(event) {
    let cookiearray = document.cookie.split(';')
    let isclose = false;
    cookiearray.forEach(item => {
      const cookie = item.split('=');
      if (cookie[0].trim() === "isAlertClosed") {
        isclose = cookie[1] === 'true';
      }
    });

    if (event.clientY < 0 && !isclose) {
      this.setState({
      isOpen: !this.state.isOpen
    });
    }
  }

  onCloseAlert = () => {
    document.cookie = `isAlertClosed=${true};`;
    console.log('alert closed');
    this.toggle();
  }

  hideContent = (isBlocked) => {
    this.setState({isHidden: isBlocked});
  }

  componentDidMount() {
    if(window.location.pathname === "/jobs/why-gspann/"){
    window.addEventListener('mouseout', this.handleWindowClose);
    }
  }

  componentWillUnmount() {
    window.removeEventListener('mouseout', this.handleWindowClose);
  }

  selectedFilter(filterList) {
    if (filterList && filterList.length > 0) {
      filterList.forEach((data) => {
        if (data.type === "checkbox") {
          this.setState({
            selectedOptions:
              data.value || localStorage.getItem("resourceFilter"),
          });
        } else if (data.type === "searchWord") {
          this.setState({
            searchWord:
              data.value || localStorage.getItem("searchWord"),
          });
        } else if (data.type === "resourceTags") {
          this.setState({
            searchTag:
              data.value || localStorage.getItem("resourceTag"),
          });
        }
      });
    }
  }
  createTilesObject(resourceTilesData) {
    let resourceTilesViewObject = [];
    if (resourceTilesData && resourceTilesData.length > 0) {
      this.resourceTags = [];
      // console.log(":: resourceTilesData ::", resourceTilesData)
      resourceTilesData.filter(item => item?.node?.data?.page_path?.text !== 'campaign').forEach((item, i) => {
        if (item) {
          // console.log("item in slicezone", item);
          let itemNode = item.node ? item.node : item.document[0];
          let pagePath = itemNode.data.page_path.text;
          const itemBody = itemNode.data.body;
          const rtvo = {};
          const indexForResourceTag = (obj) =>
            obj.__typename === "PrismicResourceBodyResourceTags";
          const indexForResourceType = (obj) =>
            obj.__typename === "PrismicResourceBodyResourceOverview";
          const indexForThimbnail = (obj) =>
            obj.__typename === "PrismicResourceBodyPageOverview";
          const indexForHeroes = (obj) =>
            obj.__typename === "PrismicResourceBodyHeroes";
          const indexResourceType = itemBody.findIndex(indexForResourceType);
          const indexThumbnail = itemBody.findIndex(indexForThimbnail);
          const indexResourceTag = itemBody.findIndex(indexForResourceTag);
          const indexHeroes = itemBody.findIndex(indexForHeroes);
          const resourceUrl = itemNode.data.page_url?.text ? itemNode.data.page_url.text : itemNode.data.resource_display_name.text;
          //console.log('resourceUrl:', resourceUrl);
          rtvo.pagePath = pagePath;
          rtvo.cardType = "resource-card";
          rtvo.displayName = itemNode.data.resource_display_name.text;
          rtvo.href = `${itemNode.data.page_path.text
            }/${resourceUrl
              .split(" ")
              .join("-")
              ?.toLowerCase()}`;
          rtvo.pageTitle = itemBody[indexThumbnail].primary.page_title.text;
          rtvo.resourceType = itemBody[indexResourceType].primary.resource_type;
          if(itemBody[indexThumbnail].primary.page_thumbnail.alt === "Maximize Business Efficiency: Use Terraform to Turbocharge AWS Fargate Migration"){
            // console.log("itemBody",itemBody[indexThumbnail].primary);
          }
          rtvo.thumbNail = itemBody[indexThumbnail].primary.page_thumbnail;
          rtvo.thumbNailTag = itemBody[indexThumbnail].primary.page_overview_tag;
          rtvo.thumbNailTag2 = itemBody[indexThumbnail].primary.page_overview_tag_2;
          // if(rtvo.thumbNailTag.alt?.length>0){
          //   console.log("rtvo.thumbNailTag",rtvo.thumbNailTag);
          // }
          rtvo.date = itemBody[indexResourceType].primary.date;
          rtvo.title = itemBody[indexResourceType].primary.title.text;
          rtvo.id = itemNode.id || item;
          if (
            itemBody[indexResourceTag] &&
            itemBody[indexResourceTag].items &&
            itemBody[indexResourceTag].items.length > 0
          ) {
            rtvo.resourceTags = itemBody[indexResourceTag];
            rtvo.resourceTags.items.sort((tagDataA, tagDataB) => {
              if (
                tagDataA.tag_keywords_list?.toLowerCase() <
                tagDataB.tag_keywords_list?.toLowerCase()
              ) {
                return -1;
              }
              if (
                tagDataA.tag_keywords_list?.toLowerCase() >
                tagDataB.tag_keywords_list?.toLowerCase()
              ) {
                return 1;
              }
              return 0;
            });
            itemBody[indexResourceTag].items.forEach((item) => {
              const found = this.resourceTags.some(
                (el) => el.tag_keywords_list === item.tag_keywords_list
              );
              if (!found) {
                (item.tag_keywords_list !== null) && this.resourceTags.push(item);
              }
            });
          }
          if (
            itemBody[indexHeroes] &&
            itemBody[indexHeroes].items &&
            itemBody[indexHeroes].items.length > 0
          ) {
            rtvo.bannerHeader =
              itemBody[indexHeroes].items[0].hero_description.text;
          }
          resourceTilesViewObject.push(rtvo);
        }
      });
      if (this.resourceTags && this.resourceTags.length > 0) {
        this.resourceTags.sort((tagDataA, tagDataB) => {
          if (
            tagDataA.tag_keywords_list?.toLowerCase() <
            tagDataB.tag_keywords_list?.toLowerCase()
          ) {
            return -1;
          }
          if (
            tagDataA.tag_keywords_list?.toLowerCase() >
            tagDataB.tag_keywords_list?.toLowerCase()
          ) {
            return 1;
          }
          return 0;
        });
        this.resourceTags = [
          { tag_keywords_list: "All Topics" },
          ...this.resourceTags,
        ];
      }
      return resourceTilesViewObject;
    }
  }

  getCarouselList(values) {
    var carouselList = [];
    carouselList = values.items.map((element) => {
      return element.carousel_card_link;
    });
    return carouselList;
  }

  sortPagesBasedOnREsourceTags(resourceType = "", minResult, maxResult) {
    let finalResourceDataObject = {};
    let resourceTilesViewObject = [];
    resourceTilesViewObject = this.createTilesObject(
      this.state.resourceTilesData
    );
    if (resourceType) {
      resourceTilesViewObject = resourceTilesViewObject.filter((item) =>
        resourceType.includes(item.resourceType)
      );
    }
    let pageOverviewIndx = this.props.allSlices.findIndex(
      (slice) => slice.__typename === "PrismicResourceBodyPageOverview"
    );
    let selectedResource =
      resourceTilesViewObject.filter(
        (item) =>
          item.pageTitle ===
          this.props.allSlices[pageOverviewIndx].primary.page_title.text
      )[0] || {};
    resourceTilesViewObject = resourceTilesViewObject.filter(
      (item) =>
        item.pageTitle !==
        this.props.allSlices[pageOverviewIndx].primary.page_title.text
    );
    resourceTilesViewObject = resourceTilesViewObject.filter(
      (item) => item.pagePath !== "campaign"
    );

    resourceTilesViewObject.sort((tile1, tile2) => {
      var nuOfMatchingTags1 =
        tile1.resourceTags &&
          tile1.resourceTags.items &&
          tile1.resourceTags.items.length > 0
          ? tile1.resourceTags.items.reduce(function (count, tag) {
            let found =
              selectedResource.resourceTags &&
              selectedResource.resourceTags.items &&
              selectedResource.resourceTags.items.some(
                (el) => el.tag_keywords_list === tag.tag_keywords_list
              );
            if (found) count++;
            return count;
          }, 0)
          : 0;
      tile1.count = nuOfMatchingTags1;
      var nuOfMatchingTags2 =
        tile2.resourceTags &&
          tile2.resourceTags.items &&
          tile2.resourceTags.items.length > 0
          ? tile2.resourceTags.items.reduce(function (count, tag) {
            let found =
              selectedResource.resourceTags &&
              selectedResource.resourceTags.items &&
              selectedResource.resourceTags.items.some(
                (el) => el.tag_keywords_list === tag.tag_keywords_list
              );
            if (found) count++;
            return count;
          }, 0)
          : 0;
      tile2.count = nuOfMatchingTags2;
      if (nuOfMatchingTags1 > nuOfMatchingTags2) {
        return -1;
      }
      if (nuOfMatchingTags1 < nuOfMatchingTags2) {
        return 1;
      }
      return 0;
    });

    var revelentResult = [];
    resourceTilesViewObject.forEach((item) => {
      if (revelentResult[item.count] && revelentResult[item.count].length > 0) {
        revelentResult[item.count].push(item);
      } else {
        revelentResult[item.count] = [];
        revelentResult[item.count].push(item);
      }
    });
    let notMatchedData =
      revelentResult[0] && revelentResult[0].length
        ? revelentResult.splice(0, 1)[0]
        : [];
    revelentResult = revelentResult.filter(Array);
    revelentResult.reverse();
    revelentResult.forEach((list) => {
      list.sort((a, b) => {
        return new Date(b.date).getTime() - new Date(a.date).getTime();
      });
    });
    if (revelentResult && revelentResult.length > 0) {
      finalResourceDataObject.items = [];
      for (var i = 0, len = revelentResult.length; i < len; i++) {
        for (var j = 0, len2 = revelentResult[i].length; j < len2; j++) {
          if (finalResourceDataObject.items.length < maxResult) {
            finalResourceDataObject.items.push(revelentResult[i][j]);
          } else {
            break;
          }
        }
      }
      // if(revelentResult[0].length >= minResult && revelentResult[0].length <= maxResult) {
      //   finalResourceDataObject.items = revelentResult[0];
      // }else if(revelentResult[0].length > maxResult) {
      //   finalResourceDataObject.items = revelentResult[0].slice(0, maxResult);
      // } else if(revelentResult[0].length < minResult) {
      //   finalResourceDataObject.items = [];
      //   for (var i=0, len=revelentResult.length; i<len; i++) {
      //     // inner loop applies to sub-arrays
      //     for (var j=0, len2=revelentResult[i].length; j<len2; j++) {
      //       if(finalResourceDataObject.items.length < maxResult) {
      //         finalResourceDataObject.items.push( revelentResult[i][j] );
      //       } else {
      //         break;
      //       }
      //     }
      //   }
      // }
    }
    if (
      finalResourceDataObject &&
      (!finalResourceDataObject.items ||
        finalResourceDataObject.items < minResult)
    ) {
      if (!finalResourceDataObject.items) {
        finalResourceDataObject.items = [];
      }
      notMatchedData.sort((a, b) => {
        return new Date(b.date).getTime() - new Date(a.date).getTime();
      });
      for (var i = 0, len = notMatchedData.length; i < len; i++) {
        if (finalResourceDataObject.items.length < minResult) {
          finalResourceDataObject.items.push(notMatchedData[i]);
        } else {
          break;
        }
      }
    }

    return finalResourceDataObject;
  }

  render() {
    var isWindow = true; //typeof window !== "undefined";
    const { allSlices, pageId } = this.props;
    const extraSlices = [];
    const { homePageFeaturedResourceData, homepageEventsData } = this.state;
    // console.log(":: allSlices ::", allSlices);
    // this section is just for blog type resource page to get the data of blog author
    let authorInd = -1;
    let authorData = {};
    let emailTemplateData = [];
    let resourceOverviewIndx = -1;
    let resourceType = "";
    let resourceTagIndx = -1;
    let resourceTagDetails = {};

    if (allSlices && allSlices.length > 0) {
      authorInd = allSlices.findIndex(
        (slice) => slice.__typename === "PrismicResourceBodyAuthor"
      );

      emailTemplateData = allSlices.filter((slice) =>
        [
          "PrismicResourceBodyEmailTemplateLinks",
          "PrismicPagePageContentEmailTemplateLinks",
          "PrismicHomepagePageContentEmailTemplateLinks",
          "PrismicJobDescriptionPageBodyEmailTemplateLinks",
          "PrismicPopupModalBodyEmailTemplateLinks",
        ].includes(slice.__typename)
      );

      allSlices &&
        allSlices.map((s) => {
          if (s.slice_type == "breadcrumb") {
            console.log(":: breadcrumb ::", s);
            this.breadcrumbsDataArray = s;
          }
        });
    }
    resourceOverviewIndx =
      allSlices &&
      allSlices.findIndex((slice) =>
        "PrismicResourceBodyResourceOverview".includes(slice.__typename)
      );

    if (resourceOverviewIndx !== -1) {
      resourceType =
        allSlices && allSlices[resourceOverviewIndx].primary.resource_type;
    }
    resourceTagIndx =
      allSlices &&
      allSlices.findIndex((slice) =>
        "PrismicResourceBodyResourceTags".includes(slice.__typename)
      );
    if (resourceTagIndx !== -1) {
      resourceTagDetails = allSlices && allSlices[resourceTagIndx];
      resourceTagDetails &&
        resourceTagDetails.items.sort((tagDataA, tagDataB) => {
          if (
            tagDataA.tag_keywords_list?.toLowerCase() <
            tagDataB.tag_keywords_list?.toLowerCase()
          ) {
            return -1;
          }
          if (
            tagDataA.tag_keywords_list?.toLowerCase() >
            tagDataB.tag_keywords_list?.toLowerCase()
          ) {
            return 1;
          }
          return 0;
        });
    }
    if (authorInd !== -1) {
      authorData = allSlices[authorInd];
    }

    const slice = allSlices
      ? allSlices.map((s) => {
        console.log(s.slice_type, s)
        switch (s.slice_type) {
          case "heroes":
            this.pageHeroData = s;
            return (
              <HomePageCorousel
                key={s.id}
                corouselData={s.items}
                pagesLinksData={this.linksData}
                pageId={s.id}
                breadcrumbsData={this.breadcrumbsDataArray}
                heroTagImage={s?.primary?.hero_image_tag?.localFile?.publicURL}
                pageType={resourceOverviewIndx !== -1 ? "resources" : ""}
                resourceOverviewData={
                  resourceOverviewIndx !== -1
                    ? allSlices[resourceOverviewIndx]
                    : null
                }
              />
            );
          case "page_header___body":
            this.pageHeaderBodyData = s;
            return (
              <HomePageHeadingSection
                key={s.id}
                input={s}
                pagesLinksData={this.linksData}
                pageId={s.id}
                pageHeroData={this.pageHeroData}
              />
            );
          case "page_with_dynamic_links":
            return (
              <PageWithDynamicLinks
                pageId={pageId}
                key={s.id}
                input={s}
                dynamicLinks={this.pageAdobeDynamicLinks}
                pagesLinksData={this.linksData}
              />
            );
          case "cards1":
            return (
              <HomePageCardSection
                key={s.id}
                input={s}
                pagesLinksData={this.linksData}
              />
            );
          case "csr_section":
            return (
              <CSRSection
                key={s.id}
                {...s}
              />
            );
          case "cards_with_no_border":
            return (
              <HomePageCardSection
                key={s.id}
                input={s}
                pagesLinksData={this.linksData}
              />
            );
          case "image_with_data":
            return (
              <ImageWithData
                key={s}
                input={s}
                pagesLinksData={this.linksData}
              />
            );
          case "adobe_ecosystem":
            return (
              <AdobeEcosystem
                key={this.linksData}
                input={s}
                pagesLinksData={this.linksData}
              />
            );
          case "adobejourney":
            return (<AdobeJourney
              key={this.linksData}
              input={s}
              pagesLinksData={this.linksData}
            />
            );

          case "adobe_expertise":
            return (<AdobeExpertise
              key={this.linksData}
              input={s}
              pagesLinksData={this.linksData}
            />
            );

          case "page_with_carousel":
            return (
              <PageWithCorousel
                key={s.id}
                input={s}
                pagesLinksData={this.linksData}
              />
            );
          case "cards":
            return (
              <HomePageCardSection
                key={s.id}
                input={s}
                pagesLinksData={this.linksData}
              />
            );

          case "cards_with_header":
            return (
              <CardsWithHeader
                key={s.id}
                input={s}
                pagesLinksData={this.linksData}
              />
            );


          case "offices":
            return <Location key={s.id} input={s} />;
          case "resources":
            return (
              <HomePageResouceSection
                key={s.id}
                input={s}
                homePageFeaturedResourceData={homePageFeaturedResourceData}
                homepageEventsData={homepageEventsData}
                pagesLinksData={this.linksData}
                jobListData={this.props.jobListData}
              />
            );
          case "banner-tech1":
            return (
              <HomePagePartnerBanner key={s.id + 1} partnerBannerData={s} />
            );
          case "banner-tech":
            return (
              <HomePagePartnerBanner key={s.id + 2} partnerBannerData={s} />
            );
          case "s-pattern":
            return (
              <SPattern
                key={s.id}
                patternData={s}
                pagesLinksData={this.linksData}
              />
            );
          case "multi_cards_with_images":
            return (
              <MultiCardsWithImages
                key={s.id}
                pagesLinksData={this.linksData}
                input={s}
              />
            );
          case "header_with_svg":
            return (<PieChart key={s.id}
              pagesLinksData={this.linksData}
              input={s}></PieChart>);
          case "latest_job_openings":
            return (
              <LatestJobOpenings
                key={s.id}
                input={s}
                pageId={pageId}
                pagesLinksData={this.linksData}
                allJobs={this.props.jobListData}
                pageOverViewData={this.pageOverViewData}
              />
            );
          case "experts":
            return <Experts key={s.id} expertsData={s} allSlices={this.props.allSlices} prismicPopupModal={{ body: s }} />;
          case "banner-twitter":
            return <BannerTwitter key={s.id} bannerData={s} />;
          case "banner-job":
            return (
              <CallToActionStrip
                key={s.id}
                input={s}
                pagesLinksData={this.linksData}
                pageId={pageId}
                emailTemplateData={emailTemplateData}
                pageOverViewData={this.pageOverViewData}
                pageHeaderBodyData={this.pageHeaderBodyData}
                pageHeroData={this.pageHeroData}
              />
            );
          case "banner-numbers":
            return <NumbersBanner key={s.id} input={s} />;
          case "testimonials":
            return (
              <Testimonial
                key={s.id}
                input={s}
                pagesLinksData={this.linksData}
              />
            );
          case "walkthrough":
            return (
              <WalkThrough
                key={s.id}
                input={s}
                pagesLinksData={this.linksData}
              />
            );

          case "button_with_text":
            return (
              <ResourceOvierviewButton
                key={s.id}
                input={s}
                pagesLinksData={this.linksData}
              />
            );

          case "resource_overview":
            this.resourceOverviewData = s;
            let isNewDesign =
              process.env.NEW_CASE_STUDY_DESIGN &&
                process.env.NEW_CASE_STUDY_DESIGN === "true"
                ? true
                : false;
            let relatedResources =
            (resourceType && resourceType === "Blog" || (resourceType === "Event"))
                ? this.sortPagesBasedOnREsourceTags(resourceType, 3, 5)
                : null;

            if (isNewDesign) {
              relatedResources =
                resourceType && resourceType === "Case Study"
                  ? this.sortPagesBasedOnREsourceTags(resourceType, 2, 3)
                  : relatedResources;
              relatedResources =
                resourceType && resourceType === "White Paper"
                  ? this.sortPagesBasedOnREsourceTags(
                    ["Case Study", "White Paper"],
                    2,
                    3
                  )
                  : relatedResources;
            }
            return (
              <ResourceOverview
                pageId={pageId}
                key={s.id}
                input={s}
                author={authorData}
                pagesLinksData={this.linksData}
                tagsData={resourceTagDetails.items}
                tagInput={resourceTagDetails}
                relatedBlogs={relatedResources} //reverting new case study design, uncomment line
                pageHeroData={this.pageHeroData}
                isHidden={true}
                hideContent= {this.hideContent}
              />
            );
          // case "form_display_section":
          //   return <ResourceForms key={s.id} input={s} />;
          case "walkin_detail":
            return <WalkinDetails key={s.id} input={s} />;
          case "page_overview":
            this.pageOverViewData = s;

            break;
          case "form_display_section":
            return (
              <FormDisplaySection2
                key={s.id}
                id={s.id}
                input={s}
                pageId={pageId}
                pageOverViewData={this.pageOverViewData}
                resourceOverviewData={this.resourceOverviewData}
                pageHeroData={this.pageHeroData}
                emailTemplateData={emailTemplateData}
                resourceType={resourceType}
                pageDisplayName={this.props.pageDisplayName}
                jobID={this.props.jobID}
                isHidden={this.state.isHidden}
              />
            );

          case "UploadLogo":
            return (
              <UploadLogo
                key={s.id}
                input={s}
                pagesLinksData={this.linksData}
              />
            );


          case "award-and-partnerships":
            return (
              <ContentAwardAndPartnerships key={s.slice_type} input={s} />
            );
          case "privacy_content":
            return <Policies key={s.id} policyData={s} />;
          case "walkin_cards":
            return (
              <WalkinCardsSection
                key={s.id}
                input={s}
                pagesLinksData={this.linksData}
              />
            );
          case "overlay_form_display_section":
            return (
              <InlineFormDisplaySection
                key={s.id}
                input={s}
                pagesLinksData={this.linksData}
                pageId={pageId}
                emailTemplateData={emailTemplateData}
                pageOverViewData={this.pageOverViewData}
                pageHeaderBodyData={this.pageHeaderBodyData}
                pageHeroData={this.pageHeroData}
                resourceType={resourceType}
                pageDisplayName={this.props.pageDisplayName}
              />
            );
          case "multi_cards_carousel":
            var finalResourceDataObject = {};
            finalResourceDataObject.items = this.createTilesObject(
              this.getCarouselList(s)
            );
            console.log("finalResourceDataObject in card",finalResourceDataObject);
            return (
              <MultiCardsCarousel
                cardData={finalResourceDataObject}
                pagesLinksData={this.linksData}
                selectedFilter={this.selectedFilter}
                key="ResourceCards"
                type="ResourceCards"
                input={s}
                pageId={pageId}
              />
            );

          case "cards_with_certifications":
            return (
              <CardsWithCertifications
                key={s.id}
                input={s}
                pageId={pageId}
                pagesLinksData={this.linksData}
              ></CardsWithCertifications>
            );
          case "resource_media_cards":
            return (
              <ResourceMediaCards
                key={s.id}
                input={s}
                pageId={pageId}
                pagesLinksData={this.linksData}
              />
            );
          case "cards_with_certifications":
            return (
              <CardsWithCertifications
                key={s.id}
                input={s}
                pageId={pageId}
                pagesLinksData={this.linksData}
              ></CardsWithCertifications>
            );
          case "resource_tags":
            let isNewDesignCaseStudy =
              process.env.NEW_CASE_STUDY_DESIGN &&
                process.env.NEW_CASE_STUDY_DESIGN === "true"
                ? true
                : false;
            if (
              pageId !== "all-resources" &&
              resourceType !== "Blog" &&
              !isNewDesignCaseStudy
            ) {
              this.ResourceTagData = s;
              return (
                <ResourceTags
                  key={s.id}
                  input={s}
                  pageId={pageId}
                  tagsData={s.items}
                  pagesLinksData={this.linksData}
                  resourceType={resourceType}
                />
              );
            } else {
              this.resourceTagInput = s;
            }
          case "resource_description":
            return (
              <ResourceDescription
                key={s.id}
                input={s}
                pageId={pageId}
                pagesLinksData={this.linksData}
                resourceType={resourceType}
                resourceOverviewData={this.resourceOverviewData}
                isHidden={this.state.isHidden}
                hideContent= {this.hideContent}
              />
            );
          case "landing_page_description":
            return (
              <TwoColumnDescription
                key={s.id}
                input={s}
                pageId={pageId}
                pagesLinksData={this.linksData}
                resourceType={resourceType}
                isHidden={this.state.isHidden} />
            )
          case "overlay_form_display_section_1":
            return (
              <InlineButton
                key={s.id}
                input={s}
                pagesLinksData={this.linksData}
                pageId={pageId}
                pageOverViewData={this.pageOverViewData}
                pageHeaderBodyData={this.pageHeaderBodyData}
                pageHeroData={this.pageHeroData}
                resourceType={resourceType}
                pageDisplayName={this.props.pageDisplayName}
              />
            );
          case "jobs_list_for_walkin":
            this.jobListForWalkin = s;
            break;

          case "adobecasestudy":
            var finalResourceDataObject = {};
            finalResourceDataObject.items = this.createTilesObject(
              this.getCarouselList(s)
            );
            return (
              <AdobeSlice
                cardData={finalResourceDataObject}
                pagesLinksData={this.linksData}
                selectedFilter={this.selectedFilter}
                key="ResourceCards"
                type="ResourceCards"
                input={s}
                pageId={pageId}
              />
            );

          case "virtual_walkin_details":
            return (
              <VirtualWalkinDetails
                key={s.id}
                input={s}
                pageId={pageId}
                pagesLinksData={this.linksData}
                jobListForWalkin={this.jobListForWalkin}
                jobListData={this.props.jobListData}
              />
            );
          case "brands_work_with":
            return (
              <BrandsWeWork
                key={s.id + 21}
                input={s}
                pagesLinksData={this.linksData}
              ></BrandsWeWork>
            );

          case "job_description":
            let keyDetails = allSlices.filter((slice) =>
              ["PrismicJobDescriptionPageBodyKeyDetails"].includes(
                slice.__typename
              )
            );
            let Jobtags = allSlices.filter((slice) =>
              ["PrismicJobDescriptionPageBodyJobTags"].includes(
                slice.__typename
              )
            );
            let primarySkills = allSlices.filter((slice) =>
              ["PrismicJobDescriptionPageBodyPrimarySkills"].includes(
                slice.__typename
              )
            );
            return (
              <JobDetails
                key={s.id}
                input={s}
                pageId={pageId}
                pagesLinksData={this.linksData}
                keyDetails={
                  keyDetails && keyDetails.length > 0 ? keyDetails[0] : null
                }
                jobTags={Jobtags && Jobtags.length > 0 ? Jobtags[0] : null}
                allJobs={this.props.jobListData}
                primarySkills={
                  primarySkills && primarySkills.length > 0
                    ? primarySkills[0]
                    : null
                }
                pageOverViewData={this.pageOverViewData}
              />
            );
          case "page_popup_model":
            return <PopupModal allSlices={this.props.allSlices} prismicPopupModal={{ body: s }} />

          case "alert_popup":
            return <AlertModal allSlices={this.props.allSlices}
              isOpen={this.state.isOpen}
              // prismicPopupModal={{ page_content: s }}
              prismicPopupModal={{ body: s }}
              onCloseAlert={this.onCloseAlert} />
          default:
            return null;
  
        }
      })
      : null;
    if (pageId === "find-a-position") {
      const positionSlice = (
        <Positions
          key="job-positions"
          emailTemplateData={emailTemplateData}
          pageOverViewData={this.pageOverViewData}
          jobListData={this.props.jobListData}
          pagesLinksData={this.linksData}
        />
      );
      extraSlices.push(positionSlice);
    }
    if (pageId === "all-resources") {
      // console.log({pageId, extraSlices}, this.props)
      const finalResourceDataObject = {};
      const finalResourceDataObjectBlog = [];
      const finalResourceDataObjectCaseStudy = [];
      const finalResourceDataObjectWhitePapers = [];
      const topResourceObject = [];
      const topResourceObjectBlogs = [];
      const topResourceObjectCaseStudy = [];
      const topResourceObjectWhitePapers = [];
      let resourceTilesViewObject = [];
      const { selectedOptions, searchWord, resourceTilesData } = this.state;
      resourceTilesViewObject = this.createTilesObject(resourceTilesData);
      /* code for filtering the card details as per selected checkboxes */
      let fliteredData = [];
      if (selectedOptions) {
        fliteredData = resourceTilesViewObject.filter(
          (item) => selectedOptions.indexOf(item.resourceType) >= 0
        );
        finalResourceDataObject.items = fliteredData;
      } else {
        finalResourceDataObject.items = resourceTilesViewObject;
      }
      let searchTag = "";
      try {
        searchTag = JSON.parse(this.state.searchTag)
          ?.toLowerCase()
          .replace("%20", " ");
      } catch (e) {
        searchTag = this.state.searchTag?.toLowerCase();
      }
      if (this.state.searchTag && searchTag !== "all topics") {
        finalResourceDataObject.items = finalResourceDataObject.items.filter(
          (item) => {
            let flag = false;
            if (
              item.resourceTags &&
              item.resourceTags.items &&
              item.resourceTags.items.length > 0
            ) {
              for (const element of item.resourceTags.items) {
                if (element.tag_keywords_list?.toLowerCase() === searchTag) {
                  flag = true;
                  break;
                }
              }
              if (flag) {
                return item;
              }
            }
          }
        );

        this.resourceTags.map((item) => {
          // console.log(searchTag);
          if (item.tag_keywords_list?.toLowerCase() === searchTag) {
            item.selected = true;
          } else {
            item.selected = false;
          }
        });
      } else {
        this.resourceTags.map((item) => {
          if (item.tag_keywords_list?.toLowerCase() === "all topics") {
            item.selected = true;
          } else {
            item.selected = false;
          }
        });
      }
      /*  code for filtering the card details by searched value */
      if (searchWord) {
        finalResourceDataObject.items = finalResourceDataObject.items.filter(
          (item) =>
            item.displayName?.toLowerCase().indexOf(searchWord?.toLowerCase()) >=
            0
        );
      }

      finalResourceDataObject.items.sort((a, b) => {
        return new Date(b.date).getTime() - new Date(a.date).getTime();
      });
      // Filtering data to add the latest prompt tag.
      //START
      finalResourceDataObject.items.forEach((ele,idx) => {
        ele.thumbNailTag.url =null;
        ele.thumbNailTag.alt = null;
        ele.thumbNailTag2.url =null;
        ele.thumbNailTag2.alt = null;

        if(ele.resourceType === "Case Study") {
          if(finalResourceDataObjectCaseStudy.length === 0){
            finalResourceDataObjectCaseStudy.push(ele);
            finalResourceDataObjectCaseStudy[0].thumbNailTag.url ="https://gspann.cdn.prismic.io/gspann/7e0f9f1c-6ca0-484d-b995-9e24876afee2_Latest.svg";
            finalResourceDataObjectCaseStudy[0].thumbNailTag.alt = "latest";
          }
        }
        if(ele.resourceType === "Blog") {
          if(finalResourceDataObjectBlog.length === 0){
            finalResourceDataObjectBlog.push(ele);
            finalResourceDataObjectBlog[0].thumbNailTag.url ="https://gspann.cdn.prismic.io/gspann/7e0f9f1c-6ca0-484d-b995-9e24876afee2_Latest.svg";
            finalResourceDataObjectBlog[0].thumbNailTag.alt = "latest";
          }
        }

        if(ele.resourceType === "White Paper") {
          if(finalResourceDataObjectWhitePapers.length === 0){
            finalResourceDataObjectWhitePapers.push(ele);
            finalResourceDataObjectWhitePapers[0].thumbNailTag.url ="https://gspann.cdn.prismic.io/gspann/7e0f9f1c-6ca0-484d-b995-9e24876afee2_Latest.svg";
            finalResourceDataObjectWhitePapers[0].thumbNailTag.alt = "latest";
          }
        }
      })
      // END

      // Filtering data to add the Popular prompt tag.
      //START

      // console.log("TopResources", TopResources);
      TopResources.in.forEach((ele,idx) => {
        if(ele.PagePath.includes("/blogs/")){
          topResourceObjectBlogs.push(ele);
        }
        if(ele.PagePath.includes("/case-studies/")){
          topResourceObjectCaseStudy.push(ele);
        }
        if(ele.PagePath.includes("/white-papers/")){
          topResourceObjectWhitePapers.push(ele);
        }
      });
      const filteredDataBlogs = [];
      const MatchingDataBlogs = [];
      const filteredDataCaseStudy = [];
      const MatchingDataCaseStudy = [];
      const filteredDataWhitePapers = [];
      const MatchingDataWhitePapers = [];
      
      let highestViewsBlogs = -1;
      let highestViewsObjectBlogs = null;
      let highestViewsCaseStudy = -1;
      let highestViewsObjectCaseStudy = null;
      let highestViewsWhitePapers = -1;
      let highestViewsObjectWhitePapers = null;
      // BLOGS START
      finalResourceDataObject.items.forEach((ele,idx) => {
        if(ele.resourceType === "Blog") {
          filteredDataBlogs.push(ele);
        }
      });
      for(const blog of topResourceObjectBlogs) {
        for (const data of filteredDataBlogs) {
          if(blog.PagePath === `/${data.href}/`){
            MatchingDataBlogs.push(data);
            if(blog.Views > highestViewsBlogs){
              highestViewsBlogs = blog.Views;
              highestViewsObjectBlogs = blog;
            }
          }
        }
      }
      if(highestViewsObjectBlogs !== null){
        const correspondingObject = filteredDataBlogs.find(data => highestViewsObjectBlogs.PagePath === `/${data.href}/`);
        if(correspondingObject){
          if(correspondingObject){
            correspondingObject.thumbNailTag2.url ="https://gspann.cdn.prismic.io/gspann/c2df4609-793c-4022-a510-2e4e946df70a_Popular.svg";
            correspondingObject.thumbNailTag.alt = "popular";
          }           
        }
      }
      // BLOGS END
      // CASE STUDY START
      finalResourceDataObject.items.forEach((ele,idx) => {
        if(ele.resourceType === "Case Study") {
          filteredDataCaseStudy.push(ele);
        }
      });
      for(const casestudy of topResourceObjectCaseStudy) {
        for (const data of filteredDataCaseStudy) {
          if(casestudy.PagePath === `/${data.href}/`){
            MatchingDataCaseStudy.push(data);
            if(casestudy.Views > highestViewsCaseStudy){
              highestViewsCaseStudy = casestudy.Views;
              highestViewsObjectCaseStudy = casestudy;
            }
          }
        }
      }
      if(highestViewsObjectCaseStudy !== null){
        const correspondingObject = filteredDataCaseStudy.find(data => highestViewsObjectCaseStudy.PagePath === `/${data.href}/`);
        if(correspondingObject){
          correspondingObject.thumbNailTag2.url ="https://gspann.cdn.prismic.io/gspann/c2df4609-793c-4022-a510-2e4e946df70a_Popular.svg";
          correspondingObject.thumbNailTag2.alt = "popular"
        }
      }
      // CASE STUDY END
      // WHITE PAPERS START
      finalResourceDataObject.items.forEach((ele,idx) => {
        if(ele.resourceType === "White Paper") {
          filteredDataWhitePapers.push(ele);
        }
      });
      for(const whitepapers of topResourceObjectWhitePapers) {
        for (const data of filteredDataWhitePapers) {
          if(whitepapers.PagePath === `/${data.href}/`){
            MatchingDataWhitePapers.push(data);
            if(whitepapers.Views > highestViewsWhitePapers){
              highestViewsWhitePapers = whitepapers.Views;
              highestViewsObjectWhitePapers = whitepapers;
            }
          }
        }
      }
      if(highestViewsObjectWhitePapers !== null){
        const correspondingObject = filteredDataWhitePapers.find(data => highestViewsObjectWhitePapers.PagePath === `/${data.href}/`);
        if(correspondingObject){
          correspondingObject.thumbNailTag2.url ="https://gspann.cdn.prismic.io/gspann/c2df4609-793c-4022-a510-2e4e946df70a_Popular.svg";
          correspondingObject.thumbNailTag2.alt = "popular"
        }
      }
      // WHITE PAPERS END
      //END

      const filterResources = (
        <FilterResources
          selectedFilter={this.selectedFilter}
          cardData={finalResourceDataObject}
          key="refineResources"
          type="refineResources"
        />
      );
      extraSlices.push(filterResources);

      if (this.resourceTags) {
        const tagsResources = (
          <ResourceTags
            key="resourceTags"
            pageId={pageId}
            input={this.resourceTagInput}
            pagesLinksData={this.linksData}
            tagsData={this.resourceTags}
            finalResourceDataObject={finalResourceDataObject}
            selectedFilter={this.selectedFilter}
          />
        );
        extraSlices.push(tagsResources);
      }
      if (finalResourceDataObject.items.length > 0) {
        const resourceCards = (
          <HomePageCardComponent
            selectedFilter={this.selectedFilter}
            cardData={finalResourceDataObject}
            key="ResourceCards"
            type="ResourceCards"
            pagesLinksData={this.linksData}
          />
        );

        extraSlices.push(resourceCards);
      } else {
        let filterPlaceholder = "";
        if (this.state.searchWord) {
          filterPlaceholder += `Term: ${this.state.searchWord}, `;
        }
        if (this.state.selectedOptions) {
          filterPlaceholder += `FILTER "${this.state.selectedOptions}" and `;
        }
        if (searchTag) {
          filterPlaceholder += `Tag "${searchTag}"`;
        }
        const messageContainer = (
          <div className="message-container" key="ResourceFilterError">
            <div className="message">
              <span>
                We're sorry, we could not find any resources with{" "}
                {`${filterPlaceholder}`}.
              </span>
              <span>Please try your search again.</span>
            </div>
          </div>
        );
        extraSlices.push(messageContainer);
      }
    }

    if (resourceType === "Blog") {
      const multiCardsCarousel = (
        <MultiCardsCarousel
          cardData={this.sortPagesBasedOnREsourceTags(null, 3, 5)}
          pagesLinksData={this.linksData}
          selectedFilter={this.selectedFilter}
          key="ResourceCards"
          type="ResourceCards"
          pageId={pageId}
        />
      );
      extraSlices.push(multiCardsCarousel);
    }

    if (pageId === constants.PAGE.NEWS_AND_PRESS_RELEASE) {
      extraSlices.push(<NewsPressReleaseList key={pageId} list={this.props.newsList || []} />);
    }

    // console.log(":: SliceZone ::", {pageId, extraSlices, allSlices}, this.props)
    return (
      <>
        {isWindow && (
          <>
            <div id="slice-container" style={{ position: "relative" }}>
              {slice}
              {extraSlices}
            </div>
            <ScrollToTopButton />
          </>
        )}
      </>
    );
  }

}

SliceZone.propTypes = {
  allSlices: PropTypes.array.isRequired,
  pageId: PropTypes.string,
  homepageEventsData: PropTypes.array,
  resourceTilesData: PropTypes.array,
  homePageFeaturedResourceData: PropTypes.array,
  pagesLinksData: PropTypes.array,
};
