import React, { Component } from "react";
import "../styles/cardsWithCertifications.css";

class CardsWithCertifications extends Component {
  constructor(props) {
    super(props);
    this.cardsWithCertificationsData = props.input;
    this.linksData = props.pagesLinksData;

  }


  render() {
    let cardElements = [];
    let count = 0;
    this.cardsWithCertificationsData.items.forEach((element, key) => {

      let brand = (
        <div className="card-wrapper no-padding col-12 col-sm-6 col-md-6 col-lg-4" key={count++}>
          <div className="resource-card-container">

            <a href={'/certifications'+element.card_link.url}>
              <div className="card-background-img">
                <img
                  src={element.card_image.url}
                  className=" resource-card-img-width"
                />
                <div className="resource-content-wrapper">

                  <p className="card-resource-description">
                    {element.card_title.text}
                  </p>
                </div>
              </div>
            </a>
          </div>
        </div>
      )
      cardElements.push(brand);
    });
    return (
      <div className="resource-background">
        <div className="container header heading-section-container" >
          <div className="parent-container no-margin noGutters row">


            {cardElements}

          </div>
        </div>
      </div>
    )

  }
}

export default CardsWithCertifications;

