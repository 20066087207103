import React, { useState, useEffect } from "react";
import {getColor, createUrl} from "../utils/universalHelperFunctions";
import '../styles/tile-card-component.css';

function TileCardComponent(props) {
  const [focus, isFocused] = useState(false);

    function handleMouseEnter(e) {
    isFocused(true);
  }
  function handleMouseLeave(e) {
    isFocused(false);
  }

  let logoImage = null;
  if (props.tileData.card_logo) {
    logoImage = props.tileData.card_logo.url;
  } else {
    logoImage = null;
  }

  return (
    <div className="col-12 col-sm-6 col-md-6 col-lg-3 tile-container">
      <div className='col-12 tile-card' onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} style={{ backgroundColor: ` ${focus ? props.tileData.on_hover_background_color ? getColor(props.tileData.on_hover_background_color) : '#fff' : props.tileData.card_background_color ? getColor(props.tileData.card_background_color) : '#fff'}` }}>
        {
          props.tileData.card_logo && props.tileData.card_logo.url ? (<img
            alt={props.tileData.card_logo.alt}
            style={{ maxWidth: '30px', maxHeight: '30px', marginBottom: '15px'}}
            src={props.tileData.card_logo.url}
            className={`show-content`}
          />) : props.tileData.icon_class && props.tileData.icon_class.text && (
            <i className={`tile-icon ${props.tileData.icon_class.text} show-content`} style={{ color: props.tileData.icon_color ? getColor(props.tileData.icon_color) : '#000' }}></i>
          )
        }
        <h3 className={`tile-heading show-content`}>
          {props.tileData.card_heading.text}
        </h3>
        <p className="tile-description hide-content">
          {props.tileData.card_description.text}
        </p>
        {
          props.tileData.card_button_link && props.tileData.card_button_link.url && (
            <a href={createUrl(props.tileData.card_button_link.url, props.pagesLinksData)} style={{ color: props.tileData.card_button_color ? getColor(props.tileData.card_button_color) : '#000' }} className="tile-button hide-content" hrefLang="en">
              {props.tileData.card_button_text.text}
              <i className="fas fa-long-arrow-alt-right tile-button-icon"></i>
            </a>
          )
        }
      </div>
    </div>
  );
}

export default TileCardComponent;


