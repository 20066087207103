import React, { Component } from "react";
import "../styles/brandsWeWork.css";

class BrandsWeWork extends Component {
  constructor(props) {
    super(props);
    this.brandsWeWorkData = props.input;
    this.linksData = props.pagesLinksData;
  }

  render() {
    let brandElements = [];
    let count = 0;
    this.brandsWeWorkData.items.forEach((element, key) => {
      let brand = (
        <img
          src={element.brands.url}
          alt={element.brands.alt}
          key={count++}
          className={`${
            element.brands.alt === "macys" ? "brands-image-macys" : ""
          } brands-image-corousel d-md-block `}
        />
      );
      brandElements.push(brand);
    });
    return (
      <div style={{ backgroundColor: "rgb(241, 245, 246)" }}>
        <div className="container heading-section-container">
          <div>
            <h1 className="header-text-brands">
              {this.brandsWeWorkData.primary.header.text}{" "}
            </h1>
          </div>

          <div className="row column-brands">{brandElements}</div>
        </div>
      </div>
    );
  }
}

export default BrandsWeWork;
