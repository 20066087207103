import React, { Component } from "react"
import { getColor } from "../utils/universalHelperFunctions";
import '../styles/resource_media_cards.css';
import VideoOverlay from "./VideoOverlay";

class ResourceMediaCards extends Component {
	constructor(props) {
		super(props);
		this.state = {
			height: typeof window !== 'undefined' ? window.innerHeight : 0,
			width: typeof window !== 'undefined' ? window.innerWidth : 0,
		};
	}

	componentDidMount() {
		window.addEventListener('resize', (e) => this.onResize());
	}

	onResize() {
		this.setState({
			height: window.innerHeight,
			width: window.innerWidth
		});
	}

	render() {
		let sectionBackgroundColor = '#fff';
		let sectionFontColor = '#000';
		let sectionTitleColor = '#000';

		if (this.props.input.primary.hasOwnProperty('card_section_background_color') && this.props.input.primary.card_section_background_color) {
			sectionBackgroundColor = getColor(this.props.input.primary.card_section_background_color);
		}
		if (this.props.input.primary.hasOwnProperty('card_section_title_color') && this.props.input.primary.card_section_title_color) {
			sectionTitleColor = getColor(this.props.input.primary.card_section_title_color);
		}
		if (this.props.input.primary.hasOwnProperty('card_section_description_color') && this.props.input.primary.card_section_description_color) {
			sectionFontColor = getColor(this.props.input.primary.card_section_description_color);
		}


		this.dynamicStyles = { backgroundColor: sectionBackgroundColor, color: sectionFontColor };

		return (
			<div className="container">
				<div className="row col-12" style={{ margin: 0, padding: 0 }}>
					<div style={{ backgroundColor: sectionBackgroundColor }} className="row justify-content-center media-heading-section">
						<h2 className="col-12 col-md-9  no-padding no-margin media-section-heading" style={{ color: sectionTitleColor }}>{this.props.input.primary.resource_card_section_title.text}</h2>
						{
							this.props.input.primary.hasOwnProperty("rsource_card_section_subtitle") && this.props.input.primary.rsource_card_section_subtitle &&
							<h3>{this.props.input.primary.rsource_card_section_subtitle.text}</h3>
						}
						<p className="col-12 col-md-10 no-padding no-margin" style={{ color: sectionFontColor }}>{this.props.input.primary.resource_card_section_description.text}</p>
					</div>
					{
						this.props.input.items.map((item, i) => (
							<div className="col-12 col-sm-6 col-lg-4">
								<VideoOverlay videoLink={item.resource_card_video_link} videoDetails={item.resource_card_embed_video} cardDetails={item} pagesLinksData={this.props.pagesLinksData} key={item.resource_card_video_link.url} />
							</div>
						))
					}
				</div>
			</div>
		)
	}
}

export default ResourceMediaCards;
