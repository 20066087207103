import React, { Component } from "react";
import ResourceCard from "./ResourceCard";
import Slider from "react-slick";
import { getColor } from "../utils/universalHelperFunctions";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../styles/multi-cards-carousel.css";

class MultiCardsCarousel extends Component {
  constructor(props) {
    super(props);
    this.cardDataArray = this.props.cardData;
    this.linksData = this.props.pagesLinksData;
  }

  renderCarouselCards(element, key) {
    let singleCard = {};
    singleCard = (
      <ResourceCard
        cardData={element}
        pagesLinksData={this.linksData}
        key={key}
        carousel={true}
        selectedFilter={this.props.selectedFilter}
      />
    );
    return [singleCard];
  }

  render() {
    const settings = {
      lazyLoad: true,
      className: "center",
      centerPadding: "60px",
      infinite: true,
      speed: 1000,
      slidesToShow: 3,
      slidesToScroll: 1,
      initialSlide: 0,
      adaptiveHeight: false,
      autoplay: true,
      autoplaySpeed: 4000,
      dots: false,
      arrows: true,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 2,
            dots: true,
            arrows: false,
          },
        },
        {
          breakpoint: 600,
          settings: {
            infinite: true,
            speed: 1000,
            slidesToShow: 1,
            slidesToScroll: 1,
            initialSlide: 0,
            adaptiveHeight: false,
            autoplay: true,
            autoplaySpeed: 4000,
            dots: true,
            arrows: false,
          },
        },
      ],
    };

    var headingColor =
      this.props.input && this.props.input.primary.carousel_heading_font_color
        ? getColor(this.props.input.primary.carousel_heading_font_color)
        : "#000";
    var backgroundColor =
      this.props.input && this.props.input.primary.carousel_background_color
        ? getColor(this.props.input.primary.carousel_background_color)
        : "#F1F5F6";

    var carouselCardElements = [];
    if (this.cardDataArray.items && this.cardDataArray.items.length > 0) {
      this.cardDataArray.items.forEach((element, key) => {
        if (
          ![
            "resources/white-papers/aiops-in-monitoring1",
            "resources/white-papers/aiops-in-monitoring2",
            "resources/white-papers/aiops-in-monitoring3",
          ].includes(element?.href || element?.url)
        ) {
          carouselCardElements.push(
            <ResourceCard
              cardData={element}
              pagesLinksData={this.linksData}
              key={element.href}
              carousel={true}
              selectedFilter={this.props.selectedFilter}
            />
          );
        }
      });
    }

    let carouselHeadingElement = "";
    if (
      this.props.input &&
      this.props.input.primary.hasOwnProperty("carousel_heading") &&
      this.props.input.primary.carousel_heading.text
    ) {
      carouselHeadingElement = (
        <h2
          className="multi-card-carousel-heading"
          style={{ color: headingColor }}
        >
          {this.props.input && this.props.input.primary.carousel_heading
            ? this.props.input.primary.carousel_heading.text
            : "You May Also Like"}
        </h2>
      );
    } else {
      carouselHeadingElement = (
        <h2
          className="multi-card-carousel-heading"
          style={{ color: headingColor }}
        >
          You May Also Like
        </h2>
      );
    }
    if (carouselCardElements.length <= 2) {
      settings.arrows = false;
      settings.slidesToShow = carouselCardElements.length;
    }

    console.log(this.cardDataArray.items);
    const sliders = <Slider {...settings}>{carouselCardElements}</Slider>;
    return (
      <div
        className="container-wrapper multi-card-carousel-container"
        style={{ background: backgroundColor }}
      >
        <div className="container">
          {carouselHeadingElement}
          {sliders}
        </div>
      </div>
    );
  }
}

export default MultiCardsCarousel;
