import { Component } from "react";
import PropTypes from 'prop-types';
import "../styles/multiCardsWithImages.css";
import Slider from "react-slick";
class MultiCardsWithImages extends Component {
  constructor(props) {
    super(props);
    this.multiCardsWithImagesData = props.input;
    this.linksData = props.pagesLinksData;
  }

  render() {
    let carouselCardElements = [];
    this.multiCardsWithImagesData.items.forEach((element, key) => {
      let cardImageElement = (

        <img
          src={element.multi_cards_image.url}
          alt="header image"
          className="w-75 border rounded d-md-block mx-auto"
        />
      );

      let descriptionElement = (
        <div className="multi-main">
          <div ><b>{element.multi_card_image_heading.text}</b></div>
          <p >{element.multi_card_subheading.text}</p>

        </div>
      )

      carouselCardElements.push(<div className="col-lg-4 col-md-4 col-sm-12" key={key}>
        {cardImageElement}
        {descriptionElement}
      </div>);
    });
    return (

      <div  >
        <div className="container heading-section-container"  >
          <div >
            <h2 className="page-heading">{this.multiCardsWithImagesData.primary.multicards_title.text}</h2>
          </div>
          <div className="header-description-multi" dangerouslySetInnerHTML={{
            __html: this.multiCardsWithImagesData.primary.multicards_description.text

          }} />

          <div className="multi-items">
            {carouselCardElements}
          </div>

        </div>

      </div>
    )

  }
}

export default MultiCardsWithImages;

MultiCardsWithImages.propTypes = {
  input: PropTypes.object,
  pagesLinksData: PropTypes.array
};