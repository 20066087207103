import React from 'react'

function CSRSection({ primary = {}, items = [] }) {

  const { committee_title = {}, description = {}, title = {} } = primary;

  return (
    <section className='ui-slice-csr-section py-5'>
      <div className="container pt-4 text-center">
        <h2 className='experts-section-heading'>
          {title?.text}
        </h2>

        <div className='my-4 expert-role-para'>
          {description?.text}
        </div>

        <h2 className=' mt-5 experts-section-heading mb-2 text-capitalize'>
          {committee_title?.text}
        </h2>

        <div className='ui-slice-csr-section-items row'>
          {(items || [])
            .map(({ member_designation = {}, member_name = {} }, key) => <div className='col-12 col-sm-6 col-md-4 ui-slice-csr-section-items-list' key={key}>
              <div className='card my-2'>
                <div className="card-body ">
                  <div className="card-title mb-1"><strong>{member_name?.text}</strong></div>
                  <div className="card-text text-secondary"> <small>{member_designation?.text}</small></div>
                </div>
              </div>
            </div>)}
        </div>
      </div>
    </section>
  )
}

export default CSRSection