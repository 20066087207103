import React, { Component } from "react"
import PropTypes from 'prop-types';
import {
  Modal, Card, CardText, CardBody
} from "reactstrap";
import {getColor, createUrl} from "../utils/universalHelperFunctions";
import '../styles/video-overlay.css';

class VideoOverlay extends Component {
  constructor(props) {
    super(props);
    this.state = {
      height: typeof window !== 'undefined' ? window.innerHeight : 0,
      width: typeof window !== 'undefined' ? window.innerWidth : 0,
      showOverlay: false,
    };
    this.showOverlaySection = this.showOverlaySection.bind(this);
    this.hideOverlaySection = this.hideOverlaySection.bind(this);
    this.toggle = this.toggle.bind(this);

  }
  componentDidMount() {
    window.addEventListener('resize', (e) => this.onResize());
  }
  onResize() {
    this.setState({
      height: window.innerHeight,
      width: window.innerWidth
    });
  }
  showOverlaySection() {
    this.setState({ showOverlay: true });
  }
  hideOverlaySection() {
    this.setState({ showOverlay: false });
  }
  toggle() {
    this.setState({ showOverlay: !this.state.showOverlay });
  }
  render() {
    let { videoDetails } = this.props;
    let cardBackgroundColor = '#fff';
    let cardFontColor = '#000';
    let button1FontColor = '#000';
    let button2FontColor = '#000';
    var styleObj = {};
    var resourceButton2Element, resourceButton1Element;

    if (this.props.cardDetails) {
      if (this.props.cardDetails.hasOwnProperty('resource_card_background_color') && this.props.cardDetails.resource_card_background_color) {
        cardBackgroundColor = getColor(this.props.cardDetails.resource_card_background_color);
      }
      if (this.props.cardDetails.hasOwnProperty('resource_card_font_color') && this.props.cardDetails.resource_card_font_color) {
        cardFontColor = getColor(this.props.cardDetails.resource_card_font_color);
      }
      if (this.props.cardDetails.hasOwnProperty('resource_card_button1_font_color') && this.props.cardDetails.resource_card_button1_font_color) {
        button1FontColor = getColor(this.props.cardDetails.resource_card_button1_font_color);
      }
      if (this.props.cardDetails.hasOwnProperty('resource_card_button2_font_color') && this.props.cardDetails.resource_card_button2_font_color) {
        button2FontColor = getColor(this.props.cardDetails.resource_card_button2_font_color);
      }

      if (this.props.cardDetails.hasOwnProperty("resource_card_button1_link") && this.props.cardDetails.resource_card_button1_link) {
        resourceButton1Element = (
          <div className="media-card-button">
            <a href={createUrl(this.props.cardDetails.resource_card_button1_link.url, this.props.pagesLinksData)} style={{ color: button1FontColor }} className="media-button-link" hrefLang="en">
              {this.props.cardDetails.resource_card_button1_text.text}
              <i className="fas fa-long-arrow-alt-right media-button-icon"></i>
            </a>
          </div>
        );
      } else {
        resourceButton1Element = null;
      }

      if (this.props.cardDetails.hasOwnProperty("resource_card_button2_link") && this.props.cardDetails.resource_card_button2_link) {
        resourceButton2Element = (
          <div className="media-card-button">
            <a href={createUrl(this.props.cardDetails.resource_card_button2_link.url, this.props.pagesLinksData)} style={{ color: button2FontColor }} className="media-button-link" hrefLang="en">
              {this.props.cardDetails.resource_card_button2_text.text}
              <i className="fas fa-long-arrow-alt-right media-button-icon"></i>
            </a>
          </div>
        );
      } else {
        resourceButton2Element = null;
      }

      styleObj = { backgroundColor: cardBackgroundColor, color: cardFontColor };
    }
    return (
      <>
        <Card className="madia-card" style={{ height: 'inherit' }} >

          {(this.props.videoLink?.url || "").indexOf("prismic.io") !== -1 ? <div className="embed-responsive embed-responsive-16by9">
            <video className="card-video"  poster={this.props.videoThumbnail?.url} controls playsInline preload='auto' width="100%" height="100%">
              <source src={this.props.videoLink.url} type="video/mp4" />
              <source src={this.props.videoLink.url} type="video/ogg" />
              <source src={this.props.videoLink.url} type="video/webm" />
              Your browser does not support HTML5 video.
            </video>
          </div> : 

            <div className="embed-responsive embed-responsive-16by9" dangerouslySetInnerHTML={{ __html: `<iframe className='embed-responsive-item' src=${this.props.videoLink.url} frameBorder="0" allow="autoplay; fullscreen" webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe>` }}>
            </div>
          }
          {
            this.props.cardDetails && (
              <CardBody className="media-card-body" style={styleObj}>
                <CardText className="card-description" style={styleObj}>{this.props.cardDetails.resource_card_description.text}</CardText>
                {resourceButton1Element}
                {resourceButton2Element}
              </CardBody>
            )
          }
        </Card >
        <Modal
          className="video-overlay-modal"
          isOpen={this.state.showOverlay}
          toggle={this.toggle}
        >
          <i className="far fa-times fa-2x close-btn" onClick={this.toggle}></i>
          <video className="card-video" controls playsInline autoPlay preload='auto' width="100%" height="100%">
            <source src={this.props.videoLink.url} type="video/mp4" />
            <source src={this.props.videoLink.url} type="video/ogg" />
            <source src={this.props.videoLink.url} type="video/webm" />
            Your browser does not support HTML5 video.
          </video>
        </Modal>
      </>
    )
  }

}

export default VideoOverlay;

VideoOverlay.propTypes = {
  videoThumbnail: PropTypes.object,
  videoLink: PropTypes.object.isRequired,
  videoDetails: PropTypes.object,
  pagesLinksData: PropTypes.array.isRequired,
  cardDetails: PropTypes.object
};