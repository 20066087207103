import { Component } from "react";
import React from 'react';

import "../styles/piechart.css";
class PieChart extends Component {
  constructor(props) {
    super(props);
    this.pieChartData = props.input;
    this.linksData = props.pagesLinksData;
  }

  render() {
    return (
      <div style={{ backgroundColor: '#F6F5F2', height: 800 }} className="d-none">
        <div className="container heading-section-container"  >
          <div >
            <h2 className="page-heading">{this.pieChartData.primary.header.text}</h2>
          </div>

          <div style={{ height: 490, width: 490, marginLeft: "auto", marginRight: "auto" }}>
            {/* <img src={this.pieChartData.primary.image.url}></img> */}
            <div id="parentDiv">
              <div id="mobility"></div>
              <div id="data" onMouseEnter={this.onMouseEnterHandler}
                onMouseLeave={this.onMouseLeaveHandler} ></div>
              <div id="contentManage" ></div>
              <div id="managed"></div>
              <div id="ecomm"></div>
              <div id="devops"></div>
              <div id="qe"></div>
              <div id="centerDiv"></div>
            </div>
          </div>
          <div className="mobility d-none">
            <div className="mobility-at-gspann">
              Mobility at GSPANN
            </div>
            <div classNmae="our-mobility-experie">
              Our mobility experience helps companies build new capabilities and applications lorem ipsum sit dolor amet elit.
              Venenatis interdum commodo eu, suscipit a tortor porttitor at augue.
            </div>
            <div className="skills-lorem-upsum">
              Skills.  Lorem upsum classes and want to further their lorem ipsum sit dolor amet dignissim quis dolor in porta.
            </div>
            <div className="education-lorem-ups">
              Education.  Lorem upsum classes and want to further their lorem ipsum sit dolor amet dignissim quis dolor in porta.
            </div>
            <div className="career-growth-lorem">
              Career Growth.  Lorem upsum classes and want to further their lorem ipsum sit dolor amet dignissim quis dolor in porta.
            </div>
            <div className="learn-more-about-our">
              Learn more about our Mobility Practice
            </div>
          </div>
        </div>
      </div>
    )
  }

}

export default PieChart;
