import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { getColor, createUrl } from "../utils/universalHelperFunctions";
import '../styles/home-page-heading-section.css';

class HomePageHeadingSection extends Component {
  constructor(props) {
    super(props);
    this.HeadingSectionData = props.input;
    this.linksData = props.pagesLinksData;
    this.toggle = this.toggle.bind(this);
    this.state = {
      toggle: false,
    }
    this.winLocation = "";
  }


  toggle() {
    this.setState(!tooltipOpen);
  }
  render() {
    let headerBodyImage = this.HeadingSectionData.primary.image;
    let winLocation = true;
    
  if (typeof window !== "undefined") {
    winLocation = (!(window?.location?.pathname.includes("services")));
     console.log((!(window?.location?.pathname.includes("services"))));
  }
    let pageHeaderButtonElement;
    let pageHeaderButton = {};
    let pageHeaderButtonLink;
    let pageHeaderBackgroundColor = '#fff';
    let pageHeaderFontColor = '#4a4a4a';
    if (this.HeadingSectionData.primary.hasOwnProperty("page_header_button_text") && this.HeadingSectionData.primary.page_header_button_text.text) {
      pageHeaderButton = this.HeadingSectionData.primary.page_header_button_text.text;
    } else {
      pageHeaderButton = null;
    }

    if (this.HeadingSectionData.primary.hasOwnProperty("page_header_button_link") && this.HeadingSectionData.primary.page_header_button_link.url) {
      try {
        pageHeaderButtonLink = this.HeadingSectionData.primary.page_header_button_link.url;
      } catch (error) {
        pageHeaderButtonLink = null;
      }
    } else {
      pageHeaderButtonLink = null;
    }
    if(this.HeadingSectionData.primary.hasOwnProperty('page_header_background_color') && this.HeadingSectionData.primary.page_header_background_color) {
      pageHeaderBackgroundColor = getColor(this.HeadingSectionData.primary.page_header_background_color);
    }
    if(this.HeadingSectionData.primary.hasOwnProperty('page_header_font_color') && this.HeadingSectionData.primary.page_header_font_color) {
      pageHeaderFontColor = getColor(this.HeadingSectionData.primary.page_header_font_color);
    }
    if (pageHeaderButton) {
      pageHeaderButtonElement = (
        <a className="page-header-button-text" href={createUrl(pageHeaderButtonLink, this.linksData)} hrefLang="en">
          {pageHeaderButton}
        </a>
      );
    } else {
      pageHeaderButtonElement = null;
    }

    if (headerBodyImage && headerBodyImage.url) {
      headerBodyImage = (
        <img alt="header body" className="header-body-img" src={this.HeadingSectionData.primary.image.url} />
      );
    } else {
      headerBodyImage = null;
    }
    let id = "";
    if(this.HeadingSectionData.primary.hasOwnProperty("breadcrumb_id")) {
      id = this.HeadingSectionData.primary.breadcrumb_id.text;
    }
    let pageBodyHTMLContent = null;
    if(this.HeadingSectionData.primary.hasOwnProperty('page_body_html_content') && this.HeadingSectionData.primary.page_body_html_content.text) {
      pageBodyHTMLContent = (
        <div
          style={{paddingTop: '40px'}}
          dangerouslySetInnerHTML={{
            __html: this.HeadingSectionData.primary.page_body_html_content.text
          }}
        />
      )
    }

    

    return (
      <div style={{backgroundColor: pageHeaderBackgroundColor}} id={id}>
        <div className={`heading-section-container container ${this.HeadingSectionData.primary.mobile_visibility === "false" ? "visible-for-desktop" : ""}`}>
          {
            this.props.pageHeroData?.items[0]?.hero_headline && this.props.pageHeroData?.items[0]?.hero_headline?.text && winLocation ? (
              <h1 className="home-page-sub-heading"> {this.HeadingSectionData.primary.heading.text}</h1>
            ) : (
              <h2 className="home-page-sub-heading"> {this.HeadingSectionData.primary.heading.text}</h2>
            )
          }
          <div
            className="para-style"
            style={{color: pageHeaderFontColor}}
            dangerouslySetInnerHTML={{
              __html: this.HeadingSectionData.primary.body.html,
            }}
          />
          {pageBodyHTMLContent}
          {headerBodyImage}
          {pageHeaderButtonElement}
        </div>
      </div>
    );
    // return <div>{this.HeadingSectionData.primary.heading.text}</div>;
  }
}

export default HomePageHeadingSection;

HomePageHeadingSection.propTypes = {
  input: PropTypes.object.isRequired,
};
