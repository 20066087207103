import React, { Component } from "react";
import PropTypes from "prop-types";
import "../styles/cardsWithHeader.css";

export default class CardsWithHeader extends Component {
  constructor(props) {
    super(props);
    this.CardsWithHeader = props.input;
    this.linksData = props.pagesLinksData;
  }

  render() {
    let back_boolean = this.CardsWithHeader.primary.back_colr_boolean;
    let head_boolean = this.CardsWithHeader.primary.heading_boolean;
    let carouselCardElements = [];
    this.CardsWithHeader.items.forEach((element, key) => {
      let descriptionHead = (
        <div className="">
          <div className="page_head">
            <b>{element.card_title.text}</b>
          </div>
          <br></br>
        </div>
      );

      let cardImageElement = (
        <div className="align-image1">
          <img src={element.card_img.url} alt="header image" className="img" />
        </div>
      );

      let descriptionElement = (
        <div className="">
          <p className="img_desc_1">{element.card_desc.text}</p>
        </div>
      );
      
      let linkElement = (
        element?.linkElement?.url ? <a className="" href={element?.linkElement?.url}>
          <p className="link" >{element.card_link.text}</p>
        </a> : null
      );

      carouselCardElements.push(
        <div
          name={descriptionHead.props.children[0].props?.children?.props?.children?.replaceAll(" ", "_")}
          className="" key={key}>
          <div className="card_desc_ele">
            {cardImageElement}
            {descriptionHead}
            {descriptionElement}
            <>{linkElement}</>
          </div>
        </div>
      );
    });
    return (<div className="scroll_margin" id={carouselCardElements[0].props.name}>
      <div className={back_boolean ? 'back_color1' : 'no_back_color1'}>

        <div className="container heading-section-container_cwh">
          {console.log("Insights ddwwwww", carouselCardElements[0].props.name)}
          <span>
            {/* <h1  className={head_boolean?'heading':'no_heading'}>  */}
            <h2 className='heading'>
              {this.CardsWithHeader.primary.card_header.text}
            </h2>
          </span>

          <div className="rect_style">{carouselCardElements}</div>
        </div>
      </div></div>
    );
  }
}

CardsWithHeader.propTypes = {
  input: PropTypes.object,
  pagesLinksData: PropTypes.array,
};
