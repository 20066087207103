import React, { Component } from "react";
import { PropTypes } from "prop-types";
import { Button } from "reactstrap";

class InlineButton extends Component {
    constructor(props) {
      super(props);
      this.linksData = props.pagesLinksData;
      this.handleSubmit = this.handleSubmit.bind(this);
      this.overlayFormBannerData = props.input;
    }

    handleSubmit(url){
window.open(url,"_blank")
    }

    render(){

        let downloadUrl = this.overlayFormBannerData.primary.file_content.url;
        let buttonText = this.overlayFormBannerData.primary.banner_buttton_text.text;
        return(

        <div className="container " style={{marginTop:"-30px",marginBottom:"30px"}}><Button color="primary" onClick={(e)=>this.handleSubmit(downloadUrl)}>

            {buttonText}
            </Button></div>
        )
    }
}

export default InlineButton;

InlineButton.propTypes = {
  input: PropTypes.object.isRequired,
  pagesLinksData: PropTypes.array
};
