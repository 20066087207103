import { Component } from "react";
import PropTypes from "prop-types";
import "../styles/adobeExpertise.css";
import { Link } from 'react-scroll';
import { motion } from "framer-motion";

export default class AdobeExpertise extends Component {
  constructor(props) {
    super(props);
    this.AdobeExpertise = props.input;
    this.linksData = props.pagesLinksData;
  }

  render() {
    let carouselCardElements = [];
    let contentCommerceElements = [];
    let engagementManagementElements = [];
    let audiencesInsightsElements = [];
    let experienceManagementList = this.AdobeExpertise.items.filter(ele => ele.card_identifier.text === 'Experience Management');
    let contentCommerceList = this.AdobeExpertise.items.filter(ele => ele.card_identifier.text === 'Content & Commerce');
    let engagementManagementList = this.AdobeExpertise.items.filter(ele => ele.card_identifier.text === 'Engagement Management');
    let audiencesInsightsList = this.AdobeExpertise.items.filter(ele => ele.card_identifier.text === 'Audiences & Insights');




    let audiencesInsights = (<div className="cards" >
      <div className="card_heading">
        Audiences & Insights
      </div>
      {

        audiencesInsightsList.forEach(element => {

          let cardImageElement = (
            <img src={element.card_img.url} alt="header image" className="card_img" />
          );

          let descriptionElement = (
            <div className="">
              <p className="">{element.card_title.text}</p>
            </div>
          );
          audiencesInsightsElements.push(
            <Link to={descriptionElement.props.children.props.children.replaceAll(" ", "_")}>
              <motion.div whileHover={{ scale: 1.1 }}>   <div className="element_style3">
                <div className="in_alingn">
                  <div className="">{cardImageElement}</div>
                  <div className="card_elemt_desc">{descriptionElement}</div>

                </div>
              </div>
              </motion.div>
            </Link>
          );


        })



      }
      <div> {audiencesInsightsElements}  </div>
    </div>)





    let engagementManagement = (<div className="cards" >
      <div className="card_heading">
        Engagement Management
      </div>
      {

        engagementManagementList.forEach(element => {

          let cardImageElement = (
            <img src={element.card_img.url} alt="header image" className="card_img" />
          );

          let descriptionElement = (
            <div className="">
              <p className="">{element.card_title.text}</p>
            </div>
          );
          engagementManagementElements.push(<Link to={descriptionElement.props.children.props.children.replaceAll(" ", "_")}>
            <motion.div whileHover={{ scale: 1.1 }}>
              <div className="element_style2">
                <div className="in_alingn">
                  <div className="">{cardImageElement}</div>
                  <div className="card_elemt_desc">{descriptionElement}</div>
                </div>
              </div>
            </motion.div>
          </Link>
          );


        })



      }
      <div> {engagementManagementElements}  </div>
    </div>)







    let contentCommerce = (<div className="cards" >
      <div className="card_heading">
        Content & Commerce
      </div>
      {

        contentCommerceList.forEach(element => {

          let cardImageElement = (
            <img src={element.card_img.url} alt="header image" className="card_img" />
          );

          let descriptionElement = (
            <div className="">
              <p className="">{element.card_title.text}</p>
            </div>
          );
          contentCommerceElements.push(<Link to={descriptionElement.props.children.props.children.replaceAll(" ", "_")}>
            <motion.div whileHover={{ scale: 1.1 }}>
              <div className="element_style1">
                <div className="in_alingn">
                  <div className="">{cardImageElement}</div>
                  <div className="card_elemt_desc">{descriptionElement}</div>
                </div>
              </div></motion.div></Link>
          );


        })



      }
      <div> {contentCommerceElements}  </div>
    </div>)


    let experienceManagement = (
      <div className="cards_EM" >
        <div className="card_heading">
          Experience Management
        </div>
        {

          experienceManagementList.forEach(element => {

            let cardImageElement = (
              <img src={element.card_img.url} alt="header image" className="card_img" />
            );

            let descriptionElement = (
              <div className="">
                <p className="">{element.card_title.text}</p>
              </div>
            );
            carouselCardElements.push(<Link to={descriptionElement.props.children.props.children.replaceAll(" ", "_")}>
              <motion.div whileHover={{ scale: 1.1 }}>
                <div className="element_style1">
                  <div className="in_alingn">
                    <div className="">{cardImageElement}</div>
                    <div className="card_elemt_desc">{descriptionElement}</div>
                  </div>
                </div></motion.div></Link>
            );


          })



        }
        <div> {carouselCardElements}  </div>
      </div>)




    // this.AdobeExpertise.items.forEach((element, key) => {
    //   let descriptionHead = (
    //     <div className="card_heading">
    //       <div className="">
    //         <b>{element.card_heading.text}</b>

    //       </div>
    //       <br></br>
    //     </div>
    //   );



    //   let cardImageElement = (
    //     <img src={element.card_img.url} alt="header image" className="card_img" />
    //   );

    //   let descriptionElement = (
    //     <div className="">
    //       <p className="">{element.card_title.text}</p>
    //     </div>
    //   );

    //   carouselCardElements.push(
    //     <div className="cards" key={key}>
    //       {descriptionHead}

    //         <div className="element-style">
    //           <div className="in_alingn">
    //           <div className="">{cardImageElement}</div>
    //           <div className="card_elemt_desc">{descriptionElement}</div>
    //           </div>
    //         </div>
    //     </div>
    //   );
    // });
    return (
      <div className="container-fluid bg-gradient-gray">
        <div className="align_AE container">
          <div>
            <h2 className="page_heading_exp">
              {this.AdobeExpertise.primary.heading.text}
            </h2>
          </div>
          <div
            className="header_description_multi"
            dangerouslySetInnerHTML={{
              __html: this.AdobeExpertise.primary.desc.text,
            }}
          />
          <div className="row py-4"
          // style={{display:'flex'}}
          >
            <div className="col-md-4 col-12 pb-3 ui__card__container">{contentCommerce} {experienceManagement} </div>
            {/* <div className="multi">{experienceManagement}</div> */}
            <div className="col-md-4 col-12 pb-3 ui__card__container">{audiencesInsights} </div>
            <div className="col-md-4 col-12 pb-3 ui__card__container">{engagementManagement}</div>
          </div>
        </div>
      </div>
    );
  }
}

AdobeExpertise.propTypes = {
  input: PropTypes.object,
  pagesLinksData: PropTypes.array,
};
