import { Component } from "react";
import PropTypes from "prop-types";

export default class ResourceOvierviewButton extends Component {
  constructor(props) {
    super(props);
    this.ResourceOvierviewButton = props.input;
    this.linksData = props.pagesLinksData;
  }

  render() {
    let button_name = this.ResourceOvierviewButton.primary.button_name;
    let button_link = this.ResourceOvierviewButton.primary.button_link;
    let button_desc = this.ResourceOvierviewButton.primary.button_desc;

    return (
      <div>
        <h1>helloooo</h1>
        <button onclick={button_link?.url}>{button_name}</button>
        {button_desc}
      </div>
    );
  }
}

ResourceOvierviewButton.propTypes = {
  input: PropTypes.object,
  pagesLinksData: PropTypes.array,
};
