import React, { Component } from "react";
import PropTypes from "prop-types";
import { getColor, createUrl } from "../utils/universalHelperFunctions";
import HomePageResouceComponent from "../slices/HomePageResouceComponent";
import "../styles/home-page-resource-section.css";

class HomePageResouceSection extends Component {
  constructor(props) {
    super(props);
    this.linksData = props.pagesLinksData;
    this.HomePageResouceSectionData = props.input;
    this.homePageFeaturedResourceData = props.homePageFeaturedResourceData;
    this.homepageEventsData = props.homepageEventsData;
    this.insightData = props.insightData;
    this.state = {
      isFocused: false,
    }
  }
  
  handleMouseEnter = (activeIndex) => {
    this.setState({ isFocused: true});
  }

  handleMouseLeave = (activeIndex) => {
    this.setState({ isFocused: false });
  }

  render() {
    this.backgroundImageUrl = this.HomePageResouceSectionData.primary.resource_background_image.url;
    this.imageAltText = this.HomePageResouceSectionData.primary.resource_background_image.alt;
    let sectionHeadingFontColor = this.HomePageResouceSectionData.primary.section_heading_font_color ?  getColor(this.HomePageResouceSectionData.primary.section_heading_font_color) : '#fff';
    let sectionSubheadingFontColor = this.HomePageResouceSectionData.primary.section_subheading_font_color ? getColor( this.HomePageResouceSectionData.primary.section_subheading_font_color) : '#e7eeef';
    let sectionDescriptionFontColor = this.HomePageResouceSectionData.primary.section_description_font_color ? getColor(this.HomePageResouceSectionData.primary.section_description_font_color) : '#000';
    let sectionBtnFontColor = this.HomePageResouceSectionData.primary.section_button_font_color ? getColor(this.HomePageResouceSectionData.primary.section_button_font_color) : '#313542';
    let sectionBtnBackgroundColor = this.HomePageResouceSectionData.primary.section_button_background_color ?  getColor(this.HomePageResouceSectionData.primary.section_button_background_color) : '#fff';
    let careerBtnFontColor = this.HomePageResouceSectionData.primary.career_button_font_color ? getColor( this.HomePageResouceSectionData.primary.career_button_font_color) : '#e7eeef';
    let careerBtnBackgroundColor = this.HomePageResouceSectionData.primary.career_button_background_color ? getColor(this.HomePageResouceSectionData.primary.career_button_background_color) : '#000';

    let buttonStyleObj = {
      color: sectionBtnFontColor,
      background: sectionBtnBackgroundColor,
      borderColor: sectionBtnBackgroundColor
    }
    let buttonHoverStyleObj = {
      color: sectionBtnBackgroundColor,
      background: sectionBtnFontColor,
      borderColor: sectionBtnBackgroundColor
    }
    let resourceButtonLinkElement;
    if (this.HomePageResouceSectionData.primary.resource_button_text && this.HomePageResouceSectionData.primary.resource_button_text.text
      && this.HomePageResouceSectionData.primary.resource_button_link && this.HomePageResouceSectionData.primary.resource_button_link.url) {
      resourceButtonLinkElement = (
        <div className="resource-container-button">
          <a 
            href={createUrl(this.HomePageResouceSectionData.primary.resource_button_link.url, this.linksData)}
            className="button-events"
            hrefLang="en"
            onMouseEnter={this.handleMouseEnter} 
            onMouseLeave={this.handleMouseLeave}
            style={this.state.isFocused ? buttonHoverStyleObj : buttonStyleObj}
          >
            {this.HomePageResouceSectionData.primary.resource_button_text.text}
          </a>
        </div>
      );
    } else {
      resourceButtonLinkElement = null;
    }

    if (this.HomePageResouceSectionData.primary.resource_type.toLowerCase() === "Events".toLowerCase()) {
      console.log('eventCardData :', this.homePageFeaturedResourceData);
      const upcommingEvent = this.homePageFeaturedResourceData.filter(item => item.event_type === 'Upcoming');
      let sectionBackground = this.HomePageResouceSectionData.primary.resource_background_color ? getColor(this.HomePageResouceSectionData.primary.resource_background_color) : '#fff';
      return (
        <div
          style={ sectionBackground && sectionBackground !== '#fff' && sectionBackground !== '#ffffff' && sectionBackground !== "rgb(255, 255, 255)" && sectionBackground.toLowerCase() !== "white" ? { backgroundColor: sectionBackground, paddingTop: upcommingEvent.length ? '50px': '0px', paddingBottom: '50px' } : { backgroundColor: '#fff', paddingTop: upcommingEvent.length ? '50px': '0px', marginBottom: '50px' }}
          className="parent-container-resource"
        >
          <h2 className="resouce-heading">
            {
              this.HomePageResouceSectionData.primary
                .resource_section_subheading.text
            }
          </h2>
          {upcommingEvent.length > 0 && <h3 className="resouce-sub-heading">
            {
              this.HomePageResouceSectionData.primary.resource_section_heading
                .text
            }
          </h3>}

          <HomePageResouceComponent
            input={{
              sliceName: "events",
              eventsData: this.HomePageResouceSectionData,
              eventCardData: this.homepageEventsData

            }}
            pagesLinksData={this.linksData}
          />
        </div>
      );
    } else if (this.HomePageResouceSectionData.primary.resource_type.toLowerCase() === "Featured Resources".toLowerCase()) {
      /*White background color for Latest Insights Resource Slice */
      return (
        <div
          className="featured-resource-container-section"
          style={this.HomePageResouceSectionData.primary.resource_background_color ? { backgroundColor: getColor(this.HomePageResouceSectionData.primary.resource_background_color), paddingTop: '50px' } : { backgroundColor: '#fff' }}
        >
          <HomePageResouceComponent
            input={{
              sliceName: "featuredResource",
              eventsData: this.HomePageResouceSectionData,
              eventCardData: this.homePageFeaturedResourceData
            }}
            pagesLinksData={this.linksData}
          />
          {resourceButtonLinkElement}
        </div>
      );
    } else {
      /*If no color i.e background color = null, then there must be background Image 
    for Resource section Background */

      /*Image in background for Career Resource Slice */
      //  const BACKGROUND_IMAGE_URL = "../Image/";
      return (
        //  <div style={{ background: "#FAD7A0" }}>
        <div className="careers-container-section bg-image">
          <div className="img-cls d-none d-sm-block">
            <img src={this.backgroundImageUrl} loading="lazy" alt={this.imageAltText} />
          </div>
          <HomePageResouceComponent
            input={{
              sliceName: "Careers",
              eventsData: this.HomePageResouceSectionData,
              jobListData: this.props.jobListData
            }}
            pagesLinksData={this.linksData}
          />
        </div>
      );
    }
  }
}

export default HomePageResouceSection;

HomePageResouceSection.propTypes = {
  input: PropTypes.object.isRequired,
  pagesLinksData: PropTypes.array
};
