import React, { Component } from "react";
import PropTypes from "prop-types";
import { getColor } from "../utils/universalHelperFunctions";
import "../styles/home-page-partner-banner.css";

class HomePagePartnerBanner extends Component {
  constructor(props) {
    super(props);
    this.partnersObj = props.partnerBannerData;
    this.state = {};
    this.dynamicStyles = {};
  }

  render() {
    const urls = {
      Adobe:'/resources/all-resources/##Adobe',
      Salesforce:'/resources/all-resources/##Salesforce',
      Google:'/resources/all-resources/##Google%20Cloud%20Platform',
      AWS:'/resources/all-resources/##Amazon%20Web%20Services',
      Microsoft:'/resources/all-resources/##Azure',
      Tricentis:'/',
    }
    let id = "";
    let techBackgroundColor = '##3498DB';
    let techFontColor = '#fff';
    if (this.partnersObj.primary.hasOwnProperty('tech_background_color') && this.partnersObj.primary.tech_background_color) {
      techBackgroundColor = getColor(this.partnersObj.primary.tech_background_color);
    }
    if (this.partnersObj.primary.hasOwnProperty('tech_font_color') && this.partnersObj.primary.tech_font_color) {
      techFontColor = getColor(this.partnersObj.primary.tech_font_color);
    }

    this.dynamicStyles = { backgroundColor: techBackgroundColor, color: techFontColor };

    if (this.partnersObj.primary.hasOwnProperty("breadcrumb_id")) {
      id = this.partnersObj.primary.breadcrumb_id.text;
    }
    return (
      <div className="partner-section" style={this.dynamicStyles} id={id} >
        <h6 className="partner-section-title">
          {this.partnersObj.primary.banner_tech_title.text}
        </h6>
        <div className="logos-section container">
          {this.partnersObj.items.map((logo, i) => (
            <a href={urls[logo.partner_logo.alt.split(' ')[0]]}>
            <div className="logo-container" key={logo.partner_logo.alt}>
              <img className="partner-logo" loading="lazy" src={logo.partner_logo.url} alt={logo.partner_logo.alt} />
            </div>
            </a>
          ))}
        </div>
      </div>
    );
  }
}

export default HomePagePartnerBanner;

HomePagePartnerBanner.propTypes = {
  partnerBannerData: PropTypes.object.isRequired
};