import React, { Component } from 'react';
import { Container, Row, Col } from 'reactstrap';
import '../styles/filter-resources.css';

class FilterResources extends Component {
  constructor(props) {
    super(props);
    this.selectedResourceFilter = '';
    this.filterList = [];
    this.handleClick = this.handleClick.bind(this);
    this.shouldRadioChecked = this.shouldRadioChecked.bind(this);
    this.state = {
      word: '',
      isRadioChecked: true,
      cardDataArray: props.cardData,
      dummyInputButtons: [
        {
          input: {
            id: 'allResources',
            buttonsClassname: 'btn-labels',
            text: 'All Resources',
            checked: true,
            hash: 'All-Resources',
          },
        },
        {
          input: {
            id: 'blogs',
            buttonsClassname: 'btn-labels',
            text: 'Blogs',
            checked: false,
            hash: 'Blogs',
          },
        },
        {
          input: {
            id: 'whitePapers',
            buttonsClassname: 'btn-labels',
            text: 'White Papers',
            checked: false,
            hash: 'White-Papers',
          },
        },
        {
          input: {
            id: 'caseStudies',
            text: 'Case Studies',
            buttonsClassname: 'btn-labels',
            checked: false,
            hash: 'Case-Studies',
          },
        },
        {
          input: {
            id: 'events',
            buttonsClassname: 'btn-labels',
            text: 'Events',
            checked: false,
            hash: 'Events',
          },
        },
      ],
    };
  }

  componentDidMount() {
    const optionsObj = {
      events: 'Events',
      caseStudies: 'Case-Studies',
      blogs: 'Blogs',
      whitePapers: 'White-Papers',
    };
    const isBrowser = () => typeof window !== "undefined"

    let hash = isBrowser() && window.location.hash?.split('#');
    if (isBrowser()) {
      if (hash.length && hash[1]) {
        localStorage.setItem("resourceFlag", 'true');
        localStorage.setItem("resourceFilter", hash[1]);
      } else {
        localStorage.setItem("resourceFlag", 'false');
        localStorage.setItem("resourceFilter", '');
      }

      if (hash.length && hash[2]) {
        localStorage.setItem('resourceTagFlag', 'true');
        localStorage.setItem('resourceTag', decodeURI(hash[2]));
      } else {
        localStorage.setItem('resourceTagFlag', 'false');
        localStorage.setItem('resourceTag', '');
      }
    }
    let queryString = window.location.search;
    const { dummyInputButtons } = this.state;
    // if (queryString && queryString.length > 0) {
    //   const resources = this.state.dummyInputButtons;
    //   resources.forEach(element => {
    //     if (optionsObj[element.input.id]) {
    //       if (queryString.toLowerCase().indexOf(optionsObj[element.input.id].trim().replace(' ', '_').toLowerCase()) > -1) {
    //         element.input.checked = true;
    //         this.selectedResourceFilter = optionsObj[element.input.text];
    //       } else {
    //         element.input.checked = false;
    //       }
    //     } else {
    //       element.input.checked = false;
    //     }
    //   });
    //   this.setState({ dummyInputButtons: resources });
    //   this.filterList.push({ type: 'checkbox', value: this.selectedResourceFilter === 'Case Studies' ? 'Case Study' : this.selectedResourceFilter });
    // }
    let breadCrumbFlag = localStorage.getItem('breadCrumbFlag');
    if (breadCrumbFlag === null || breadCrumbFlag === 'false') {
      localStorage.removeItem('breadCrumbFlag');
      localStorage.removeItem('breadCrumbFilter');
    } else {
      // localStorage.removeItem('breadCrumbFlag');
      // localStorage.setItem('resourceTagFlag', 'false');
      // localStorage.setItem('resourceFlag', 'false');
      let breadCrumbFilter = localStorage.getItem('breadCrumbFilter');
      localStorage.removeItem('breadCrumbFilter');
      if (breadCrumbFilter) {
        const resources = this.state.dummyInputButtons;
        resources.forEach(element => {
          if (optionsObj[element.input.id]) {
            if (breadCrumbFilter.toLowerCase().indexOf(element.input.text.trim().replace(' ', '-').toLowerCase()) > -1) {
              this.selectedResourceFilter = element.input.text;
              element.input.checked = true;
            } else {
              element.input.checked = false;
            }
          } else {
            element.input.checked = false;
          }
        });
        this.setState({ dummyInputButtons: resources });
        this.filterList.push({ type: 'checkbox', value: this.selectedResourceFilter === 'Case Studies' ? 'Case Study' : this.selectedResourceFilter })
      }
    }

    // let flag = localStorage.getItem('resourceFlag');
    // if (flag === null || flag === 'false') {
    //   localStorage.setItem('resourceFlag', 'false');
    //   localStorage.removeItem('resourceFilter');
    // } else {
    //   localStorage.setItem('resourceFlag', 'false');
    //   let filter = localStorage.getItem('resourceFilter');
    //   if (filter) {
    //     const resources = this.state.dummyInputButtons;
    //     this.selectedResourceFilter = '';
    //     for (let i = 0; i < resources.length; i++) {
    //       if (optionsObj[resources[i].input.text] === filter) {
    //         resources[i].input.checked = true;
    //         this.selectedResourceFilter = filter;
    //         this.filterList.push({ type: 'checkbox', value: this.selectedResourceFilter});
    //       } else {
    //         resources[i].input.checked = false;
    //       }
    //     }
    //     localStorage.setItem("resourceFilter", this.selectedResourceFilter);
    //     this.setState({ dummyInputButtons: resources });
    //   }
    // }

    //localStorage.setItem('resourceFlag', 'false');
    let filter = localStorage.getItem('resourceFilter');
    if (filter) {
      const { dummyInputButtons } = this.state;
      this.selectedResourceFilter = '';
      for (let i = 0; i < dummyInputButtons.length; i++) {
        if (dummyInputButtons[i].input.hash === decodeURI(filter)) {
          dummyInputButtons[i].input.checked = true;
          this.selectedResourceFilter = dummyInputButtons[i].input.text;
          filter = dummyInputButtons[i].input.hash;
          this.filterList.push({ type: 'checkbox', value: this.selectedResourceFilter === 'Case Studies' ? 'Case Study' : this.selectedResourceFilter });
        } else {
          dummyInputButtons[i].input.checked = false;
        }
      }
      localStorage.setItem("resourceFilter", filter);
      this.setState({ dummyInputButtons: dummyInputButtons });
    }

    let searchWordFlag = localStorage.getItem('searchWordFlag');
    if (searchWordFlag === null || searchWordFlag === 'false') {
      localStorage.setItem('searchWordFlag', 'false');
      localStorage.removeItem('searchWord');
    } else {
      localStorage.setItem('searchWordFlag', 'false');
      let searchWord = localStorage.getItem('searchWord');
      if (searchWord) {
        this.setState({
          word: localStorage.getItem('searchWord')
        });
        this.filterList.push({ type: 'searchWord', value: searchWord });
      }
    }

    let resourceTagFlag = localStorage.getItem('resourceTagFlag');
    if (resourceTagFlag === null || resourceTagFlag === 'false') {
      localStorage.setItem('resourceTagFlag', 'false');
      localStorage.removeItem('resourceTag');
    } else {
      localStorage.setItem('resourceTagFlag', 'false');
      let resourceTags = localStorage.getItem('resourceTag');
      if (resourceTags) {
        this.filterList.push({ type: 'resourceTags', value: resourceTags })
      }
    }
    if (this.filterList && this.filterList.length > 0) {
      this.props.selectedFilter(this.filterList);
    }

    this.updateBasingOnUrl();

  }

  updateBasingOnUrl() {
    let queryHash = window.location.hash;
    localStorage.setItem('queryHash', queryHash);
    if (queryHash) {
      let splitQueryHash = queryHash.split("#");
      //let buttonContext = "";
      // if (splitQueryHash.length > 1) {
      //   buttonContext = splitQueryHash[0] + splitQueryHash[1];
      //   let finalValue = "";
      //   if (buttonContext === "blogs") {
      //     finalValue = 'blogs'
      //   }
      //   else if (buttonContext === "white-papers") {
      //     finalValue = 'whitePapers'
      //   }
      //   else if (buttonContext === "case-studies") {
      //     finalValue = 'caseStudies'
      //   } else if (buttonContext === "events") {
      //     finalValue = 'events'
      //   }
      //   //let eve = { target: { id: finalValue } }
      //   //this.handleClick(eve);
      // }
      if (splitQueryHash.length > 2) {
        localStorage.setItem('resourceTagFlag', 'true');
        localStorage.setItem('resourceTag', decodeURI(splitQueryHash[2]));
        let resourceTags = decodeURI(splitQueryHash[2]);
        if (resourceTags) {
          let filterText = this.state.dummyInputButtons.find(item => item.input.hash === resourceTags)?.text;
          this.filterList.push({ type: 'resourceTags', value: filterText })
        }
        if (this.filterList && this.filterList.length > 0) {
          this.props.selectedFilter(this.filterList);
        }
      }
    }
  }


  componentDidUpdate() {
    //if(window.location.hash !== localStorage.getItem("queryHash")){
    //this.updateBasingOnUrl();
    //}
    // let resourceTagFlag = localStorage.getItem('resourceTagFlag');
    // if (resourceTagFlag === null || resourceTagFlag === 'false') {
    //   localStorage.setItem('resourceTagFlag', 'false');
    //   //localStorage.removeItem('resourceTag');
    // } else {
    let resourceTags = localStorage.getItem('resourceTag');
    let filterText = this.state.dummyInputButtons.find(item => item.input.hash === resourceTags)?.text;
    if (filterText) {
      let found = this.filterList.some(el => el.type === 'resourceTags');
      if (found) {
        this.filterList.map(item => {
          if (item.type === 'resourceTags') {
            item.value = filterText;
          }
        });
      } else {
        this.filterList.push({ type: 'resourceTags', value: filterText });
      }
    }
    //}
  }
  static getDerivedStateFromProps(props, state) {
    if (props.cardData && (JSON.stringify(props.cardData) !== JSON.stringify(state.cardDataArray))) {
      return {
        cardDataArray: props.cardData,
      }
    }

    return null;
  }

  shouldRadioChecked() {
    const resources = this.state.dummyInputButtons;
    let isTrue = false;
    for (let i = 0; i < resources.length; i++) {
      if (resources[i].input.type === 'checkbox') {
        if (resources[i].input.checked) {
          isTrue = true;
        }
      }
    }
    return !isTrue;
  }

  getHashUrl() {
    return localStorage.getItem('resourceTag') ? `#${localStorage.getItem("resourceFilter")}#${localStorage.getItem('resourceTag')}` : `#${localStorage.getItem("resourceFilter")}`;
  }

  handleClick(event) {
    const optionsObj = {
      events: 'Events',
      caseStudies: 'Case-Studies',
      blogs: 'Blogs',
      whitePapers: 'White-Papers',
    };
    const id = event.target.id;
    const resources = this.state.dummyInputButtons;
    localStorage.removeItem('resourceTagFlag');
    localStorage.removeItem('resourceTag');
    let selectedFilter = this.selectedResourceFilter;
    resources.forEach(item => {
      if (item.input.id === id) {

        if (id === 'allResources') {
          selectedFilter = '';
          localStorage.removeItem("resourceFlag");
          localStorage.removeItem("resourceFilter");
        } else {
          selectedFilter = item.input.text;
          item.input.checked = true;
          localStorage.setItem("resourceFlag", 'true');
          localStorage.setItem("resourceFilter", item.input.hash);
        }
      } else {
        item.input.checked = false;
      }
    });
    this.selectedResourceFilter = selectedFilter;
    if (this.selectedResourceFilter) {
      localStorage.setItem("resourceFlag", 'true');
      let hash = this.state.dummyInputButtons.find(item => item.input.text === this.selectedResourceFilter)?.input.hash
      console.log('##### hash : ', hash);
      localStorage.setItem("resourceFilter", hash);
      window.location.hash = this.getHashUrl();
    } else {
      resources.map(item => {
        if (item.input.id === 'allResources') {
          item.input.checked = true;
        }
      })
    }
    this.setState({ dummyInputButtons: resources });
    let found = this.filterList.some(el => el.type === 'checkbox');
    if (found) {
      this.filterList.map(item => {
        if (item.type === 'checkbox') {
          item.value = this.selectedResourceFilter === 'Case Studies' ? 'Case Study' : this.selectedResourceFilter;
        }
      });
    } else {
      this.filterList.push({ type: 'checkbox', value: this.selectedResourceFilter === 'Case Studies' ? 'Case Study' : this.selectedResourceFilter })
    }
    this.props.selectedFilter(this.filterList);
  }

  handleChange(value) {
    localStorage.setItem('searchWordFlag', 'true');
    localStorage.setItem('searchWord', value);
    this.setState({
      word: value,
    });
    this.props.selectedFilter([{ type: 'searchWord', value }]);
  }

  render() {
    let messageContainer = "";
    if (!this.state.cardDataArray.items.length && this.state.word) {
      messageContainer = (
        <div className="message-container">
          <div className="message">
            <span>
              We're sorry, we could not find any resources with "{this.state.word}".
            </span>
            <span>
              Please try your search again.
            </span>
          </div>
        </div>
      );
    }

    return (
      <div className="resource-background" id="refine-resources-filters">
        <Container>
          <Row noGutters className="refine-resources" >
            <Col className="col-sm-12 refine-btns" style={{ display: 'block' }}>
              {this.state.dummyInputButtons.map((item, index) => (
                <div className={`btns ${item.input.checked ? 'selected-btn' : ''}`} key={index}>
                  <div
                    onClick={this.handleClick}
                    id={item.input.id}
                    className={`${item.input.buttonsClassname}`}
                  >
                    {item.input.text}
                  </div>
                </div>
              ))}
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}
export default FilterResources;
