import React, { Component } from "react";
import { Container, Row, Col, Collapse, FormGroup, Label, Input, Form } from 'reactstrap';
import "../styles/job-position.css";
import { isCountryIndia } from "../utils/universalHelperFunctions";

class JobFilter extends Component {
  constructor(props) {
    super(props);
    this.toggle = this.toggle.bind(this);
    this.filterLocationByCountry();
    // this.filterObj = this.props.input;
    this.state = { collapse: false, filterdObj: [] };
    this.toggleContries = this.toggleContries.bind(this);
    this.closeJobFilter = this.closeJobFilter.bind(this);
  }

  filterLocationByCountry = () => {
    const INDIAN_LOCATIONS = ['India', 'Hyderabad', 'Gurugram', 'Bangalore', 'Noida', 'Pune', 'Delhi'];
    const OTHER_LOCATIONS = ['Los Angeles, CA', 'Mexico', 'Miami, FL (Onsite)', 'Orlando, FL', 'Portland', 'San Francisco', 'Sunnyvale']; 
    const ALL_LOCATIONS =  ['India', 'Hyderabad', 'Gurugram', 'Bangalore', 'Noida', 'Pune', 'Delhi', 'USA', 'Los Angeles, CA', 'Mexico', 'Miami, FL (Onsite)', 'Orlando, FL', 'Portland', 'San Francisco', 'Sunnyvale', 'Chennai'];  
    const isIndia = isCountryIndia()
    let {categoryName, categoryValue} = {...this.props.input};
    if(categoryName === 'Location') {
      if(isIndia) {
      categoryValue = categoryValue.filter(value => {
        const hasIndianState = ALL_LOCATIONS.filter(state => {
          return value?.toLowerCase().includes(state.toLowerCase());
        }).length > 0;
        return isIndia ? hasIndianState : !hasIndianState
      });
    } else {
      categoryValue = categoryValue.filter(value => {
        const hasIndianState = OTHER_LOCATIONS.filter(state => {
          return value?.toLowerCase().includes(state.toLowerCase());
        }).length > 0;
        return !isIndia ? hasIndianState : !hasIndianState
      });
    }
      this.filterObj = {
        ...this.props.input,
        categoryValue
      }
    } else {
      this.filterObj = this.props.input;
    }
  }

  componentDidMount() {
    let flag = false;
    for (var item of this.filterObj.categoryValue) {
      if (this.props.data.map(x => x.value).indexOf(item) >= 0) {
        flag = true;
        break;
      }
    }
    this.setState({ collapse: flag });
  }
  closeJobFilter() {
    this.setState({ collapse: false });
  }
  toggle() {
    //uncomment this code for auto closing the filter section
    //this.props.closeAllFilterSections();
    this.setState({ collapse: !this.state.collapse });
  }

  // To select and unselet the countries
  toggleContries(item, category, categoryType) {
    if (this.props.data.map(x => x.value).indexOf(item) < 0) {
      this.props.actionAdd(item, category, categoryType)
    } else {
      this.props.actionRemove(item, category)
    }
  }

  isLocationCheckBoxDesabled = (item) => {
    const { data } = this.props;
    return data.findIndex(x=> x.value === 'Anywhere in India') > -1 && item !== 'Anywhere in India';
  }

  render() {
    let data = this.props.data;
    return (
      <Container>
        <Row>
          <Col md={12} className="job-filter">
            <div role="button">
              <div className="position-item" onClick={this.toggle}>
                <h6 className="job-filter-heading">{this.filterObj.categoryName}</h6>
                <button className="add-jobs"><i className={this.state.collapse ? 'fas fa-angle-up filter-arrow-icon' : 'fas fa-angle-down filter-arrow-icon'}></i></button>
              </div>
              <Collapse isOpen={this.state.collapse}>
                <ul className={`filter-list ${this.filterObj.categoryValue && this.filterObj.categoryValue.length > 10 ? 'filter-scroll' : ''}`} >
                  {this.filterObj.categoryValue.map((item, i) => (
                    <li key={i}>
                      <Form>
                      <FormGroup check>
                        <Input type="checkbox" name="check" id={`filterCheckBox${item}Test`} onChange={() => this.toggleContries(item, this.filterObj.categoryName, this.filterObj.categoryType)} checked={data.map(x => x.value).indexOf(item) >= 0 ? true : false} disabled={this.isLocationCheckBoxDesabled(item)}/>
                        <Label className="checkbox-label" for={`filterCheckBox${item}`} check={data.map(x => x.value).indexOf(item) >= 0 ? true : false}>{item}</Label>
                      </FormGroup>
                      </Form>
                    </li>
                  ))}
                </ul>
              </Collapse>
            </div>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default JobFilter;
