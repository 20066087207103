import React, { Component } from 'react';
import PropTypes from 'prop-types';
import '../styles/card-design.css';
import { getColor, createUrl } from "../utils/universalHelperFunctions";
import ResourceCard from "./ResourceCard";
import TileCardComponent from "./TileCardComponent";
import InformationCardComponent from "./InformationCardComponent";
import { Container, Row } from 'reactstrap';

class HomePageCardComponent extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.linksData = props.pagesLinksData;
    this.state = {
      width: 800,
      height: 182,
      cardColumns: [],
      cardDataArray: props.cardData,
    };
    this.slice_type = props.cardData.slice_type;
  }

  /**
   * Add event listener
   */
  componentDidMount() {
    this._isMounted = true;
    this.updateDimensions();
    window.addEventListener('resize', this.updateDimensions.bind(this));
  }

  static getDerivedStateFromProps(props, state) {
    if(props.cardData && (JSON.stringify(props.cardData) !== JSON.stringify(state.cardDataArray))) {
      return {
        cardDataArray: props.cardData,
      }
    }

    return null;
  }

  /**
   * Remove event listener
   */
  componentWillUnmount() {
    this._isMounted = false;
    window.removeEventListener('resize', this.updateDimensions.bind(this));
  }

  /**
   * Calculate & Update state of new dimensions
   */
  updateDimensions() {
    if (this._isMounted) {
      if (window.innerWidth < 500) {
        this.setState({ width: 450, height: 102 });
      } else {
        const updateWidth = window.innerWidth - 100;
        const updateHeight = Math.round(updateWidth / 4.4);
        this.setState({ width: updateWidth, height: updateHeight });
      }
    }
  }

  /** to render resource cards */
  renderResourceCards(item, key) {
    // to hold card background URL
    let cardResourceBackgroundURL;
    let { cardDataArray } = this.state;
    // checking card image property
    if (cardDataArray.items[key].thumbNail) {
      cardResourceBackgroundURL = cardDataArray.items[key].thumbNail.url;
    } else {
      cardResourceBackgroundURL = null;
    }

    let cardResourceButtonText;
    let cardResourceButtonTextElement = {};
    let uniqueId = cardDataArray.items[key].id;

    // checking card button text property
    if (cardDataArray.items[key].resourceType) {
      cardResourceButtonText = cardDataArray.items[key].resourceType;
    } else {
      cardResourceButtonText = null;
    }

    let cardResourceDescriptionText;
    let cardResourceDescriptionElement = {};

    // checking card description property
    if (cardDataArray.items[key].displayName) {
      cardResourceDescriptionText = cardDataArray.items[key].displayName;
    } else {
      cardResourceDescriptionText = null;
    }

    let cardResourceLink;

    // checking href property
    if (cardDataArray.items[key].href) {
      cardResourceLink = cardDataArray.items[key].href || cardDataArray.items[key].url;
    } else {
      cardResourceLink = null;
    }

    // checking visibility for card heading
    if (cardResourceDescriptionText) {
      cardResourceDescriptionElement = <span className="card-resource-description">{cardResourceDescriptionText}</span>;
    } else {
      cardResourceDescriptionElement = null;
    }

    // checking visibility for card heading
    if (cardResourceButtonText) {
      cardResourceButtonTextElement = <span className="card-resource-button">{cardResourceButtonText}</span>;
    } else {
      cardResourceButtonTextElement = null;
    }

    let cardResourceIconElement;
    if (cardResourceButtonText) {
      let logo = `icon-${cardResourceButtonText.toLowerCase().split(' ').join('-').trim()}`;
      cardResourceIconElement = <img src={require(`./../../static/${logo}.svg`).default} alt={`${cardResourceButtonText} logo`} className="resource-icon" />
    } else {
      cardResourceIconElement = null;
    }

    let singleCardElement = {};
    singleCardElement = (
      <div key={uniqueId} className="card-wrapper col-12 col-sm-6 col-md-6 col-lg-4">
        <div className="resource-card-container">
          <a href={createUrl(cardResourceLink, this.linksData)} hrefLang="en">
            <div className='card-background-img'>
                <img src={cardResourceBackgroundURL} alt={cardDataArray.items[key].thumbNail.alt} className="resource-card-img"></img>
            </div>
            <div className="resource-content-wrapper">
              <div className="resource-title-container">
                {cardResourceIconElement}
                {cardResourceButtonTextElement}
              </div>
              {cardResourceDescriptionElement}
            </div>
          </a>
        </div>
      </div>
    );

    return [singleCardElement];
  }

  getDateContent(startDateInput, endDateInput) {
    let startDateContent = new Date(startDateInput);
    let startMonth = new Intl.DateTimeFormat('en-US', { month: 'long' }).format(startDateContent);
    let startYear = startDateContent.getFullYear();
    let startDate = startDateContent.getDate();
    let startWeekDay = new Intl.DateTimeFormat('en-US', { weekday: 'long' }).format(startDateContent);

    let endDateContent = new Date(endDateInput);
    let endMonth = new Intl.DateTimeFormat('en-US', { month: 'long' }).format(endDateContent);
    let endYear = endDateContent.getFullYear();
    let endDate = endDateContent.getDate();
    let endWeekDay = new Intl.DateTimeFormat('en-US', { weekday: 'long' }).format(endDateContent);

    if (startYear === endYear && startMonth === endMonth && startDate === endDate && startWeekDay === endWeekDay) {
      return `${startWeekDay}, ${startMonth} ${startDate}, ${startYear}`;
    } else if (startYear === endYear) {
      return `${startMonth} ${startDate} - ${endMonth} ${endDate}, ${endYear}`;
    } else {
      return `${startMonth} ${startDate}, ${startYear} - ${endMonth} ${endDate}, ${endYear}`;
    }
  }

  getTimeContent(startDateInput, endDateInput) {
    startDateInput = new Date(startDateInput);
    endDateInput = new Date(endDateInput);

    let options = {
      hour: 'numeric', minute: 'numeric',
    };
    startDateInput = new Intl.DateTimeFormat('en-US', options).format(startDateInput);
    endDateInput = new Intl.DateTimeFormat('en-US', options).format(endDateInput);
    return `${startDateInput} - ${endDateInput}`;
  }

  /** to render General cards */
  renderGeneralCards(item, key) {
    /*
      Checking for all the card related property exists or not in the reponse object from Prismic
  */
    // defining cardBackground element and ojbect
    let cardBackgroundURL;
    let cardBackground = {};
    let { cardDataArray } = this.state;

    // checking card image property
    if (cardDataArray.items[key].card_image) {
      cardBackground = cardDataArray.items[key].card_image.url;
    } else {
      cardBackground = null;
    }

    // defining card logo element and object
    let logoImageElement;
    let logoImage = {};

    // checking card_logo property
    if (cardDataArray.items[key].card_logo) {
      logoImage = cardDataArray.items[key].card_logo.url;
    } else {
      logoImage = null;
    }

    // defining card button element and object
    let cardButtonElement;
    let cardButton = {};

    // checking card_button_text property
    if (cardDataArray.items[key].hasOwnProperty('card_button_text')) {
      cardButton = cardDataArray.items[key].card_button_text.text;
    } else {
      cardButton = null;
    }
    // defining card button element and object
    let cardButtonLink;
    let cardWebUrl;
    // checking card_button_text property
    if (cardDataArray.items[key].hasOwnProperty('card_button_link')) {
      try {
        cardButtonLink = cardDataArray.items[key].card_button_link.url;
        if (cardButtonLink.indexOf('{host}') > -1) {
          cardWebUrl = cardButtonLink.split('{host}')[1];
          cardWebUrl = `${window.location.origin}${cardWebUrl}`;
        }

      } catch (error) {
        cardButtonLink = null;
      }
    } else {
      cardButtonLink = null;
    }
    
    // defining card heading element and object
    let cardHeadingElement;
    let cardHeading = {};

    // checking card_heading property
    if (cardDataArray.items[key].card_heading) {
      cardHeading = cardDataArray.items[key].card_heading.text;
    } else {
      cardHeading = null;
    }

    // defining card subheading element and object
    let cardSubHeadingElement;
    let cardSubHeading = {};

    // checking card_sub_heading property
    if (cardDataArray.items[key].card_subheading) {
      cardSubHeading = cardDataArray.items[key].card_subheading.text;
    } else {
      cardSubHeading = null;
    }

    // fetching card description
    let cardDescriptionElement, cardLocationElement, walkinDescriptionElement, walkinDateElement, walkinTimeElement;
    let cardDescription = {};

    // checking card_description property
    if (item.cardType === 'walkin-card') {
      if (item.event_end_date_and_time && item.event_start_date_and_time) {
        walkinDateElement = (
          <p>{this.getDateContent(item.event_start_date_and_time, item.event_end_date_and_time)}</p>
        )
        walkinTimeElement = (
          <p>{this.getTimeContent(item.event_start_date_and_time, item.event_end_date_and_time)}</p>
        );
      } else {
        walkinDateElement = null;
        walkinTimeElement = null;
      }
      if (item.card_description && item.card_description.text) {
        walkinDescriptionElement = (
          <p>{item.card_description.text}</p>
        )
      } else {
        walkinDescriptionElement = null;
      }
      if (item.event_location && item.event_location.text) {
        cardLocationElement = (
          <p>{item.event_location.text}</p>
        )
      } else {
        cardLocationElement = null;
      }

      if (logoImage) {
        cardDescriptionElement = (
          <div className="card-desc-product">
            {walkinDescriptionElement}
            {walkinDateElement}
            {walkinTimeElement}
            {cardLocationElement}
          </div>
        );
      } else {
        cardDescriptionElement = (
          <div className="card-desc">
            {walkinDescriptionElement}
            {walkinDateElement}
            {walkinTimeElement}
            {cardLocationElement}
          </div>
        );
      }
    } else {
      if (cardDataArray.items[key].card_description) {
        cardDescription = cardDataArray.items[key].card_description.text;
      } else {
        cardDescription = null;
      }

      // checking visiblity for card description
      if (logoImage) {
        cardDescriptionElement = <p className="card-desc-product">{cardDescription}</p>;
      } else {
        cardDescriptionElement = <p className={`card-desc ${this.slice_type==='cards_with_no_border'?'card-display-center':''}`}>{cardDescription}</p>;
      } 
    }

    if (cardBackground) {
      cardBackgroundURL = cardBackground;
    } else {
      cardBackgroundURL = null;
    }   

    if (logoImage) {
      logoImageElement = (
        <img key={key}
          alt={cardDataArray.items[key].card_logo.alt}
          style={{ maxWidth: '50%', maxHeight: '15%', marginBottom: 'auto' }}
          src={cardDataArray.items[key].card_logo.url}
        />
      );
    } else {
      logoImageElement = null;
    }

    
    const resourceCardBackground = {};
    let resourceCardBackgroundElement;

    // checking card_description property

    /*
     * Checking visibility of elements based on design requirement.
    */

    // checking visiblity for card logo
    if (logoImage) {
      logoImageElement = (
        <img key={key}
          alt={cardDataArray.items[key].card_logo.alt}
          style={{ maxWidth: '50%', maxHeight: '15%', marginBottom: 'auto' }}
          src={cardDataArray.items[key].card_logo.url}
        />
      );
    } else {
      logoImageElement = null;
    }

    // checking visibility for card heading
    if (logoImage) {
      cardHeading = null;
    } else {
      cardHeadingElement = (
        <h4 className={`card-heading ${item.cardType === 'walkin-card' ? 'walkin-card-heading' : this.slice_type==="cards_with_no_border"?'card-font-weight':''}`} key={key} style={{ display: cardHeading ? 'block' : 'none' }}>
          {cardHeading}
        </h4>
      );
    }

 // checking card background
 if (cardBackground && this.slice_type==='cards_with_no_border') {
  logoImageElement  = (
    <img key={key+cardDataArray.items[key].card_image.url}
      alt={cardDataArray.items[key].card_image.alt}
      style={{ maxWidth: '50%', marginBottom: 'auto',marginRight:'auto',marginLeft:'auto',
      height: '100px',
      width: '100px',
      marginBottom: '10px',
       }}
      src={cardDataArray.items[key].card_image.url}
    />
  );
} else {
  logoImageElement = null;
}

    // checking visibility for card sub heading
    if (logoImage) {
      cardSubHeading = null;
    } else {
      cardSubHeadingElement = (
        <p className="card-sub-heading" style={{ display: cardSubHeading ? 'block' : 'none' }}>
          {cardSubHeading}
        </p>
      );
    }

    // checking visiblity for card button
    if (cardButton) {
      if (item.cardType === 'walkin-card') {
        cardButtonElement = (
          <div className="walkin-register-button">
            <a className="walkin-card-button-text" href={createUrl(cardButtonLink, this.linksData)} hrefLang="en">
              {cardButton}
            </a>
          </div>
        );
      } else {
        cardButtonElement = (
          <a className="card-button-text" href={cardWebUrl || createUrl(cardButtonLink, this.linksData)} hrefLang="en">
            {cardButton}
          </a>
        );
      }
    } else {
      cardButtonElement = null;
    }

    let singleCardElement = {};

    let cardBackgroundColor;
    if (cardDataArray.items[key].hasOwnProperty('card_background_color') && cardDataArray.items[key].card_background_color) {
      cardBackgroundColor = getColor(cardDataArray.items[key].card_background_color);
    }

    singleCardElement = (
      <div className={`col-12 col-sm-6 col-lg-4 ${this.slice_type==='cards_with_no_border'?'card-no-border':''}`} key={key}>
        <div key={key} className={`card-design ${item.cardType === 'walkin-card' ? 'walkin-card-design' : this.slice_type==='cards_with_no_border'?'card-no-border':''}`} style={{backgroundColor: cardBackgroundColor}}>
          {logoImageElement}
          {cardHeadingElement}
          {cardSubHeadingElement}
          <div className={`card-desc-container ${this.slice_type==='cards_with_no_border'?'card-display-center':''}`}>
          {cardDescriptionElement}
          {cardButtonElement}
          </div>
        </div>
      </div>
    );

    return [singleCardElement];
  }

  render() {
    // Object which contains all the CardElements to render
    const finalCardElements = [];
    let { cardDataArray } = this.state;

    let parentStyle = null;
    // fetching all Cards data one by one
    cardDataArray.items.forEach((item, key) => {
      // to save individual cards Element to push in Final card element
      let cardElements = {};
      /**
       * Checking if the card type is general card or Resource card by using
       * card_background_resource property
       */
      if (this.props.cardType === "Tiles") {
        cardElements = <TileCardComponent tileData={item} pagesLinksData={this.props.pagesLinksData} key={key} className="col-12" />
        finalCardElements.push(cardElements);
      } else if (item.cardType && item.cardType === "resource-card") {
        // calling function renderResourceCards to render Resource Type Cards
        if(item.pagePath !== 'campaign' && item.bannerHeader!=="GSPANN builds on its heritage with Adobe") {
          cardElements = <ResourceCard cardData={item} pagesLinksData={this.props.pagesLinksData} key={item.id} selectedFilter={this.props.selectedFilter} />
          // pushing each individual card into Final Card Element
          finalCardElements.push(cardElements);
        }
      } else if (this.props.cardType === "Information Card") {
        parentStyle={gridRowGap: '50px', marginTop: '50px'}
        cardElements = <InformationCardComponent infoCardData={item} pagesLinksData={this.props.pagesLinksData} key={key} className="col-12" />
        finalCardElements.push(cardElements);
      } else {
        // calling function renderGeneralCards to render Resource Type Cards
        cardElements = this.renderGeneralCards(item, key);
        // pushing each individual card into Final Card Element
        finalCardElements.push(cardElements);
      }
    });

    return (
      <div className={this.props.type === 'ResourceCards' ? 'resource-background' : ''}>
        <Container>
          <Row className="parent-container no-margin noGutters Offerings-block" style={parentStyle}>{finalCardElements}</Row>
        </Container>
      </div>
    );
  }
}

export default HomePageCardComponent;

HomePageCardComponent.propTypes = {
  cardData: PropTypes.object.isRequired,
  pagesLinksData: PropTypes.array
};
