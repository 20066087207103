import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { getColor } from "../utils/universalHelperFunctions";
import HomePageCardComponent from "./HomePageCardComponent";
import '../styles/home-page-card-section.css';

class HomePageCardSection extends Component {
  constructor(props) {
    super(props);
   // console.log(props.type);
    this.linksData = props.pagesLinksData;
    this.CardSectionData = props.input;
  }

  render() {
    let backgroundImageUrl = {};
    let sectionFontColor = '#4a4a4a';
    let sectionBackgroundColor = null;

    if (this.CardSectionData.primary.hasOwnProperty('card_section_background_color') && this.CardSectionData.primary.card_section_background_color) {
      sectionBackgroundColor = getColor(this.CardSectionData.primary.card_section_background_color);
    }

    if (this.CardSectionData.primary.hasOwnProperty('card_section_font_color') && this.CardSectionData.primary.card_section_font_color) {
      sectionFontColor = getColor(this.CardSectionData.primary.card_section_font_color);
    }

    // checking if CardSectionData contains card_section_background_image property
    // , if yes then setting the value in backgroundImageUrl
    if (this.CardSectionData.primary.hasOwnProperty('card_section_background_image')) {
      backgroundImageUrl = this.CardSectionData.primary.card_section_background_image.url;
    } else {
      backgroundImageUrl = null;
    }

    // getting card Section Title data from Card Section Data and setting it in cardSectionTitle
    const cardSectionTitle = this.CardSectionData.primary?.card_section_title?.text;

    // getting card Section Description data from Card Section Data and setting it in cardSectionDescription
    const cardSectionDescription = this.CardSectionData.primary.card_section_description && this.CardSectionData.primary.card_section_description.text;

    // defining card Sub title element and object
    let cardSectionSubTitleElement, multilineDescriptionElement;
    let cardSectionSubTitle = {};

    // checking if CardSectionData contains card_section_subtitle property
    // , if yes then setting the value in cardSectionSubTitle
    if (this.CardSectionData.hasOwnProperty('card_section_subtitle')) {
      cardSectionSubTitle = this.CardSectionData.primary?.card_section_subtitle?.text;
    } else {
      cardSectionSubTitle = null;
    }

    // if card Section Sub Title Element exists then creating cardSectionSubTitleElement
    if (cardSectionSubTitle) {
      cardSectionSubTitleElement = (
        <h2 className={backgroundImageUrl ? 'header-industries' : 'header-products'}>{cardSectionSubTitle}</h2>
      );
    } else {
      cardSectionSubTitleElement = null;
    }

    let cardSectionDescriptionElement;

    // if card Section Description Element exists then creating cardSectionDescriptionElement
    if (cardSectionDescription) {
      cardSectionDescriptionElement = (
        <p className="card-description col-12 col-md-10" style={{ color: sectionFontColor }}>{this.CardSectionData.primary.card_section_description.text}</p>
      );
    } else {
      cardSectionDescriptionElement = null;
    }
    let id = "";
    if (this.CardSectionData.primary.hasOwnProperty("breadcrumb_id")) {
      id = this.CardSectionData.primary.breadcrumb_id.text;
    }

    if (this.CardSectionData.primary.card_section_multiline_description && this.CardSectionData.primary.card_section_multiline_description.html) {
      multilineDescriptionElement = (
        <div className="card-description col-12" dangerouslySetInnerHTML={{
          __html: this.CardSectionData.primary.card_section_multiline_description.html
        }}>
        </div>
      )
    } else {
      multilineDescriptionElement = null;
    }
    // creating the final Component based on the data
    return (
      <div
        className={backgroundImageUrl ? 'parent-container-card-section trans-layer' : 'parent-container-card-section'}
        style={backgroundImageUrl ? {
          backgroundImage: `url(${backgroundImageUrl})`, paddingTop: '50px', paddingBottom: '50px', marginBottom: '50px'
        } : (sectionBackgroundColor && sectionBackgroundColor !== '#fff' && sectionBackgroundColor !== '#ffffff' && sectionBackgroundColor !== "rgb(255, 255, 255)") ? { backgroundColor: sectionBackgroundColor, paddingTop: '50px'} : { paddingTop: '50px',marginBottom: '50px' }}
        id={id}
      >
        <div className="container" style={{ display: cardSectionTitle ? 'visible' : 'none' }}>
          <div className="row justify-content-center">
            <h2 className={`${backgroundImageUrl ? 'header-industries' : 'header-products'} col-12 col-md-9`}>
              {this.CardSectionData.primary?.card_section_subtitle?.text}
            </h2>
            <h2 className={`${backgroundImageUrl ? 'sub-header-industries' : 'sub-header-products'} col-12 col-md-9`}>
              {this.CardSectionData.primary?.card_section_title?.text}
            </h2>

            {cardSectionDescriptionElement}
            {multilineDescriptionElement}
          </div>
        </div>

        {/* sending Cards data to Card Component which renders cards layour accordingly. */}
        <HomePageCardComponent cardType={this.CardSectionData.primary.card_type} cardData={this.CardSectionData} pagesLinksData={this.linksData} />
      </div >
    );
  }
}

export default HomePageCardSection;

HomePageCardSection.propTypes = {
  input: PropTypes.object,
  pagesLinksData: PropTypes.array
};
