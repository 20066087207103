import React, { useState, useEffect, useRef } from "react";
import { Helmet } from "react-helmet";
import { Container, Row, Col, Button } from "reactstrap";
import { createUrl } from "../utils/universalHelperFunctions";
import CollapseContentSection from "../slices/CollapseContentSection";
import '../styles/virtual-walkin-details.css';

function VirtualWalkinDetails(props) {
    let data = props.input;
    const [relatedJobs, setRelatedJobs] = useState([]);

    const handleRegisterBtnClick = () => {
      window.scroll({
        top: document.querySelector('.jobs-walkin-container').offsetTop - 100,
        left: 0, 
        behavior: 'smooth',
      });
    }

    const handleSocialMediaClick = (e) => {
      e.preventDefault();
      let url = 'https://www.facebook.com/sharer.php?display=popup&u=' + window.location.href;
      window.open(url);
    }

    const handleLinkedInSocialMediaClick = (e) => {
      e.preventDefault();
      let url = `https://www.linkedin.com/shareArticle?mini=true&url=${encodeURIComponent(window.location.href)}&title=${encodeURIComponent(data.primary.virtual_walkin_title.text)}&summary=${encodeURIComponent(data.primary.virtual_walkin_content1.text)}&source=${encodeURIComponent(source)}`;
      window.open(url);
    }

    const handleTwitterSocialMediaClick = (e) => {
      e.preventDefault();
      let url = `https://twitter.com/share?url=${encodeURIComponent(window.location.href)}&text=${encodeURIComponent(data.primary.virtual_walkin_title.text)}`;
      window.open(url);
    }

    const getRelatedJobList = (openedJob, minResult, maxResult) => {
        let finalResourceDataObject = {};
        let data = props.jobListData.filter(item => item.node.data.include_job_in_list !== false);
        data = data.filter(item => item.node.uid !== openedJob.uid);
        data.sort((job1, job2) => {
          const job1ItemBody = job1.node.data.body;
          const job2ItemBody = job2.node.data.body;
          const openedJobBody = openedJob.data.body;
          const indexForJobTag = obj =>
            obj.__typename === "PrismicJobDescriptionPageBodyJobTags";
          const indexForJobDescription = obj =>
            obj.__typename === "PrismicJobDescriptionPageBodyJobDescription";
          const indexForJobKeyDetails = obj =>
            obj.__typename === "PrismicJobDescriptionPageBodyKeyDetails";
          const indexForPrimarySkills = obj =>
            obj.__typename === "PrismicJobDescriptionPageBodyPrimarySkills";
          //const jobTagIndex = itemBody.findIndex(indexForJobTag);
          const job1DescriptionIndex = job1ItemBody.findIndex(indexForJobDescription);
          const job1KeyDetsilsIndex = job1ItemBody.findIndex(indexForJobKeyDetails);
          const job2DescriptionIndex = job2ItemBody.findIndex(indexForJobDescription);
          const job2KeyDetsilsIndex = job2ItemBody.findIndex(indexForJobKeyDetails);
          const openedJobDescriptionIndex = openedJobBody.findIndex(indexForJobDescription);
          const openedjobPrimarySkillsIndex = openedJobBody.findIndex(indexForPrimarySkills);
          const job1PrimarySkillsIndex = job1ItemBody.findIndex(indexForPrimarySkills);
          const job2PrimarySkillsIndex = job2ItemBody.findIndex(indexForPrimarySkills);

          var regExToRemoveBrackets = new RegExp(/\(([^)]+)\)/g);
          let openedJobSkills = openedjobPrimarySkillsIndex > -1 && openedJobBody[openedjobPrimarySkillsIndex] && openedJobBody[openedjobPrimarySkillsIndex].items && openedJobBody[openedjobPrimarySkillsIndex].items.length > 0 && openedJobBody[openedjobPrimarySkillsIndex].items.map(item => item.technology);
          openedJobSkills = openedJobSkills.join(',').replace(/ like | or | and |like |any | & /gi, ',');
          openedJobSkills = openedJobSkills.replace(regExToRemoveBrackets, ",$1");
          openedJobSkills = openedJobSkills.replace(/\//g, ',');
          var openedJobSkillsArr = openedJobSkills.split(',');
          openedJobSkillsArr = openedJobSkillsArr.filter(function (el) {
            return el !== null || el !== "" || el !== 'undefined' || el !== " ";
          });
          openedJobSkillsArr = openedJobSkillsArr.map(item => item.trim());
          
          let openedJobPosition = openedJobBody[openedJobDescriptionIndex].primary.job_title && openedJobBody[openedJobDescriptionIndex].primary.job_title.text.replace(/ or | and | & /gi, '');
          let regExRemoveSpecialChar = new RegExp(/[\(\)-\/]/g);
          openedJobPosition = openedJobPosition.replace(regExToRemoveBrackets, "");
          var openedJobPositionArr = openedJobPosition.split(' ');
          openedJobPositionArr = openedJobPositionArr.filter(function (el) {
            return el !== null || el !== "" || el !== 'undefined' || el !== " ";
          });

          let job1SkillsArr = [];
          if(job1PrimarySkillsIndex > -1) {
            let PrimarySkills1 = job1ItemBody[job1PrimarySkillsIndex] && job1ItemBody[job1PrimarySkillsIndex].items && job1ItemBody[job1PrimarySkillsIndex].items.length > 0 && job1ItemBody[job1PrimarySkillsIndex].items.map(item => item.technology);
            PrimarySkills1 = PrimarySkills1.join(',').replace(/ like | or | and |like |any | & /gi, ',');
            PrimarySkills1 = PrimarySkills1.replace(regExToRemoveBrackets, ",$1");
            PrimarySkills1 = PrimarySkills1.replace(/\//g, ',');
            job1SkillsArr = PrimarySkills1.split(',');
            job1SkillsArr = job1SkillsArr.filter(function (el) {
              return el !== null || el !== "" || el !== 'undefined' || el !== " ";
            });
            job1SkillsArr = job1SkillsArr.map(item => item.trim());
          }
          
          var noOfMatchForJob1 = job1SkillsArr && job1SkillsArr.length > 0 ? job1SkillsArr.reduce(function(count, skills){
            let found = openedJobSkillsArr && openedJobSkillsArr.some(el => el.trim().toLowerCase() === skills.trim().toLowerCase());
            if(found)
            count++;
            return count;
          },0) : 0;

          let PrimaryPosition1 = job1ItemBody[job1DescriptionIndex].primary.job_title && job1ItemBody[job1DescriptionIndex].primary.job_title.text.replace(/ or | and | & /gi, '');
          PrimaryPosition1 = PrimaryPosition1.replace(regExToRemoveBrackets, "");
          var job1PositionArr = PrimaryPosition1.split(' ');
          job1PositionArr = job1PositionArr.filter(function (el) {
            return el !== null || el !== "" || el !== 'undefined' || el !== " ";
          });
          noOfMatchForJob1 = job1PositionArr && job1PositionArr.length > 0 ? job1PositionArr.reduce(function(count, positions){
            let found = openedJobPositionArr && openedJobPositionArr.some(el => el.trim().toLowerCase() === positions.trim().toLowerCase());
            if(found)
            count++;
            return count;
          },noOfMatchForJob1) : noOfMatchForJob1;

          job1.count = noOfMatchForJob1;

          let job2SkillsArr = [];
          if(job2PrimarySkillsIndex > -1) {
            let PrimarySkills2 =  job2ItemBody[job2PrimarySkillsIndex] && job2ItemBody[job2PrimarySkillsIndex].items && job2ItemBody[job2PrimarySkillsIndex].items.length > 0 && job2ItemBody[job2PrimarySkillsIndex].items.map(item => item.technology);
            PrimarySkills2 = PrimarySkills2.join(',').replace(/ like | or | and |like |any | & /gi, ',');
            PrimarySkills2 =  PrimarySkills2.replace(regExToRemoveBrackets, ",$1");
            PrimarySkills2 = PrimarySkills2.replace(/\//g, ',');
            job2SkillsArr = PrimarySkills2.split(',');
            job2SkillsArr = job2SkillsArr.filter(function (el) {
              return el !== null || el !== "" || el !== 'undefined' || el !== " ";
            });
            job2SkillsArr = job2SkillsArr.map(item => item.trim());
          }

          var noOfMatchForJob2 = job2SkillsArr && job2SkillsArr.length > 0 ? job2SkillsArr.reduce(function(count, skills){
            let found = openedJobSkillsArr && openedJobSkillsArr.some(el => el.trim().toLowerCase() === skills.trim().toLowerCase());
            if(found)
            count++;
            return count;
          },0) : 0;

          let PrimaryPosition2 = job2ItemBody[job2DescriptionIndex].primary.job_title && job2ItemBody[job2DescriptionIndex].primary.job_title.text.replace(/ or | and | & /gi, '');
          PrimaryPosition2 = PrimaryPosition2.replace(regExToRemoveBrackets, "");
          var job2PositionArr = PrimaryPosition2.split(' ');
          job2PositionArr = job2PositionArr.filter(function (el) {
            return el !== null || el !== "" || el !== 'undefined' || el !== " ";
          });
          noOfMatchForJob2 = job2PositionArr && job2PositionArr.length > 0 ? job2PositionArr.reduce(function(count, positions){
            let found = openedJobPositionArr && openedJobPositionArr.some(el => el.trim().toLowerCase() === positions.trim().toLowerCase());
            if(found)
            count++;
            return count;
          }, noOfMatchForJob2) : noOfMatchForJob2;

          job2.count = noOfMatchForJob2;

          if(noOfMatchForJob1 > noOfMatchForJob2) { return -1  }
          if(noOfMatchForJob1 < noOfMatchForJob2) { return 1 }
          return 0;
        });

        var revelentResult = [];
        data.forEach(item => {
          if(revelentResult[item.count] && revelentResult[item.count].length > 0) {
            revelentResult[item.count].push(item);
          } else {
            revelentResult[item.count] = [];
            revelentResult[item.count].push(item);
          }
        });
        let notMatchedData = revelentResult[0] && revelentResult[0].length ? revelentResult.splice(0, 1)[0] : [];
        revelentResult = revelentResult.filter(Array);
        revelentResult.reverse();
        revelentResult.forEach(list => {
          list.sort((a, b) => {
              return new Date(b.StartDate).getTime() - new Date(a.StartDate).getTime();
            });
        })
        if(revelentResult && revelentResult.length > 0) {
          finalResourceDataObject.items = [];
          for (var i=0, len=revelentResult.length; i<len; i++) {
            for (var j=0, len2=revelentResult[i].length; j<len2; j++) {
              if(finalResourceDataObject.items.length < maxResult) {
                finalResourceDataObject.items.push( revelentResult[i][j] ); 
              } else {
                break;
              }
            }
          }
        }

        if(finalResourceDataObject && (!finalResourceDataObject.items || finalResourceDataObject.items < minResult)) {
          if(!finalResourceDataObject.items) {
            finalResourceDataObject.items = [];
          }
          notMatchedData.sort((a, b) => {
            return new Date(b.StartDate).getTime() - new Date(a.StartDate).getTime();
          });
          for (var i=0, len=notMatchedData.length; i<len; i++) {
            if(finalResourceDataObject.items.length < minResult) {
              finalResourceDataObject.items.push(notMatchedData[i] ); 
            } else {
              break;
            }
          }
        }

        setRelatedJobs(finalResourceDataObject);
    }

    const generateFormatedDate = (dateVal) => {
      if(dateVal) {
        try {
          let startDate = new Date(dateVal);
          let month = new Intl.DateTimeFormat('en-US', { month: 'long' }).format(startDate);
          let year = startDate.getFullYear();
          let date = startDate.getDate();
          let formattedDate = `${date} ${month} ${year}`;
  
          return formattedDate;
  
        } catch (error) {
          console.log(error)
        }
      }
    }

    useEffect(() => {

      if(props.jobListForWalkin.items && props.jobListForWalkin.items.length > 0 && props.jobListForWalkin.items[0].job_link){
        if(props.jobListForWalkin.items[0].job_link.document) {
          let relatedJob = props.jobListForWalkin.items[0].job_link.document[0];
          if(relatedJob.data.body.findIndex(obj => obj.__typename === "PrismicJobDescriptionPageBodyPrimarySkills") > -1) {
            getRelatedJobList(relatedJob, 2, 3);
          }
        }
      }
    }, props.jobListForWalkin.items);

    const handleBackButton = () => {
      if (typeof window !== 'undefined') {
        window.history.back();
      }
    }

    let source = typeof window !== 'undefined' ? window.location.hostname : 'Gspann';
    return (
        <>
        <Helmet>
          <meta property="og:title" content={data.primary.virtual_walkin_title.text} />
          <meta property="og:description" content={data.primary.virtual_walkin_content1.text} />
          <meta property="og:image" content="https://images.prismic.io/gspann/1f4f1bc1-78d1-4b5b-ae1e-118c8d74c6df_job.jpeg?auto=compress,format"/>
          <meta name="twitter:card" content="summary_large_image"/>
          <meta name="twitter:image" content="https://images.prismic.io/gspann/f84bd4fa-91ad-46de-948e-10e0ce438f3f_Hdpi+-+1440+x+500.jpg?auto=compress,format"/>
        </Helmet>
        <Container className="walkin-section">
          <Row className="back-to-search-btn" noGutters>
            <i className="fa fa-angle-left back-to-search-arrow" onClick={handleBackButton}></i>
            <button className="back-to-search" onClick={handleBackButton}> Back to search results </button>
          </Row>
          <Row>
            {/* walkin Left section */}
            <Col md={6} lg={7}>
              {/* walkin page heading section for both view */}
              <Row noGutters>
                <Col md={12}>
                  <h1 className="walkin-title">{data.primary.virtual_walkin_title.text}</h1>
                  <h5 className="job-location">{data.primary.job_location.text}</h5>
                </Col>
              </Row>
              <h2 className="walkin-right-key-heading visible-for-mobile">{data.primary.virtual_walkin_right_section_heading.text}</h2>
              {/* details section for mobile view*/}
              <Row className="row-spacing visible-for-mobile" noGutters>
                <Col md={12}>
                  {
                    data.items && data.items.length > 0 && data.items.map((item, i, arr) => {
                      return (
                        <div className={`${(arr.length - 1 === i) ? '': 'walkin-details-section'}`} key={item.virtual_walkin_right_section_title.text}>
                          <h3 className="walkin-section-key">{item.virtual_walkin_right_section_title.text}</h3>
                          <div 
                            className="walkin-section-content right-section-content"
                            style={{whiteSpace: 'pre-wrap', lineHeight: '28px'}}
                            dangerouslySetInnerHTML={{
                              __html: item.virtual_walkin_right_section_content ? item.virtual_walkin_right_section_content.raw.length > 1 ? item.virtual_walkin_right_section_content.html : item.virtual_walkin_right_section_content.raw[0].text : '',
                          }}/>
                        </div>
                      )
                    })
                  }
                </Col>
              </Row>
              {/* Reginster button Section*/}
              <Row className="row-spacing register-btn-mobile" noGutters>
                <Col md={12}>
                  <Button
                    className="walkin-register-btn"
                    color="primary"
                    onClick={handleRegisterBtnClick}
                  >
                    {data.primary.virtual_walkin_button_text.text}
                  </Button>
                </Col>
              </Row>
              {/* share social media section for mobile view*/}
              <Row className="row-spacing visible-for-mobile" noGutters>
                <Col md={12}>
                  <div className="align-self-center">
                    <div className="social-media-text">Share this job</div>
                    <a      
                      href=''
                      target="_blank"
                      onClick={handleSocialMediaClick}
                      hrefLang="en"
                    ><i className="fab fa-facebook-square social-media-icon"></i></a>
                    <a
                      href=''
                      target="_blank"
                      onClick={handleLinkedInSocialMediaClick}
                      hrefLang="en"
                    ><i className="fab fa-linkedin ml-2 social-media-icon"></i></a>
                    <a
                      href=''
                      target="_blank"
                      onClick={handleTwitterSocialMediaClick}
                      hrefLang="en"
                    ><i className="fab fa-square-x-twitter ml-2 social-media-icon"></i></a>
                  </div>
                </Col>
              </Row>
              {/* walkin page content1 section for both view */}
              <Row className="row-spacing" noGutters>
                <Col md={12}>
                  <CollapseContentSection data={data.primary.virtual_walkin_content1} fieldLabel={data.primary.virtual_walkin_title1.text} isOpen={true}/>
                </Col>
              </Row>
              {/* walkin page content2 section for both view */}
              <Row className="row-spacing" noGutters>
                <Col md={12}>
                  <CollapseContentSection data={data.primary.virtual_walkin_content2} fieldLabel={data.primary.virtual_walkin_title2.text} isOpen={false}/>
                </Col>
              </Row>
            </Col>
            {/* walkin Right section */}
            <Col md={6} lg={5} className="key-details-offset">
              <h2 className="walkin-right-key-heading visible-for-desktop">{data.primary.virtual_walkin_right_section_heading.text}</h2>
              {/* details section */}
              <Row className="row-spacing visible-for-desktop" noGutters>
                <Col md={12}>
                  {
                    data.items && data.items.length > 0 && data.items.map((item, i, arr) => {
                      return (
                        <div className={`${(arr.length - 1 === i) ? '': 'walkin-details-section'}`} key={item.virtual_walkin_right_section_title.text}>
                          <h3 className="walkin-section-key">{item.virtual_walkin_right_section_title.text}</h3>
                          <div 
                            className="walkin-section-content right-section-content"
                            style={{whiteSpace: 'pre-wrap', lineHeight: '28px'}}
                            dangerouslySetInnerHTML={{
                              __html: item.virtual_walkin_right_section_content ? item.virtual_walkin_right_section_content.raw.length > 1 ? item.virtual_walkin_right_section_content.html : item.virtual_walkin_right_section_content.raw[0].text : '',
                          }}/>
                        </div>
                      )
                    })
                  }
                </Col>
              </Row>
              {/* share social media section */}
              <Row className="row-spacing visible-for-desktop" noGutters>
                <Col md={12}>
                  <div className="align-self-center">
                    <div className="social-media-text">Share this job</div>
                    <a      
                      href=''
                      target="_blank"
                      onClick={handleSocialMediaClick}
                      hrefLang="en"
                    ><i className="fab fa-facebook-square social-media-icon"></i></a>
                    <a
                      href=''
                      target="_blank"
                      onClick={handleLinkedInSocialMediaClick}
                      hrefLang="en"
                    ><i className="fab fa-linkedin ml-2 social-media-icon"></i></a>
                    <a
                      href=''
                      target="_blank"
                      onClick={handleTwitterSocialMediaClick}
                      hrefLang="en"
                    ><i className="fab fa-square-x-twitter ml-2 social-media-icon"></i></a>
                  </div>
                </Col>
              </Row>
              {/* Related Jobs Section */}
              <Row className="row-spacing" noGutters>
                <Col md={12}>
                  <div className="related-job-section">
                    <div className="related-jobs-text">Related Jobs</div>
                      {
                        relatedJobs && relatedJobs.items && relatedJobs.items.map(item => {
                          let jobDescription = item.node.data.body[item.node.data.body.findIndex(obj => obj.__typename === "PrismicJobDescriptionPageBodyJobDescription")];
                          let jobKeyDetails = item.node.data.body[item.node.data.body.findIndex(obj => obj.__typename === "PrismicJobDescriptionPageBodyKeyDetails")];
                          return (
                            <div className="related-job-wrapper" key={jobDescription.id}>
                              <a 
                                href={createUrl(`${item.node.data.page_path.text}/${item.node.data.page_display_name.text.split(" ").join("-")}`, props.pagesLinksData)}
                                className="related-job-title"
                                hrefLang="en"
                              >
                                {jobDescription.primary.job_title.text}
                              </a>
                              {
                                generateFormatedDate(jobKeyDetails.primary.job_published_date) && (
                                  <div className="related-job-date">Published On: {generateFormatedDate(jobKeyDetails.primary.job_published_date)}</div>
                                )
                              }
                            </div>
                          )})}
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </> 
    )
}

export default VirtualWalkinDetails;