import React from "react";
import { Helmet } from "react-helmet";
import {
  Container,
  Row,
  Col,
  Button
} from "reactstrap";
import { getColor, createUrl, filterLocationBasedJobs, fetchUserLocation } from "../utils/universalHelperFunctions";
import JobDescription from "./JobDescription";
import Breadcrumb from "./Breadcrumb";
import "../styles/job-details-page.css";

class JobDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      relatedJobs: null,
    }
    
    this.handleSocialMediaClick = this.handleSocialMediaClick.bind(this);
    this.handleLinkedInSocialMediaClick = this.handleLinkedInSocialMediaClick.bind(this);
    this.handleTwitterSocialMediaClick = this.handleTwitterSocialMediaClick.bind(this);
  }

  componentDidMount() {
    fetchUserLocation().then(() => {
      if(this.props.primarySkills) {
        this.getRelatedJobList(this.props, 1, 5);
      }
      let filter = localStorage.getItem('jobFilterSelection');
      if (filter && filter.length > 0) {
        localStorage.setItem("jobFilterFlag", "true");
      }
    })
  }

  generateFormatedDate(dateVal) {
    if(dateVal) {
      try {
        let startDate = new Date(dateVal);
        let month = new Intl.DateTimeFormat('en-US', { month: 'long' }).format(startDate);
        let year = startDate.getFullYear();
        let date = startDate.getDate();
        let formattedDate = `${date} ${month} ${year}`;

        return formattedDate;

      } catch (error) {
        console.log(error)
      }
    }
  }

  handleApplyNowBtnClick() {
    document.getElementById('jobs').scrollIntoView({behavior: "smooth", block: "end", inline: "nearest"});
  }

  handleSocialMediaClick (e) {
    e.preventDefault();
    let url = 'https://www.facebook.com/sharer.php?display=popup&u=' + window.location.href;
    
    window.open(url);
  }

  handleLinkedInSocialMediaClick (e) {
    e.preventDefault();
    let content = this.props.input;
    let source = typeof window !== 'undefined' ? window.location.hostname : 'Gspann';
    let url = `https://www.linkedin.com/shareArticle?mini=true&url=${encodeURIComponent(window.location.href)}&title=${encodeURIComponent(content.job_title)}&summary=${encodeURIComponent(content.primary.description.raw[0].text)}&source=${encodeURIComponent(source)}`;
    
    window.open(url);
  }

  handleTwitterSocialMediaClick (e) {
    e.preventDefault();
    let url = `https://twitter.com/share?url=${encodeURIComponent(window.location.href)}&text=${encodeURIComponent(this.props.input.primary.description.raw[0].text)}`;
    
    window.open(url);
  }
  
  getRelatedJobList(openedJob, minResult, maxResult) {
    let finalResourceDataObject = {};
    let data = this.props.allJobs.filter(item => item.node.data.include_job_in_list !== false);
    data = data.filter(item => item.node.uid !== openedJob.pageId);
    data = filterLocationBasedJobs(data);
    data.sort((job1, job2) => {
      const job1ItemBody = job1.node.data.body;
      const job2ItemBody = job2.node.data.body;
      const indexForJobTag = obj =>
        obj.__typename === "PrismicJobDescriptionPageBodyJobTags";
      const indexForJobDescription = obj =>
        obj.__typename === "PrismicJobDescriptionPageBodyJobDescription";
      const indexForJobKeyDetails = obj =>
        obj.__typename === "PrismicJobDescriptionPageBodyKeyDetails";
      const indexForPrimarySkills = obj =>
        obj.__typename === "PrismicJobDescriptionPageBodyPrimarySkills";
      //const jobTagIndex = itemBody.findIndex(indexForJobTag);
      const job1DescriptionIndex = job1ItemBody.findIndex(indexForJobDescription);
      const job1KeyDetsilsIndex = job1ItemBody.findIndex(indexForJobKeyDetails);
      const job2DescriptionIndex = job2ItemBody.findIndex(indexForJobDescription);
      const job2KeyDetsilsIndex = job2ItemBody.findIndex(indexForJobKeyDetails);
      const job1PrimarySkillsIndex = job1ItemBody.findIndex(indexForPrimarySkills);
      const job2PrimarySkillsIndex = job2ItemBody.findIndex(indexForPrimarySkills);

      var regExToRemoveBrackets = new RegExp(/\(([^)]+)\)/g);
      let openedJobSkills =  openedJob.primarySkills && openedJob.primarySkills.items && openedJob.primarySkills.items.length > 0 && openedJob.primarySkills.items.map( item => item.technology);
      openedJobSkills = openedJobSkills.join(',').replace(/ like | or | and |like |any | & /gi, ',');
      openedJobSkills = openedJobSkills.replace(regExToRemoveBrackets, ",$1");
      openedJobSkills = openedJobSkills.replace(/\//g, ',');
      var openedJobSkillsArr = openedJobSkills.split(',');
      openedJobSkillsArr = openedJobSkillsArr.filter(function (el) {
        return el !== null || el !== "" || el !== 'undefined' || el !== " ";
      });
      openedJobSkillsArr = openedJobSkillsArr.map(item => item.trim());

      let openedJobPosition = openedJob.input.primary.job_title && openedJob.input.primary.job_title.text.replace(/ or | and | & /gi, '');
      let regExRemoveSpecialChar = new RegExp(/[\(\)-\/]/g);
      openedJobPosition = openedJobPosition.replace(regExToRemoveBrackets, "");
      var openedJobPositionArr = openedJobPosition.split(' ');
      openedJobPositionArr = openedJobPositionArr.filter(function (el) {
        return el !== null || el !== "" || el !== 'undefined' || el !== " ";
      });

      let job1SkillsArr = [];
      if(job1PrimarySkillsIndex > -1 ) {
        let PrimarySkills1 =  job1PrimarySkillsIndex > -1 && job1ItemBody[job1PrimarySkillsIndex] && job1ItemBody[job1PrimarySkillsIndex].items && job1ItemBody[job1PrimarySkillsIndex].items.length > 0 && job1ItemBody[job1PrimarySkillsIndex].items.map(item => item.technology);
        PrimarySkills1 = PrimarySkills1.join(',').replace(/ like | or | and |like |any | & /gi, ',');
        PrimarySkills1 = PrimarySkills1.replace(regExToRemoveBrackets, ",$1");
        PrimarySkills1 = PrimarySkills1.replace(/\//g, ',');
        job1SkillsArr = PrimarySkills1.split(',');
        job1SkillsArr = job1SkillsArr.filter(function (el) {
          return el !== null || el !== "" || el !== 'undefined' || el !== " ";
        });
        job1SkillsArr = job1SkillsArr.map(item => item.trim());
      }
      
      var noOfMatchForJob1 = job1SkillsArr && job1SkillsArr.length > 0 ? job1SkillsArr.reduce(function(count, skills){
        let found = openedJobSkillsArr && openedJobSkillsArr.some(el => el.trim().toLowerCase() === skills.trim().toLowerCase());
        if(found)
        count++;
        return count;
      },0) : 0;

      let PrimaryPosition1 = job1ItemBody[job1DescriptionIndex].primary.job_title && job1ItemBody[job1DescriptionIndex].primary.job_title.text.replace(/ or | and | & /gi, '');
      PrimaryPosition1 = PrimaryPosition1.replace(regExToRemoveBrackets, "");
      var job1PositionArr = PrimaryPosition1.split(' ');
      job1PositionArr = job1PositionArr.filter(function (el) {
        return el !== null || el !== "" || el !== 'undefined' || el !== " ";
      });
      noOfMatchForJob1 = job1PositionArr && job1PositionArr.length > 0 ? job1PositionArr.reduce(function(count, positions){
        let found = openedJobPositionArr && openedJobPositionArr.some(el => el.trim().toLowerCase() === positions.trim().toLowerCase());
        if(found)
        count++;
        return count;
      },noOfMatchForJob1) : noOfMatchForJob1;

      job1.count = noOfMatchForJob1;

      let job2SkillsArr = [];
      if(job2PrimarySkillsIndex > -1 ) {
        let PrimarySkills2 = job2ItemBody[job2PrimarySkillsIndex] && job2ItemBody[job2PrimarySkillsIndex].items && job2ItemBody[job2PrimarySkillsIndex].items.length > 0 && job2ItemBody[job2PrimarySkillsIndex].items.map(item => item.technology);
        PrimarySkills2 = PrimarySkills2.join(',').replace(/ like | or | and |like |any | & /gi, ',');
        PrimarySkills2 = PrimarySkills2.replace(regExToRemoveBrackets, ",$1");
        PrimarySkills2 = PrimarySkills2.replace(/\//g, ',');
        let job2SkillsArr = PrimarySkills2.split(',');
        job2SkillsArr = job2SkillsArr.filter(function (el) {
          return el !== null || el !== "" || el !== 'undefined' || el !== " ";
        });
        job2SkillsArr = job2SkillsArr.map(item => item.trim());
      }
      var noOfMatchForJob2 = job2SkillsArr && job2SkillsArr.length > 0 ? job2SkillsArr.reduce(function(count, skills){
        let found = openedJobSkillsArr && openedJobSkillsArr.some(el => el.trim().toLowerCase() === skills.trim().toLowerCase());
        if(found)
        count++;
        return count;
      },0) : 0;

      let PrimaryPosition2 = job2ItemBody[job2DescriptionIndex].primary.job_title && job2ItemBody[job2DescriptionIndex].primary.job_title.text.replace(/ or | and | & /gi, '');
      PrimaryPosition2 = PrimaryPosition2.replace(regExToRemoveBrackets, "");
      var job2PositionArr = PrimaryPosition2.split(' ');
      job2PositionArr = job2PositionArr.filter(function (el) {
        return el !== null || el !== "" || el !== 'undefined' || el !== " ";
      });
      noOfMatchForJob2 = job2PositionArr && job2PositionArr.length > 0 ? job2PositionArr.reduce(function(count, positions){
        let found = openedJobPositionArr && openedJobPositionArr.some(el => el.trim().toLowerCase() === positions.trim().toLowerCase());
        if(found)
        count++;
        return count;
      }, noOfMatchForJob2) : noOfMatchForJob2;

      job2.count = noOfMatchForJob2;

      if(noOfMatchForJob1 > noOfMatchForJob2) { return -1  }
      if(noOfMatchForJob1 < noOfMatchForJob2) { return 1 }
      return 0;
    });

    var revelentResult = [];
    data.forEach(item => {
      if(revelentResult[item.count] && revelentResult[item.count].length > 0) {
        revelentResult[item.count].push(item);
      } else {
        revelentResult[item.count] = [];
        revelentResult[item.count].push(item);
      }
    });
    let notMatchedData = revelentResult[0] && revelentResult[0].length ? revelentResult.splice(0, 1)[0] : [];
    revelentResult = revelentResult.filter(Array);
    revelentResult.reverse();
    revelentResult.forEach(list => {
      list.sort((a, b) => {
          return new Date(b.StartDate).getTime() - new Date(a.StartDate).getTime();
        });
    })
    if(revelentResult && revelentResult.length > 0) {
      finalResourceDataObject.items = [];
      for (var i=0, len=revelentResult.length; i<len; i++) {
        for (var j=0, len2=revelentResult[i].length; j<len2; j++) {
          if(finalResourceDataObject.items.length < maxResult) {
            finalResourceDataObject.items.push( revelentResult[i][j] ); 
          } else {
            break;
          }
        }
      }
    }

    if(finalResourceDataObject && (!finalResourceDataObject.items || finalResourceDataObject.items < minResult)) {
      if(!finalResourceDataObject.items) {
        finalResourceDataObject.items = [];
      }
      notMatchedData.sort((a, b) => {
        return new Date(b.StartDate).getTime() - new Date(a.StartDate).getTime();
      });
      for (var i=0, len=notMatchedData.length; i<len; i++) {
        if(finalResourceDataObject.items.length < minResult) {
          finalResourceDataObject.items.push(notMatchedData[i] ); 
        } else {
          break;
        }
      }
    }

    this.setState({relatedJobs: finalResourceDataObject});
  }

  handleBackButton() {
    if (typeof window !== 'undefined') {
      window.history.back();
    }
  }
  
  render() {
    let content = this.props.input;
    let keyDetails = this.props.keyDetails;
    let buttonFontColor =  content.primary.apply_button_font_color ? getColor(content.primary.apply_button_font_color) : "#f1f5f6ee";
    let buttonBackground = content.primary.apply_button_background_color ? getColor(content.primary.apply_button_background_color) : "#f1f5f6ee";
    let sectionBackgroundColor = content.primary.description_section_background_color ? getColor(content.primary.description_section_background_color) : "#f1f5f6ee";
    let jobSkillsFontColor = content.primary.job_skills_font_color ? getColor(content.primary.job_skills_font_color) : "#f1f5f6ee";
    let sectionContentFontColor = content.primary.section_content_font_color ? getColor(content.primary.section_content_font_color) : "#f1f5f6ee";
    let sectionHeadingFontColor = content.primary.section_heading_font_color ? getColor(content.primary.section_heading_font_color) : "#f1f5f6ee";
    
    if(keyDetails) {
      var keyDetailsSectionBackground = keyDetails.primary.key_details_section_background ? getColor(keyDetails.primary.key_details_section_background) : "#f1f5f6ee";
      var KeySectionHeadingFontColor = keyDetails.primary.key_section_heading_font_color ? getColor(keyDetails.primary.key_section_heading_font_color) : "#f1f5f6ee";
      var keyDetailsContentFontColor = keyDetails.primary.section_content_font_color ? getColor(keyDetails.primary.section_content_font_color) : "#f1f5f6ee";
    }
    let tags = this.props.jobTags;
    let primarySkills = this.props.primarySkills && this.props.primarySkills.items && this.props.primarySkills.items.length > 0 && this.props.primarySkills.items.filter(function (el) {
      return el.technology !== null || el.technology !== "" || el.technology !== 'undefined' || el.technology !== " ";
    });
    primarySkills = primarySkills && primarySkills.length > 0 && primarySkills.reduce((data, item, index, arr) => { 
      if(item.technology) {
        if(index === arr.length - 1) {
          return `${data}${item.technology}`;
        } else {
          return `${data}${item.technology}, `;
        }
      } else {
        return `${data}`;
      }
    }, '');
    if(tags) {
      tags.items && this.props.jobTags.items.sort((tagDataA, tagDataB)=> {
        if(tagDataA.tag_keywords_list?.toLowerCase() < tagDataB.tag_keywords_list?.toLowerCase()) { return -1; }
        if(tagDataA.tag_keywords_list?.toLowerCase() > tagDataB.tag_keywords_list?.toLowerCase()) { return 1; }
        return 0;
      });
    }

    return (
      <>
        <Helmet>
            <title>{`${this.props.pageOverViewData.primary.page_title.text || 'Find a Position in Our Latest Jobs Opening'} | GSPANN`}</title>
            <meta property="og:title" content={content.primary.job_title.text || 'Find a Position in Our Latest Jobs Opening'} />
            <meta property="og:description" content={content.primary.description.raw[0].text || 'Job seekers, here’s a golden opportunity for you to work in an organization that promotes openness, collaboration, and inclusion.Got what it takes? Check out this page for opportunities and apply for positions online.'} />
            <meta property="og:image" content="https://images.prismic.io/gspann/1f4f1bc1-78d1-4b5b-ae1e-118c8d74c6df_job.jpeg?auto=compress,format"/>
            <meta name="twitter:card" content="summary_large_image"/>
            <meta name="twitter:image" content="https://images.prismic.io/gspann/987c7034-aabb-44c4-b1ca-65fef983fc74_Hdpi+-+1440+x+500.jpg?auto=compress,format"/>
            <meta http-equiv="Pragma" content="no-cache" />
            <meta http-equiv="Expires" content="0" />
            <meta name="google-site-verification" content="Fy8VROwdCIk4KmBbEnJGko3-ZXm9agz417MGKGPUBP8" />
            <meta name="viewport" content="width=device-width, initial-scale=1" />
            <link rel="canonical" href={typeof window !== 'undefined' && window.location.href} hrefLang="en"/>
            <link rel="alternate" href={typeof window !== 'undefined' && window.location.href} hrefLang="en" key="en" />
        </Helmet>
        <Breadcrumb
          pagesLinksData={this.props.pagesLinksData}
          pageType='open-position'
        />
        <Container className="back-to-search-container">
          <i className="fa fa-angle-left back-to-search-arrow" style={{color: buttonBackground}} onClick={this.handleBackButton}></i>
          <button className="back-to-search" style={{color: buttonBackground}} onClick={this.handleBackButton}> Back to search results </button>
        </Container>
        <Container className="jd-title-section jd-section">
          <Row>
            <Col md={6} lg={7} style={{backgroundColor: sectionBackgroundColor}}>
              <Row noGutters>
                <Col md={12}>
                  <h1 className="jd-title" style={{color: sectionHeadingFontColor}}>{content.primary.job_title.text}</h1>
                  <h5 className="jd-primary-skill"
                    style={{color: jobSkillsFontColor}}
                  >
                    {primarySkills}
                  </h5>
                  <h5 className="field-label visible-for-desktop" style={{color: sectionHeadingFontColor}}>{content.primary.description_field_title.text}</h5>
                  <p className="field-value visible-for-desktop"
                    style= {{color: sectionContentFontColor}}
                    dangerouslySetInnerHTML={{
                      __html:
                      content.primary.description.raw.length > 1 ? content.primary.description.html : content.primary.description.raw[0].text,
                    }}/>

                  <h5 className="field-label" style={{color: sectionHeadingFontColor, marginTop: '24px'}}>Who We Are</h5>
                  <p className="field-value" style= {{color: sectionContentFontColor}}>
                    GSPANN has been in business for over a decade, with over 2000 employees worldwide, and servicing some of the largest retail, high technology, and manufacturing clients in North America. We provide an environment that enables career growth while still interacting with company leadership.                    
                  </p>
                  <p className="field-value">Visit <a href="/jobs/why-gspann/">Why GSPANN</a> for more information.</p>
                </Col>
              </Row>
              <Row noGutters className="row-spacing visible-for-mobile" style={{backgroundColor: keyDetailsSectionBackground}}>
                <Col md={12}>
                  <div className="key-details-section">
                    <span className="key-details-label" style={{color: KeySectionHeadingFontColor}}>{keyDetails ? keyDetails.primary.location_field_name.text : ''}</span>
                    <span 
                      className="key-details-value"
                      style= {{color: keyDetailsContentFontColor}}
                      dangerouslySetInnerHTML={{
                        __html:
                        keyDetails ? keyDetails.primary.job_location.raw.length > 1 ? keyDetails.primary.job_location.html : keyDetails.primary.job_location.raw[0].text: '',
                      }}
                    />
                  </div>
                  <div className="key-details-section">
                    <span className="key-details-label" style={{color: KeySectionHeadingFontColor}}>{keyDetails ? keyDetails.primary.role_type_field_name.text : ''}</span>
                    <span 
                      className="key-details-value"
                      style={{color: keyDetailsContentFontColor}}
                      dangerouslySetInnerHTML={{
                        __html:
                        keyDetails ? keyDetails.primary.job_role_type.raw.length > 1 ? keyDetails.primary.job_role_type.html : keyDetails.primary.job_role_type.raw[0].text: '',
                      }}
                    />
                  </div>
                  {
                    keyDetails && this.generateFormatedDate(keyDetails.primary.job_published_date) && (
                      <div className="key-details-section">
                        <span className="key-details-label" style={{color: KeySectionHeadingFontColor}}>{keyDetails.primary.job_published_date_field_name.text}</span>
                        <span className="key-details-value" style={{color: keyDetailsContentFontColor}}>{this.generateFormatedDate(keyDetails.primary.job_published_date)}</span>
                      </div>
                    )
                  }
                  <div>
                    <span className="key-details-label" style={{color: KeySectionHeadingFontColor}}>{keyDetails ? keyDetails.primary.job_experience_field_name.text : ''}</span>
                    <span 
                      className="key-details-value"
                      style={{color: keyDetailsContentFontColor}}
                      dangerouslySetInnerHTML={{
                        __html:
                        keyDetails ? keyDetails.primary.experience_required.raw.length > 1 ? keyDetails.primary.experience_required.html : keyDetails.primary.experience_required.raw[0].text: '',
                      }}
                    />
                  </div>
                </Col>
              </Row>
              <Row noGutters className="row-spacing apply-btn-mobile">
                <Col md={12}>
                  <Button
                    className="job-apply-btn"
                    color="primary"
                    style={{color: buttonFontColor, backgroundColor: buttonBackground}}
                    onClick={this.handleApplyNowBtnClick}
                  >
                    Apply Now
                  </Button>
                  </Col>
              </Row>
              <Row noGutters className="row-spacing visible-for-mobile">
                <Col md={12}>
                  <div className="align-self-center">
                    <div className="social-media-text">Share this job</div>
                    <a      
                      href=''
                      target="_blank"
                      onClick={this.handleSocialMediaClick}
                      hrefLang="en"
                    ><i className="fab fa-facebook-square social-media-icon" /></a>
                    <a
                      href=''
                      target="_blank"
                      onClick={this.handleLinkedInSocialMediaClick}
                      hrefLang="en"
                    ><i className="fab fa-linkedin ml-2 social-media-icon" /></a>
                    <a
                      href=''
                      target="_blank"
                      onClick={this.handleTwitterSocialMediaClick}
                      hrefLang="en"
                    ><i className="fab fa-square-x-twitter ml-2 social-media-icon" /></a>
                  </div>
                </Col>
              </Row>
              <Row noGutters className="row-spacing visible-for-mobile">
                <Col md={12}>
                  <JobDescription data={content.primary.description} fieldLabel={content.primary.description_field_title.text} isOpen={true}/>
                </Col>
              </Row>
              {
                content.items && content.items.length > 0 && (
                  content.items.map((item, index) => (
                      <Row className="row-spacing" key={index}>
                        <Col md={12}>
                          <JobDescription data={item.job_details} fieldLabel={item.job_details_heading.text} isOpen={false}/>
                        </Col>
                      </Row>
                    ))
                )
              }
            </Col>
            <Col md={6} lg={5} className="key-details-offset" style={{backgroundColor: keyDetailsSectionBackground}}>
              <h2 className="job-key-heading visible-for-desktop" style={{color: KeySectionHeadingFontColor}}>{keyDetails ? keyDetails.primary.section_heading.text : ''}</h2>
              <Row className="row-spacing visible-for-desktop" noGutters>
                <Col md={12}>
                  <div className="key-details-section">
                    <span className="key-details-label" style={{color: KeySectionHeadingFontColor}}>{keyDetails ? keyDetails.primary.location_field_name.text : ''}</span>
                    <span 
                      className="key-details-value"
                      style= {{color: keyDetailsContentFontColor}}
                      dangerouslySetInnerHTML={{
                        __html:
                        keyDetails ? keyDetails.primary.job_location.raw.length > 1 ? keyDetails.primary.job_location.html : keyDetails.primary.job_location.raw[0].text : '',
                      }}
                    />
                  </div>
                  <div className="key-details-section">
                    <span className="key-details-label" style={{color: KeySectionHeadingFontColor}}>{keyDetails ? keyDetails.primary.role_type_field_name.text : ''}</span>
                    <span 
                      className="key-details-value"
                      style= {{color: keyDetailsContentFontColor}}
                      dangerouslySetInnerHTML={{
                        __html:
                        keyDetails ? keyDetails.primary.job_role_type.raw.length > 1 ? keyDetails.primary.job_role_type.html : keyDetails.primary.job_role_type.raw[0].text: '',
                      }}
                    />
                  </div>
                  {
                    keyDetails && this.generateFormatedDate(keyDetails.primary.job_published_date) && (
                      <div className="key-details-section">
                        <span className="key-details-label" style={{color: KeySectionHeadingFontColor}}>{keyDetails.primary.job_published_date_field_name.text}</span>
                        <span className="key-details-value" style= {{color: keyDetailsContentFontColor}}>{this.generateFormatedDate(keyDetails.primary.job_published_date)}</span>
                      </div>
                    )
                  }
                  <div>
                    <span className="key-details-label" style={{color: KeySectionHeadingFontColor}}>{keyDetails ? keyDetails.primary.job_experience_field_name.text : ''}</span>
                    <span 
                      className="key-details-value"
                      style= {{color: keyDetailsContentFontColor}}
                      dangerouslySetInnerHTML={{
                        __html:
                        keyDetails ? keyDetails.primary.experience_required.raw.length > 1 ? keyDetails.primary.experience_required.html : keyDetails.primary.experience_required.raw[0].text: '',
                      }}
                    />
                  </div>
                </Col>
              </Row>
              <Row className="row-spacing visible-for-desktop" noGutters>
                <Col md={12}>
                  <div className="align-self-center">
                    <div className="social-media-text">Share this job</div>
                    <a      
                      href=''
                      target="_blank"
                      onClick={this.handleSocialMediaClick}
                      hrefLang="en"
                    ><i className="fab fa-facebook-square social-media-icon" /></a>
                    <a
                      href=''
                      target="_blank"
                      onClick={this.handleLinkedInSocialMediaClick}
                      hrefLang="en"
                    ><i className="fab fa-linkedin ml-2 social-media-icon" /></a>
                    <a
                      href=''
                      target="_blank"
                      onClick={this.handleTwitterSocialMediaClick}
                      hrefLang="en"
                    ><i className="fab fa-square-x-twitter ml-2 social-media-icon" /></a>
                  </div>
                </Col>
              </Row>
              <Row className="row-spacing" noGutters>
                <Col md={12}>
                  <div className="related-job-section">
                    <div className="related-jobs-text">Related Jobs</div>
                      {
                        this.state.relatedJobs && this.state.relatedJobs.items && this.state.relatedJobs.items.map(item => {
                          let jobDescription = item.node.data.body[item.node.data.body.findIndex(obj => obj.__typename === "PrismicJobDescriptionPageBodyJobDescription")];
                          let jobKeyDetails = item.node.data.body[item.node.data.body.findIndex(obj => obj.__typename === "PrismicJobDescriptionPageBodyKeyDetails")];
                          return (
                            <div className="related-job-wrapper" key={jobDescription.id}>
                              <a 
                                href={createUrl(`${item.node.data.page_path.text}/${item.node.data.page_display_name.text.split(" ").join("-")}`, this.props.pagesLinksData)}
                                className="related-job-title"
                                hrefLang="en"
                              >
                                {jobDescription.primary.job_title.text}
                              </a>
                              {
                                jobKeyDetails && this.generateFormatedDate(jobKeyDetails.primary.job_published_date) && (
                                  <div className="related-job-date">Published On: {this.generateFormatedDate(jobKeyDetails.primary.job_published_date)}</div>
                                )
                              }
                            </div>
                          )
                        })
                      }
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </>
    );
  }

}

export default JobDetails;

JobDetails.propTypes = {
};