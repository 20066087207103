import React, { Component } from 'react';
import '../styles/card-design.css';
import { createUrl, getColor } from "../utils/universalHelperFunctions";

class ResourceCard extends Component {
  constructor(props) {
    super(props);
    this.cardData = this.props.cardData;
    // this.finalResourceDataObject = this.props.finalResourceDataObject;
    this.linksData = props.pagesLinksData;
    this.topValue = 10+"px";
    this.handleResourceTagClick = this.handleResourceTagClick.bind(this);
    this.getHashUrl = this.getHashUrl.bind(this);
  }

  getHashUrl = () => {
    let val =  localStorage.getItem('resourceTag') ? `#${localStorage.getItem("resourceFilter")}#${localStorage.getItem('resourceTag')}` : `#${localStorage.getItem("resourceFilter")}`;
    return val;
  }

  handleResourceTagClick(e) {
    e.preventDefault();
    e.stopPropagation();
    localStorage.setItem('resourceTagFlag', 'true');
    // localStorage.setItem('resourceTag', JSON.stringify(e.target.id));
    localStorage.setItem('resourceTag', e.target.id);
    let flag = localStorage.getItem('resourceFlag');
    if (flag === null || flag === 'false') {
      localStorage.setItem('resourceFlag', 'false');
    } else {
      localStorage.setItem('resourceFlag', 'true');
    }
    if(window.location.pathname.match(/resources\/all-resources/g) && window.location.pathname.match(/resources\/all-resources/g).length > 0) {
      this.props.selectedFilter([{type: 'resourceTags', value: e.target.id}]);
    } else {
      // window.location.href = createUrl(this.cardData.resourceTags.primary.redirection_link ? this.cardData.resourceTags.primary.redirection_link.url: 'resources/all-resources', this.linksData);
      let firstPart = "resources/all-resources/";
      window.location.hash = this.getHashUrl();
      window.location.pathname = `${firstPart}`;  
    }
    window.location.hash = this.getHashUrl();
    // console.log("window.location.hash",window.location.hash);
  }

  render() {
    // to hold card background URL
    let cardResourceBackgroundURL;
    let cardResourceBackgroundTagURL;
    let cardResourceBackgroundTagAlt;
    let cardResourceBackgroundTagURL2;
    let cardResourceBackgroundTagAlt2;

    
    // checking card image property
    if (this.cardData.thumbNail) {
      cardResourceBackgroundURL = this.cardData.thumbNail.url;
    } else {
      cardResourceBackgroundURL = null;
    }

    if(this.cardData.thumbNailTag.url?.length>0){      
      cardResourceBackgroundTagURL = this.cardData.thumbNailTag.url;
      if (this.cardData.thumbNailTag.alt === "popular") {
        cardResourceBackgroundTagAlt = "popular";
      } else {
        cardResourceBackgroundTagAlt = "latest";
      }      
    } else {
      cardResourceBackgroundTagURL = null;
    }
    
    if(this.cardData.thumbNailTag2.url?.length>0){ 
      // let resourceTag2 = document.getElementById("resource-tag-src-2");
      // let newTopValue = 36;
      // if(resourceTag2){
      // resourceTag2.style.top = newTopValue + "px";     
      // }
      cardResourceBackgroundTagURL2 = this.cardData.thumbNailTag2?.url;
      if (this.cardData.thumbNailTag2.alt === "popular") {
        cardResourceBackgroundTagAlt2 = "popular";
      } else {
        cardResourceBackgroundTagAlt2 = "latest";
      }      
    } else {
      cardResourceBackgroundTagURL2 = null;
    }

    if(cardResourceBackgroundTagURL2 && cardResourceBackgroundTagURL){
      this.topValue = 36 + "px";
    }

    let cardResourceButtonText;
    let cardResourceButtonTextElement = {};

    // checking card button text property
    if (this.cardData.resourceType) {
      cardResourceButtonText = this.cardData.resourceType;
    } else {
      cardResourceButtonText = null;
    }

    let cardResourceDescriptionText;
    let cardResourceDescriptionElement = {};

    // checking card description property
    if (this.cardData.displayName) {
      cardResourceDescriptionText = this.cardData.displayName;
    } else {
      cardResourceDescriptionText = null;
    }

    let cardResourceLink;

    // checking href property
    if (this.cardData.href) {
      cardResourceLink = this.cardData.href || this.cardData.url;
    } else {
      cardResourceLink = null;
    }

    // checking visibility for card heading
    if (cardResourceDescriptionText) {
      cardResourceDescriptionElement = <p className="card-resource-description">{cardResourceDescriptionText}</p>;
    } else {
      cardResourceDescriptionElement = null;
    }

    // checking visibility for card heading
    if (cardResourceButtonText) {
      cardResourceButtonTextElement = <h3 className="card-resource-button" style={{ display: 'inline-block'}}>{cardResourceButtonText}</h3>;
    } else {
      cardResourceButtonTextElement = null;
    }

    let cardResourceIconElement;
    if (cardResourceButtonText) {
      let logo = `icon-${cardResourceButtonText.toLowerCase().split(' ').join('-').trim()}`;
      cardResourceIconElement = <img src={require(`./../../static/${logo}.svg`).default} alt={`${cardResourceButtonText} logo`} className="resource-icon" />
    } else {
      cardResourceIconElement = null;
    }
    let resourceTagElement;
    if (this.cardData.resourceTags && this.cardData.resourceTags.items && this.cardData.resourceTags.items.length > 0) {
      let tagBackgroundColor = this.cardData.resourceTags.primary.tag_background_color ? getColor(this.cardData.resourceTags.primary.tag_background_color) : '#E3E9EC';
      let tagColor = this.cardData.resourceTags.primary.tag_font_color ? getColor(this.cardData.resourceTags.primary.tag_font_color) : '#000000';
      this.cardData.resourceTags.items.sort((tagDataA, tagDataB)=> {
        if(tagDataA.tag_keywords_list?.toLowerCase() < tagDataB.tag_keywords_list.toLowerCase()) { return -1; }
        if(tagDataA.tag_keywords_list?.toLowerCase() > tagDataB.tag_keywords_list.toLowerCase()) { return 1; }
        return 0;
      });
      resourceTagElement = this.cardData.resourceTags.items.map(item => {
        if(item.tag_keywords_list?.toLowerCase() !== 'all topics') {
          return (
            <div 
              key={item.tag_keywords_list}
              style={{display: 'inline-block'}}
            >
              <button
                className='resource-card-tag'
                style={{color: tagColor, backgroundColor: tagBackgroundColor}}
                id={item.tag_keywords_list}
                onClick={this.handleResourceTagClick}
              >
                {item.tag_keywords_list}
              </button>
            </div>
          )
        }
      })
      resourceTagElement = <div className="tag-container">{resourceTagElement}</div>
    } else {
      resourceTagElement = null;
    }
    if([
      'resources/white-papers/aiops-in-monitoring1',
      'resources/white-papers/aiops-in-monitoring2',
      'resources/white-papers/aiops-in-monitoring3',
    ].includes(cardResourceLink)) {
      return null;
    }
    return (
      <div className={`card-wrapper no-padding ${this.props.carousel ? '' : 'col-12 col-sm-6 col-md-6 col-lg-4'}`}>
        <div className="resource-card-container">
          <a href={createUrl(cardResourceLink, this.linksData)} hrefLang="en">
            <div className='card-background-img'>
                <img src={cardResourceBackgroundURL} loading="lazy" alt="" className="resource-card-img"></img>
                <img src={cardResourceBackgroundTagURL} loading="lazy" alt={cardResourceBackgroundTagAlt} className="resource-card-tag-img" id="resource-tag-src"></img>
                <img src={cardResourceBackgroundTagURL2} loading="lazy" alt={cardResourceBackgroundTagAlt2} className="resource-card-tag-img-2" id="resource-tag-src-2" style={{top:this.topValue}}  ></img>
            </div>
            <div className="resource-content-wrapper" style={this.cardData.resourceTags && this.cardData.resourceTags.items ? {} : {height: '175px'}}>
              <div className="resource-title-container">
                {cardResourceIconElement}
                {cardResourceButtonTextElement}
              </div>
              {cardResourceDescriptionElement}
              {resourceTagElement}
            </div>
          </a>
        </div>
      </div>
    );
  }
}
export default ResourceCard;