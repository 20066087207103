import React from 'react';
import "../styles/newsPressReleases.css";
import { getFormattedDate } from '../utils/universalHelperFunctions';

export default function NewsPressReleaseList({ list = [] }) {
    const [isDescendingSort, setIsDescendingSort] = React.useState(true);
    const sortedList = (list || []).sort((a, b) => {
        if(isDescendingSort) {
            return a.date > b.date  ? -1 : 1
        }
        return  a.date > b.date  ? 1 : -1
    })
    return <div className="container py-5">
        <div className="row mx-0">
            <div className="col-12 text-right">
                Sort by
                <span style={{cursor: 'pointer'}} className="ui__sort mr-4" onClick={() => setIsDescendingSort(!isDescendingSort)}>
                    Most Recent {isDescendingSort ? '↓' : '↑'}
                </span>
            </div>
            {(sortedList || []).map((news, idx) => <PressReleaseList key={idx} news={news} />)}
        </div>
    </div>
}


const PressReleaseList = function ({ news = {} }) {
    const { date, pagePath, uid, title, description } = news || {};
    return <div className="col-md-6 col-12 pb-4 ui__pressRelease px-4">
        <small>{getFormattedDate(date)}</small>
        <h4><a href={`/${pagePath}/${uid}`}>{title}</a></h4>
        <p>{description}</p>
    </div>
}