import React, { Component } from "react";
import PropTypes from "prop-types";
import {
  Button,
  Container
} from "reactstrap";
import BackgroundImage from 'gatsby-background-image';
import { getColor, createUrl, checkCorporateEmail } from "../utils/universalHelperFunctions";
import ContentSlideshow from "./ContentSlideshow";
import VideoOverlay from "./VideoOverlay";
import Breadcrumb from "./Breadcrumb";
import "../styles/home-page-corousel.css";

class HomePageCorousel extends Component {
  constructor(props) {
    super(props);
    this._isMounted = false;
    this.linksData = props.pagesLinksData;
    this.items = props.corouselData;
    this.isBreadcrumbsElement;

    this.breadcrumbsData = this.props.breadcrumbsData;

    this.state = {
      isVideo: false,
      activeCampaign: false,
      items: JSON.parse(JSON.stringify(this.items)),
      sliderHeight: 'auto',
      isMobileView:false,
      winLoc:""
    };

    this.onResize = this.onResize.bind(this);
    this.setMaxHeightToCarouselOverlay = this.setMaxHeightToCarouselOverlay.bind(this);
    // this.handleClickScroll = this.handleClickScroll.bind(this);
  }


  componentDidMount() {
    this._isMounted = true;
    if(this._isMounted) {
      let height = this.setMaxHeightToCarouselOverlay();
      // console.log("height",height);
      this.setState({ sliderHeight: height });
      window.addEventListener('resize', (e) => { this.onResize();});
      if (document.querySelector('video')) {
        document.querySelector('video').addEventListener('loadeddata', this.onResize);
      }
      let url = (typeof window === 'undefined') ? '' : window.location.href;
      if(window){
        this.setState({ winLoc: window.location.pathname });
      }
      let searchParams = url.match(new RegExp('[\?&]id=([^&#]*)'));
      if (searchParams && searchParams.length > 0) {
        this.setState({ activeCampaign: true });
        let urlParams = new URLSearchParams(window.location.search);
        let value = urlParams.get('id');
        checkCorporateEmail(value)
        .then(response => {
          if (response.valid) {
            this.setState({
              activeCampaign: true
            });
          } else {
            this.setState({
              activeCampaign: false
            })
          }
        });
        this.setState({ activeCampaign: true });
      }
    }
    if (typeof window !== 'undefined') {
      this.setState({isMobileView: window.innerWidth < 768 ? true: false});
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    let maxHeight = this.setMaxHeightToCarouselOverlay()
    if(maxHeight !== prevState.sliderHeight) {
      this.setState({ sliderHeight: maxHeight});
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.onResize);
    if (document.querySelector('video')) {
      document.querySelector('video').removeEventListener('loadeddata', this.onResize);
    }
    this._isMounted = false;
  }

  handleClickScroll = (item) => {
    console.log('urlValue: ', item.hero_button_link.url)
    const element = document.getElementById('refine-resources-filters');
    if (element && item.hero_button_link.url === "/all-resources") {
      element.scrollIntoView({ behavior: 'smooth' });
    } else {
      const documentData = item?.hero_button_link.document ? item?.hero_button_link.document[0].data : null;
      const url = documentData && documentData.page_url.text ? `/${documentData.page_url.text}` : item.hero_button_link.url;
      window.location.pathname = documentData ? `${documentData.page_path.text}${url}` : createUrl(item?.hero_button_link.url, this.linksData) //  
    }
  };

  setMaxHeightToCarouselOverlay() {
    let carouselItems = document.querySelectorAll('.carousel-media-overlay');
    // console.log("carouselItems",carouselItems);
    let heights = [];
    [...carouselItems].forEach(carouselItem => {
      heights.push(carouselItem.offsetHeight);
      // console.log()
    });
    return Math.max(...heights);
  // }f
  }

  onResize() {
    if (typeof window !== 'undefined') {
      this.setState({isMobileView: window.innerWidth < 768 ? true: false});
    }
    let slideComponent = document.getElementsByClassName('slider-video');
    if (slideComponent.length > 0) {
      if (typeof window !== 'undefined' && window.innerWidth < 992) {
        let divHeight = document.querySelector('.corousel-video').offsetHeight;
        let overlayHeight = document.querySelector('.media-overlay').offsetHeight;
        let finalHeight = divHeight + overlayHeight;
        document.querySelector('.slider-video').style.height = finalHeight + 'px';
      } else {
        document.querySelector('.slider-video').removeAttribute('style');
      }
    }
  }
  render() {
    // console.log(":: Home Page Carousel props ::", this.props)
    let islandingpage =this.props.resourceOverviewData?.primary?.islandingpage;
    if (this.props.pageType === 'resources') {
      this.isBreadcrumbsElement = (
        <Breadcrumb
          pagesLinksData={this.linksData}
          pageType={this.props.pageType}
          resourceOverviewData={this.props.resourceOverviewData}
          pageHeading={this.state.items[0].hero_description.text}
          breadcrumbsData={this.breadcrumbsData}
        />
      );
    } else if(this.breadcrumbsData == undefined) {
      this.isBreadcrumbsElement = null;
    } else {
      this.isBreadcrumbsElement = (
        <Breadcrumb
          breadcrumbsData={this.breadcrumbsData}
          pagesLinksData={this.linksData}
        />
      );
    }

    let sliderName;
    // console.log("this.state.items[0] ", this.state.items[0]);
    const slides = this.state.items.map((item, i) => {
      let corItem = null;
      let corOverlay = null;
      let isVideo = item.videoflag === "Video";
      // console.log('button:', item);
      if (isVideo) {
        sliderName = "slider-video";
        corItem = (
          <video className="corousel-video" playsInline autoPlay muted loop preload='auto'>
            <source src={item.video_url.url} type="video/mp4" />
            <source src={item.video_url.url} type="video/ogg" />
            <source src={item.video_url.url} type="video/webm" />
            Your browser does not support HTML5 video.
          </video>
        );
        corOverlay = (
          <div className="media-overlay col-12 col-lg-6 col-xl-5">
            <p className="display-5">{item.hero_headline.text}</p>
            <p className="lead">{item.hero_description.text}</p>
            {
              item.hero_button_link && item.hero_button_link.url && (
                <a
                  className="lead d-md-block"
                  href={createUrl(item.hero_button_link.url, this.linksData)}
                  hrefLang="en"                
                >
                  <Button className="submit-btn btn btn-primary">{item.hero_button_text.text}</Button>
                </a>

              )
            }
          </div>
        );
        return (
          <div key={i} className={`slide${i} slideWindow CURENT`}>
            {corItem}
            {corOverlay}
          </div>
        );
      } else if (item.videoflag === "Carousel") {
        // console.log("item",item);
        sliderName = "slider-carousel";
        let crouselBannerBtn = "";
        // if (item.hero_button_link && item.hero_button_link.url && item.hasOwnProperty("hero_button_text") && item.hero_button_text.text) {
        //   crouselBannerBtn = (
        //     <a className="lead d-md-block" href={createUrl(item.hero_button_link.url, this.linksData)} hrefLang="en">
        //       <Button className="carousel-submit-btn btn btn-primary" style={item.hero_button_color ? { backgroundColor: getColor(item.hero_button_color), borderColor: getColor(item.hero_button_color) } : {}}  onClick = {(e) => { this.handleClickScroll(); }}>{item.hero_button_text.text}</Button>
        //     </a>
        //   );
        // } 

        if (item.hero_button_link && item.hero_button_link.url && item.hasOwnProperty("hero_button_text") && item.hero_button_text.text) {
          crouselBannerBtn = (
            // <a className="lead d-md-block" href={createUrl(item.hero_button_link.url, this.linksData)} hrefLang="en" >
              <Button className="carousel-submit-btn btn btn-primary" style={item.hero_button_color ? { backgroundColor: getColor(item.hero_button_color), borderColor: getColor(item.hero_button_color) } : {}} onClick = {(e) => { this.handleClickScroll(item)}} >{item.hero_button_text.text}</Button>
            // </a>
          );
        }

        

        var styleObj = {};
        if (item.hero_background_image.url) {
          styleObj.background = `${(item.hero_image_overlay == "true") ? 'linear-gradient( rgba(30, 36, 53 , 0.6),rgba(30,36,53,0.6)),' : ''} url(${item.hero_background_image.url}) center center/cover no-repeat`;
        }
        const sources = [];
        if(item?.hero_background_image?.MobileHDPI && item?.hero_background_image?.MobileHDPI?.localFile) {
          sources.push({
            ...item?.hero_background_image?.MobileHDPI?.localFile?.childImageSharp?.fluid,
            media: `(max-width: 767px)`
          })
        }
        if(item.hero_background_image.Tablet && item.hero_background_image.Tablet.localFile) {
          sources.push({
            ...item?.hero_background_image?.Tablet?.localFile?.childImageSharp?.fluid,
            media: `(min-width: 768px) and (max-width: 1023px)`,
          });
        }
        if(item?.hero_background_image?.Desktop && item?.hero_background_image?.Desktop?.localFile) {
          sources.push({
            ...item?.hero_background_image?.Desktop?.localFile?.childImageSharp?.fluid,
            media: `(min-width: 1024px)`,
          });
        }

        const isBrowser = typeof window !== "undefined";
        // console.log("isBrowser",isBrowser);
        return (
          <React.Fragment key={item.hero_multiline_heading.text?.split(' ').join('_')}>
             <BackgroundImage
              Tag="div"
              fluid={sources}
              id = {this.state.winLoc === "/resources/all-resources/" ? "sliderImageHeightResourcesCarousel" : ""}
              className={`sliderImageHeight banner-background-image`}
            >
              <div className={`${item.hero_image_overlay == "true"  ? 'image-overlay':'' }`} id={this.state.winLoc === "/resources/all-resources/" ? "resource-page-corousel" : ""} >
                <div style={this.state.sliderHeight ? (this.state.winLoc === "/resources/all-resources/" ? {height : "auto"} : { minHeight: this.state.sliderHeight }) : {}}>
                  <Container className="carousel-media-overlay" style={{ color: getColor(item.hero_font_color) }}>
                    <div>
                      <div className="carousel-label-wrapper">
                        <h6 className="carousel-label" style={{ borderColor: getColor(item.hero_button_color) }}>{item.hero_headline.text}</h6>
                      </div>
                      <div className="row col-12 no-margin no-padding">
                        <div className="col-12 col-md-8 no-padding description-spacing">
                          <div className="carousel-heading" >{item.hero_multiline_heading.text}</div>
                          <div className={`carousel-description`}>{item.hero_multiline_description.text}</div>                         
                          {crouselBannerBtn} 
                        </div>
                      </div>
                    </div>
                  </Container>
                </div>
              </div>
            </BackgroundImage>
          </React.Fragment >
        );
      } else {
        sliderName = "slider-img";
        var styleObj = {};
        if (item.hero_background_image.url) {
          styleObj.background = `${(item.hero_image_overlay == "true") ? 'linear-gradient( rgba(30, 36, 53 , 0.6),rgba(30,36,53,0.6)),' : ''} url(${item.hero_background_image.url}) center center/100% 100% no-repeat`;

        } else {
          if (item.hasOwnProperty('hero_background_color') && item.hero_background_color) {
            styleObj.backgroundColor = getColor(item.hero_background_color);
            styleObj.backgroundRepeat = 'no-repeat';
          }
        }
        if ((item.hero_headline && item.hero_headline.text) || (item.hero_description && item.hero_description.text)) {
          styleObj.minHeight = '500px';
        }
        let descriptionGridClass = item.image_change === true?'col-12 col-md-12':'col-12 col-md-8 no-padding ';
        let multilineDescriptionGridClass =  item.image_change === true?'col-12 col-md-12':'col-12 col-md-8 no-padding ';
        let isDocumentType = false;
        //reverting new case study design, (this.props.resourceOverviewData.primary.resource_type === 'Case Study') remove
        let allowedResourceType = false;
        if(this.props.resourceOverviewData) {
          let isNewDesign = process.env.NEW_CASE_STUDY_DESIGN && process.env.NEW_CASE_STUDY_DESIGN === 'true' ? true: false;
          if(this.props.resourceOverviewData.primary.resource_type && this.props.resourceOverviewData.primary.resource_type === 'Case Study' && (islandingpage || !isNewDesign)) {
            allowedResourceType = true;
          }
          if(allowedResourceType && !this.state.activeCampaign) {
            descriptionGridClass = 'col-12';
            multilineDescriptionGridClass = islandingpage ? 'col-12 col-md-12' : 'col-12 col-md-6';
            isDocumentType = true;
          }
        }
        const sources = [];
        if(item.hero_background_image.MobileHDPI && item.hero_background_image.MobileHDPI.localFile) {
          sources.push({
            ...item.hero_background_image.MobileHDPI?.localFile?.childImageSharp?.fluid,
            media: `(max-width: 767px)`,
          });
        }
        if(item.hero_background_image.Tablet && item.hero_background_image.Tablet.localFile) {
          sources.push({
            ...item.hero_background_image?.Tablet?.localFile?.childImageSharp?.fluid,
            media: `(min-width: 768px) and (max-width: 1023px)`,
          });
        }
        if(item.hero_background_image.Desktop && item.hero_background_image.Desktop.localFile) {
          sources.push({
            ...item.hero_background_image?.Desktop?.localFile?.childImageSharp?.fluid,
            media: `(min-width: 1024px)`,
          });
        }

        corItem = (
          <div>
            {/* {allowedResourceType ? this.isBreadcrumbsElement : ''} */}
            <div className="sliderImageHeight">
            <BackgroundImage
              Tag="div"
              fluid={sources}
              className={`sliderImageHeight banner-background-image`}
              style={{position: 'inherit'}}
            >
              <div className={`${item.hero_image_overlay == "true" && item.image_change !== true ? 'image-overlay':item.image_change === true?'image-height-overlay':''}`}>
                {(item.hero_headline && item.hero_headline.text && !item.hero_description && !item.hero_description.text) ? (
                  <div className="overlay-text">{item.hero_headline.text}</div>
                ) : (
                    <Container className={`slider-media-overlay ${this.props.resourceOverviewData && this.props.resourceOverviewData.primary.resource_type && this.props.resourceOverviewData.primary.resource_type === 'White Paper' ? 'whitepaper-slider-overlay' : ''} ${item.hero_video && item.hero_video.embed_url ? 'video-media-overlay' : ''}`} style={{ color: item.hero_font_color ? getColor(item.hero_font_color) : '' }}>
                      {!!this.props.heroTagImage && <div className="slider-media-overlay-tag"> <img src={this.props.heroTagImage} /> </div> }
                      <div className="carousel-label-wrapper">
                        <h6 className="carousel-label" style={{ borderColor: item.hero_button_color ? getColor(item.hero_button_color) : '' }}>{item.hero_headline.text}</h6>
                      </div>
                      {
                        (item.hero_description && item.hero_description.text && !((item.hero_video && item.hero_video.embed_url))) ? (
                          <h1 style={{paddingLeft:"15px"}} className={`${islandingpage ? 'landing-slider-img-description ' : 'slider-img-description no-padding '}${descriptionGridClass}`} dangerouslySetInnerHTML= {{ __html: item.hero_description.text }}></h1>
                        ) : ''
                      }
                      <div className="row col-12 no-margin no-padding">
                        {
                          <div className={`${!(item.hero_video && item.hero_video.embed_url) ? multilineDescriptionGridClass : (islandingpage ? 'col-12 col-md-12 no-padding description-spacing' : 'col-12 col-md-6 no-padding description-spacing') }`} style={{paddingLeft:"0px"}}>
                            {
                              (item.hero_description && item.hero_description.text && ((item.hero_video && item.hero_video.embed_url))) ? (
                                <h1 className={`slider-img-description  no-padding `} >{item.hero_description.text}</h1>) : ''
                            }
                            {
                              (item.hero_multiline_description && item.hero_multiline_description.text) ? (
                                <>                            
                                {/* {!this.state.isMobileView &&   <div className="carousel-description" >{item.hero_multiline_description.text}</div>}
                                {this.state.isMobileView &&  <div className="carousel-description" style={isDocumentType ? {marginTop :'60px'} : {}}>{item.hero_multiline_description_mobile?item.hero_multiline_description_mobile.text!==""?item.hero_multiline_description_mobile.text:item.hero_multiline_description.text:item.hero_multiline_description.text}</div>} */}
                                {<div className="carousel-description" >{item.hero_multiline_description.text}</div>}
                        
                                  <div>
                                    {
                                      item.hero_button_link && item.hero_button_link.url && (
                                        <a href={createUrl(item.hero_button_link.url, this.linksData)} style={{ color: getColor(item.hero_button_color) }} className="learn-more-button" hrefLang="en">
                                          {item.hero_button_text?.text}
                                          <i className="fas fa-long-arrow-alt-right media-button-icon"></i>
                                        </a>
                                      )
                                    }
                                    {
                                      item.hero_button_text?.text && (
                                      <a className="lead d-md-block" href={'#Prismic__ResourceBodyFormDisplaySection__Prismic__Resource__ZJp6ORIAACAAsI9Y__body__5'} hrefLang="en" >
                                      <Button className="carousel-submit-btn btn btn-primary Now" style={item.hero_button_color ? { backgroundColor: getColor(item.hero_button_color), borderColor: getColor(item.hero_button_color) } : {}}>{item.hero_button_text.text}</Button>
                                    </a>
                                    )
                                    }
                                  </div>
                                </>
                              ) : ''
                            }
                          </div>
                        }
                        {
                          (item.hero_video && item.hero_video.embed_url) ? (
                            <div className={`hero-overlay-video-holder ${this.state.activeCampaign ? 'col-12' : 'col-12 col-md-6'} no-padding overlay-spacing`}>
                              <VideoOverlay iconclass={item.hero_video_icon_class && item.hero_video_icon_class.text ? item.hero_video_icon_class.text : 'far fa-play-circle'} color={item.hero_video_icon_color ? getColor(item.hero_video_icon_color) : getColor(item.hero_button_color)} videoThumbnail={item.video_thumbnail} videoLink={item.hero_video_link} videoDetails={item.hero_video} pagesLinksData={this.linksData} />
                            </div>
                          ) : ''
                        }
                      </div>
                    </Container>
                  )
                }
              </div>
              </BackgroundImage>
            </div>
            {this.isBreadcrumbsElement}
            {/* {!allowedResourceType ? this.isBreadcrumbsElement : ''} */}
          </div >
        );
        return (
          <div key={i} className={`slideWindow ${item.image_visibility_for_mobile && item.image_visibility_for_mobile === "false" ? 'remove-hero-image' : ''}`}>
            {corItem}
          </div>
        );
      }
    });
    if (sliderName === "slider-carousel") {
      
      let carouselWrapper = (
        <ContentSlideshow slides={slides}/>
      );
      return <div className={`${sliderName}`}>{carouselWrapper}</div>;
    } else {
      return <div className={sliderName}>{slides}</div>;
    }
  }
}

export default HomePageCorousel;

HomePageCorousel.propTypes = {
  corouselData: PropTypes.array.isRequired,
  pagesLinksData: PropTypes.array
};
