import { Component } from "react";
import PropTypes from "prop-types";

export default class UploadLogo extends Component {
    constructor(props) {
      super(props);
      this.UploadLogo = props.input;
      this.linksData = props.pagesLinksData;
    }

    render() {
        let logo_image = this.UploadLogo.primary.upload_logo.url;
        return(
          <div>  <img src={logo_image} alt="header image" className="img" /></div>
        );
    }

}

UploadLogo.propTypes = {
    input: PropTypes.object,
    pagesLinksData: PropTypes.array,
  };
