import React, { Component } from "react";
import PropTypes from "prop-types";
import "../styles/breadcrumb.css";
import { Container, Row, Col } from "reactstrap";
import { replaceCharAt } from "../utils/universalHelperFunctions";

class Breadcrumb extends Component {
  constructor(props) {
    super(props);
    this.linksData = props.pagesLinksData;
    this.breadcrumbsData = props.breadcrumbsData ? props.breadcrumbsData.items : null;
    this.leftData = this.breadcrumbsData && this.breadcrumbsData.filter(function (bc) {
      return bc.position === "Left";
    });
    if ((this.props.pageType === 'resources' || this.props.pageType === 'open-position') && !(this.leftData && this.leftData.length)) {
      this.leftData = [{ breadcrumb_title: { text: 'Home' }, breadcrumb_url: { url: '/homepage' }, position: 'Left' }];
      this.isBreadCrumbPresent = false;
    } else {
      this.isBreadCrumbPresent = true;
    }

    this.rightData = this.breadcrumbsData ? this.breadcrumbsData.filter(function (bc) {
      return bc.position === "Right";
    }) : null;
    this.state = {
      position: "absolute",
      isStickyTop: false
    };
    this.scrollRef = React.createRef();
    this.rightBcNavigation = this.rightBcNavigation.bind(this);
    this.capitalizeText = this.capitalizeText.bind(this);
    this.handleBreadCrumbClick = this.handleBreadCrumbClick.bind(this);
  }
  componentDidMount() {
    const { pageType } = this.props;
    window.addEventListener("scroll", this.handleScroll.bind(this));
    this.setState({ breadcrumbTop: this.scrollRef.current.offsetTop });
    if ((pageType === 'resources' || pageType === 'open-position') && !this.isBreadCrumbPresent && this.leftData && this.leftData.length > 0) {
      var url = typeof window !== 'undefined' ? window.location.pathname.substr(1) : null;
      if (url && url.charAt(url.length - 1) === '/') {
        url = url.slice(0, -1);
      }

      var resourceTitle = this.props.pageHeading || "";
      //added the changes to remove job ID from breadcrumb

      if (url.match(/\//g).length > 2) {
        url = replaceCharAt(url, url.lastIndexOf('/'), '^');
      }
      let urlItems = url && url.split('/');
      urlItems.forEach((item, index, arr) => {
        if (item.indexOf('^') > -1) {
          item = replaceCharAt(item, item.indexOf('^'), '/');
        }
        let breadcrumb_label = item.replace(/-/g, ' ');
        if (breadcrumb_label.indexOf('~') > -1) {
          breadcrumb_label = breadcrumb_label.replace('~', '-');
        }
        var regEx = new RegExp(/ e commerce/, 'ig')
        breadcrumb_label = breadcrumb_label.match(regEx) && breadcrumb_label.match(regEx).length > -1 ? breadcrumb_label.replace(regEx, 'E-Commerce') : breadcrumb_label;
        let obj = {
          breadcrumb_title: {
            text: (index !== arr.length - 1) ? this.capitalizeText(breadcrumb_label) : this.props.pageType === 'resources' && this.props.resourceOverviewData.primary.resource_type && this.props.resourceOverviewData.primary.resource_type !== 'Event' ? resourceTitle : this.breadcrumTitle(item),
          },
          breadcrumb_url: {
            url: (index !== arr.length - 1) ? this.generateURL(item) : null,
          },
          position: 'Left',
        }
        if(obj.breadcrumb_title && obj.breadcrumb_title.text === "News"){
          obj.breadcrumb_url.url = "/about-us/news-&-press-releases/"
        }
        this.leftData[this.leftData.length] = obj;
      });
    }
  }

  breadcrumTitle(breadcrumbLabel) {
    let pageType = this.props.pageType;
    let pgIndex = this.linksData.findIndex(function (nodes) {
      if (pageType !== 'resources' || pageType !== 'open-position') {
        return nodes.node.uid === breadcrumbLabel;
      } else {
        return nodes.node.data.page_path.text === breadcrumbLabel;
      }
    });
    let pgDetails = pgIndex !== -1 ? this.linksData[pgIndex].node.data : { resource_display_name: { text: breadcrumbLabel } };
    return pgDetails?.resource_display_name.text;
  }

  capitalizeText(str) {
    return str.replace(/(?:^\w|[A-Z]|\b\w)/g, function (word, index) {
      return word.toUpperCase();
    }).replace(/\s+/g, ' ');
  }
  handleScroll(event) {
    let isStickyTopFlag = false;
    if (this.props.pageType !== 'resources') {
      if (window.pageYOffset + 55 > this.state.breadcrumbTop - 62) {
        isStickyTopFlag = true;
      }
    } else {
      if (window.pageYOffset + 60 > this.state.breadcrumbTop) {
        isStickyTopFlag = true;
      }
    }
    this.setState({
      isStickyTop: isStickyTopFlag
    });
  }
  rightBcNavigation(name) {
    let sliceList = document.getElementById('slice-container').children;
    for (let i = 0; i < sliceList.length; i++) {
      if (sliceList[i].getAttribute('id') && sliceList[i].getAttribute('id').indexOf(name) >= 0) {
        let scrollTop = sliceList[i].offsetTop - 100;
        window.scroll({
          top: scrollTop, left: 0, behavior: 'smooth'
        });
        return;
      }
    }
  }
  generateURL(breadcrumbLabel) {
    let url = window.location.pathname.substr(1);
    if (url && url.charAt(url.length - 1) === '/') {
      url = url.slice(0, -1);
    }
    let linkArray = url.split('/');
    let pageType = this.props.pageType;
    let pgIndex = this.linksData.findIndex(function (nodes) {
      if (pageType !== 'resources' || pageType !== 'open-position') {
        return nodes.node.uid === breadcrumbLabel;
      } else {
        return nodes.node.data.page_path.text === breadcrumbLabel;
      }
    });
    if (pgIndex !== -1) {
      let pgDetails = this.linksData[pgIndex].node.data;
      let finalUrl;
      if (pgDetails.page_display_name) {
        finalUrl = `${pgDetails.page_path.text ? "/" + pgDetails.page_path.text : ""
          }/${pgDetails.page_display_name.text.split(" ").join("-")}`;
      } else {
        finalUrl = `${pgDetails.page_path.text ? "/" + pgDetails.page_path.text : ""
          }/${pgDetails.resource_display_name.text.split(" ").join("-")}`;
      }
      let breadcrumbLink = window.location.pathname;
      if (breadcrumbLink && breadcrumbLink.charAt(breadcrumbLink.length - 1) === '/') {
        breadcrumbLink = breadcrumbLink.slice(0, -1);
      }
      if (finalUrl.toLowerCase() === breadcrumbLink) {
        return null;
      }
      return `${finalUrl.toLowerCase()}`;
    } else if (linkArray[0] === 'resources' && linkArray.length > 2 && linkArray[1] === breadcrumbLabel) {
      return `/resources/all-resources?${breadcrumbLabel}`;
    } else if (linkArray[0] === 'jobs' && linkArray.length > 2 && linkArray[1] === breadcrumbLabel) {
      return `/jobs/find-a-position/`;
    } else if (linkArray[0] === 'resources' && linkArray.length > 2 && breadcrumbLabel === "resources") {
      return '/resources/all-resources/';
    } else if (linkArray[0] === 'resources' && linkArray.length >= 2 && breadcrumbLabel === "resources") {
      return '/resources/all-resources/';
    } else {
      return null;
    }
  }
  createUrl = url => {
    if (url) {
      var queryParams = "";
      if (url.indexOf('{domain}') > -1) {
        url = url.split('{domain}').pop();
      }
      if (url.indexOf('?') > -1) {
        queryParams = '?' + url.split('?')[1];
        url = url.split('?')[0];
      }
      let pgId = url.split("/")[1];
      let pgIndex = this.linksData.findIndex(function (nodes) {
        return nodes.node.uid === pgId;
      });
      if (pgIndex !== -1) {
        let pgDetails = this.linksData[pgIndex].node.data;
        let finalUrl;
        if (pgDetails.page_display_name) {
          finalUrl = `${pgDetails.page_path.text ? "/" + pgDetails.page_path.text : ""
            }/${pgDetails.page_display_name.text.split(" ").join("-")}/`;
        } else {
          finalUrl = `${pgDetails.page_path.text ? "/" + pgDetails.page_path.text : ""
            }/${pgDetails.resource_display_name.text.split(" ").join("-")}/`;
        }
        return `${finalUrl.toLowerCase()}${queryParams}/`;
      } else {
        return `/${url}${queryParams}/`;
      }
    }
  };
  handleBreadCrumbClick(e) {
    if (this.props.pageType === 'resources') {
      localStorage.setItem('breadCrumbFlag', 'true');
      let url = e.target.href;
      if (url.indexOf('?') > -1) {
        url = url.split('?');
        e.target.href = url[0];
        localStorage.setItem('breadCrumbFilter', url[1]);
      }
    }
  }
  render() {
    let breadcrumbOnLoad = {};
    let isLandingPage = this.props.resourceOverviewData?.primary?.islandingpage;
    let isNewDesign = process.env.NEW_CASE_STUDY_DESIGN && process.env.NEW_CASE_STUDY_DESIGN === 'true' ? true : false;
    let allowedResourceType = this.props.resourceOverviewData && this.props.resourceOverviewData.primary.resource_type && this.props.resourceOverviewData.primary.resource_type === 'White Paper';
    if (this.props.resourceOverviewData && this.props.resourceOverviewData.primary.resource_type && this.props.resourceOverviewData.primary.resource_type === 'Case Study' && !isNewDesign) {
      allowedResourceType = true;
    }
    if (allowedResourceType || this.props.pageType === 'open-position') {
      breadcrumbOnLoad = 'breadcrumbOnLoad';
    }


    
    return (

      <div
        className={`${breadcrumbOnLoad} bc-parent-container ${this.state.isStickyTop ? 'sticky' : ''}`}
        ref={this.scrollRef}
      >
        <Container className="bc-slice-container">
          <Row noGutters>
            <Col
              className={`column-padding ${this.rightData && this.rightData.length > 0 ? (this.leftData && this.leftData[1] && this.leftData[1].breadcrumb_title.text === "About Us" ? 'col-md-5' : 'col-md-7') : 'col-md-12'}`}
              style={{
                overflow: 'hidden',
                textOverflow: 'ellipsis'
              }}
            >
              {this.leftData.map((item, i) => (
                <span key={item.breadcrumb_title.text}>
                  <a
                    style={{
                      color: "#FFFFFF",
                      textDecoration: item.breadcrumb_url && item.breadcrumb_url.url ? 'underline' : 'none',
                      fontWeight: i == this.leftData.length - 1 ? "600" : "300",
                      pointerEvents: !item.breadcrumb_url ? 'none' : 'pointed',
                    }}
                    href={
                      (item.breadcrumb_url && item.breadcrumb_url.url != "/homepage") ? this.isBreadCrumbPresent ? this.createUrl(item.breadcrumb_url.url) : item.breadcrumb_url.url
                        : item.breadcrumb_url &&
                          item.breadcrumb_url.url === "/homepage"
                          ? "/"
                          : ""
                    }
                    alt={i == this.leftData.length - 1 ? item.breadcrumb_title.text : ''}
                    id={item.breadcrumb_title.text}
                    onClick={this.handleBreadCrumbClick}
                    hrefLang="en"
                  >
                    {this.props.pageType === 'open-position' ? decodeURIComponent(item.breadcrumb_title.text) : item.breadcrumb_title.text}
                  </a>
                  <span
                    style={{
                      display: i == this.leftData.length - 1 && item.breadcrumb_title.text !== "Home" ? "none" : "inline"
                    }}
                  >
                    &nbsp;/&nbsp;
                  </span>
                </span>
              ))}
            </Col>
            {
              !isLandingPage && this.rightData && this.rightData.length > 0 ? (
                <Col className={`column-padding bc-right ${(this.leftData && this.leftData[1] && this.leftData[1].breadcrumb_title.text === "About Us" ? 'col-md-7' : 'col-md-5')}`}>
                  <span style={{ float: "right" }}>
                    {this.rightData && this.rightData.map((item, i) => (
                      <span key={item.breadcrumb_title.text}>
                        <a
                          style={{ color: "#FFFFFF", cursor: "pointer" }}
                          onClick={(e) => {
                            if (item.hasOwnProperty("breadcrumb_label")) {
                              this.rightBcNavigation(item.breadcrumb_label.text);
                            } else {
                              e.preventDefault();
                            }
                          }}
                        >
                          {this.props.pageType === 'open-position' ? decodeURIComponent(item.breadcrumb_title.text) : item.breadcrumb_title.text}
                        </a>
                        <span
                          style={{
                            display:
                              i == this.rightData.length - 1 ? "none" : "inline-block"
                          }}
                        >
                          &nbsp;|&nbsp;
                        </span>
                      </span>
                    ))}
                  </span>
                </Col>) : null
            }
          </Row>
        </Container>
      </div>
    );
  }
}
export default Breadcrumb;
Breadcrumb.propTypes = {
  breadcrumbsData: PropTypes.object,
  pagesLinksData: PropTypes.array
};
