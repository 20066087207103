import React, { useState } from "react";
import '../styles/login-enabling-block.css';
//import { Modal, Button, Form, Container, Row, Col } from 'react-bootstrap';
import { Container, Col, Row, Button } from "reactstrap";
import { getColor, camelize, isParamPresentInURL, getFormattedDate, checkCorporateEmail } from "../utils/universalHelperFunctions";
import {
  AvForm,
  AvField,
  AvGroup,
} from "availity-reactstrap-validation";
import axios from "axios";
import blockedDomains from "./../utils/domain";
import { subscribe_api } from '../constants';
import { useEffect } from "react";

function LoginEnablingBlock(props) {
  const [isShow, setIsShow] = useState(false);
  const initModal = () => {
    return setIsShow(!isShow)
  }

  const [emailId, setEmailId] = useState("");
  const [otp, setOtp] = useState("");
  const [isEmailIdValid, setIsEmailIdValid] = useState("");
  const [emailValidationError, setEmailValidationError] = useState("");
  const [subscribeData, setSubscribeData] = useState({});
  useEffect(() => {
    props.onBlockerDisplay();
  },[])

  const validateEmailField = (e, value) => {
    e.preventDefault();
    setEmailId(value);
    // const isBlocked = blockedDomains.some(domain => {
    //   return value.includes(domain)
    // });

    // if (!value || isBlocked) {
    //     return new Promise((resolve, reject) => {
    //         resolve({ valid: false });
    //         setEmailValidationError(true);
    //     })
    // }
  }

  const onOtpChange = (e, value) => {
    e.preventDefault();
    setOtp(value);
    // const isBlocked = blockedDomains.some(domain => {
    //   return value.includes(domain)
    // });

    // if (!value || isBlocked) {
    //     return new Promise((resolve, reject) => {
    //         resolve({ valid: false });
    //         setEmailValidationError(true);
    //     })
    // }
  }

  // if (emailValidationError) {
  //   emailValidationError = (
  //     <div className="invalid-feedback">{emailValidationError}</div>
  //   )
  // }

  const handleValidSubmit = (event) => {
    event.preventDefault();
    setEmailValidationError(false);
    console.log("emailValidationError", emailValidationError);
    const isBlocked = blockedDomains.some(domain => {
      return emailId.includes(domain)
    });

    if (!emailId || isBlocked) {
      return new Promise((resolve, reject) => {
        resolve({ valid: false });
        setEmailValidationError(true);
      })
    } else {
      axios.post(`${subscribe_api}/subscribe`, { email: emailId }).then(res => {
        console.log('res: ', res.data);
        setSubscribeData({ ...res.data })
      })
    }
  }

  const handleVerifyOtp = (event, errors, values) => {

    const pageUrl = window.location.href;
    axios.post(`${subscribe_api}/verifyOTP`, { email: emailId, otp: otp, page: pageUrl }).then(res => {
      console.log('res: ', res.data);
      if (res.data.status === 200) {
        props.onLogin();
      } else {
        setSubscribeData({ ...subscribeData, message: res.data.message });
      };
    })
  }

  const handleInvalidSubmit = (event, errors, values) => {
    console.log("sdfghj", errors, values);
    setEmailValidationError(true);
  }


  return (
    <div className="login-blocker-container">
      <div className="gradient">

      </div>
      <div className="login-blocker">
        <Row className="align-items-center">
          <Col xs="12" md="12" className="col-wrapper justify-content-center">
            <span className="blocker-heading">Want to keep reading? Unlock the content below.</span>
          </Col>
        </Row>
        <Row >

          <Col xs="12" md="12" >
            <AvForm
              onValidSubmit={handleValidSubmit}
              onInvalidSubmit={handleInvalidSubmit}
            >
              <Row className= "login-form-main">
                <Col xs="12" md="4" className="col-wrapper">
                  <AvField
                    type="email"
                    name="emailId"
                    id="emailId"
                    placeholder="Work Email*"
                    bsSize="lg"
                    className={`all-form-input ${isEmailIdValid ? 'email-input-field' : ''}`}
                    validate={{
                      required: { value: true, errorMessage: 'Field can’t be left blank. Please enter a corporate email.' },
                      email: { value: true, errorMessage: "This is not a valid email format. Please enter a valid corporate email." },
                    }}
                    onChange={validateEmailField}
                  // onClick={validateEmailField}
                  />
                </Col>
                {subscribeData.status === 200 && <Col xs="12" md="4" className="col-wrapper justify-content-center" style={{ display: "flex" }}><AvGroup>
                  <AvField
                    type="text"
                    name="otp"
                    id="otp"
                    placeholder="OTP*"
                    bsSize="lg"
                    className={`all-form-input`}
                    validate={{
                      required: { value: true, errorMessage: 'Field can’t be left blank. Please enter OTP.' },
                    }}
                    onChange={onOtpChange}
                  // onClick={validateEmailField}
                  //helpMessage={}
                  />
                  
                </AvGroup>
                </Col>
                }
                {emailValidationError && <p style={{ fontSize: ".875em", color: "#dc3545" }}>"Please enter a valid corporate email."</p>}
                <Col xs="12" md="4" className="col-wrapper">
                  {subscribeData.status === 200 ? <Button
                    color="primary"
                    // disabled = {emailValidationError}
                    className="formButton"
                    onClick={handleVerifyOtp}
                  >Verify OTP</Button> : <Button
                    color="primary"
                    className="formButton"
                    // disabled = {emailValidationError}
                    onClick={handleValidSubmit}
                  >Submit</Button>}
                </Col>
              </Row>
              <Row>
                <Col  xs="12" md="4" className="col-wrapper justify-content-center">
                </Col>
                <Col  xs="12" md="4" className="col-wrapper justify-content-center">
                  <span className="error-message">{subscribeData.message}</span>
                </Col>
                <Col  xs="12" md="4" className="col-wrapper justify-content-center">
                </Col>
              </Row>
            </AvForm>
          </Col>
        </Row>
      </div>
    </div>
  );
}

export default LoginEnablingBlock;