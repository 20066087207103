import React, {useState, useEffect} from 'react';
import '../styles/scrollToTop.css';
	
const ScrollToTopButton = () =>{


const [visible, setVisible] = useState(false);
	
const toggleVisible = () => {
	const scrolled = document.documentElement.scrollTop;
	if (scrolled > 100){
	setVisible(true);
	}
	else if (scrolled <= 100){
	setVisible(false);
	}
};

useEffect(() => {
    window.addEventListener('scroll', toggleVisible);
}, []);
	
const scrollToTop = () =>{
	window.scrollTo({
	top: 0,
	behavior: 'smooth'
	});
};


	
return (
    <button
    type="button"
    className="btn btn-danger btn-floating btn-lg"
    id="btn-back-to-top"
    onClick={scrollToTop}
    style={{display: visible ? 'block' : 'none'}}
    >
    <i className="fas fa-arrow-up"></i>
    </button>
);
}
	
export default ScrollToTopButton;