import React, { useState, useEffect, useRef } from "react";
import {
  Container,
  Row,
  Col,
  Collapse
} from "reactstrap";
import "./../styles/job-description.css";
import '../styles/virtual-walkin-details.css';
 
function CollapseContentSection (props) {
  const [collapse, isOpen] = useState(props.isOpen || false);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      onResize();
      window.addEventListener('resize', (e) => onResize());
    }
  }, []);

  const onResize = () => {
    if (typeof window !== 'undefined' && window.innerWidth < 768) {
      isOpen(props.isOpen);
    } else {
      isOpen(true);
    }
  }

  const toggle = () => {
    if (typeof window !== 'undefined' && window.innerWidth < 768) {
      isOpen(!collapse);
    }
  }

  const content = props.data;
  return (
    <Container className="jd-section-padding jd-section no-padding" onClick={toggle}>
      <Row noGutters>
          <Col md={12} className="jd-heading">
            <h3 className="walkin-section-key">{props.fieldLabel}</h3>
            <button type="button" className="add-jobs" onClick={toggle}>
              <i className={`${collapse ? 'far fa-chevron-up': 'far fa-chevron-down'}`} aria-hidden="true" />
            </button>
          </Col>
          <Col md={12}>
            <Collapse isOpen={collapse}>
              <div 
                className="walkin-section-content"
                dangerouslySetInnerHTML={{
              __html: content ? content.raw.length >= 1 ? content.html : content.raw[0].text : '',
              }}/>
            </Collapse>
          </Col>
        </Row>
    </Container>
  )
}

export default CollapseContentSection;