import React, { useState, useEffect } from 'react'
import { useStaticQuery, graphql } from "gatsby";
// import { Modal, ModalBody } from "reactstrap";
import {
  Col,
  Row,
  Button,
  Label,
  Container,
  Modal,
  ModalBody
} from "reactstrap";
import { FormDisplaySection2 } from "slices";
import "../styles/image-magnifier-component.css";

function PopupModal(props) {

  const { allSlices, prismicPopupModal } = props;
  // const { page_content } = prismicPopupModal || {};
  // const [s] = page_content
  const { body } = prismicPopupModal || {};
  const [s] = [body];
  console.log("body",body);
  // let emailTemplateData = allSlices.filter((slice) =>
  //   [
  //     "PrismicPopupModalBodyEmailTemplateLinks"
  //     // "PrismicResourceBodyEmailTemplateLinks",
  //     // "PrismicPagePageContentEmailTemplateLinks",
  //     // "PrismicHomepagePageContentEmailTemplateLinks",
  //     // "PrismicJobDescriptionPageBodyEmailTemplateLinks",
  //   ].includes(slice.__typename)
  // );
  let expertsPopupData = {};
  allSlices.forEach(element => {
    if (element.slice_type === 'page_popup_model') {
      expertsPopupData = element;
    } 
  });
  let emailTemplateData = [props.prismicPopupModal.body[1]];
  emailTemplateData.__typename = "PrismicPopupModalBodyEmailTemplateLinks";

  const isPopupClosed = () => {
    let cookiearray = document.cookie.split(';')
    let isclose = false;
    cookiearray.forEach(item => {
      const cookie = item.split('=');
      if (cookie[0].trim() === "isPopupClosed") {
        isclose = cookie[1] === 'true';
      }
    });
    return isclose;
  }
  const [open, setOpen] = useState(false);

  useEffect(() => {
    // setTimeout(() => {
    //   setOpen(true && !isPopupClosed());
    // }, 4000);
    if(window.location.pathname !== "/" && window.location.pathname !== "/about-us/" && window.location.pathname !== "/jobs/why-gspann/" ){
      setOpen(true && !isPopupClosed());
    }
  }, [])

  const toggle = () => {
    document.cookie = `isPopupClosed=${true};`;
    setOpen(isOpen => !isOpen)
  };
  const form = <FormDisplaySection2
    key={s.id}
    input={s}
    emailTemplateData={emailTemplateData}
  />

  return (
    <Container id="popupform-main-container">
    <Modal
      className="imageMagnifierModal container"
      isOpen={open}
      toggle={toggle}
    >
      <ModalBody className="modal-body-container">
      <Row className='col-md-12' style = {{ paddingLeft:"0px",paddingRight:"0px",marginLeft:"0px",marginRight:"0px",}}>
      <Col className='col-md-04' style = {{ width:"30%", padding:"0px"}}>
        {/* <img alt="Join Us" src={props.prismicPopupModal.body.primary ? props.prismicPopupModal.body[0].primary.popup_image.url : ""} ></img> */}
        {(props.prismicPopupModal.body.slice_type === "experts") && <img alt="Join Us" src={expertsPopupData ? expertsPopupData.primary.popup_image.url : ""} ></img>}
        {(props.prismicPopupModal.body.slice_type === "page_popup_model") && <img alt="Join Us" src={props.prismicPopupModal? props.prismicPopupModal.body[0].primary.popup_image.url : ""} ></img>}
      </Col>
      <Col className='col-md-08'style = {{ width:"70%", padding:"0px"}}>
         <button className="close-btn btn" onClick={toggle}>
          <i className="far fa-times fa-1x"></i>
        </button>
          {form}
      </Col>
      </Row>
      </ModalBody>
    </Modal>
    </Container>
  )
}


export default PopupModal