import React, { Component } from "react";
import PropTypes from "prop-types";
import { getColor, mapCounterNumbers } from "../utils/universalHelperFunctions";
import "../styles/numbers-banner.css";
import CountUp from 'react-countup';
import VisibilitySensor from 'react-visibility-sensor';

class NumbersBanner extends Component {
  constructor(props) {
    super(props);
    this.numbersBannerPrimaryData = this.props.input.primary;
    this.numberBannerItems = mapCounterNumbers(this.props.input.items);
    this.state = {};
  }

  render() {
    let id = "";
    if (this.numbersBannerPrimaryData.hasOwnProperty("breadcrumb_id")) {
      id = this.numbersBannerPrimaryData.breadcrumb_id.text;
    }

    return (
      <div className="number_banner_container py-5" style={{ backgroundColor: this.numbersBannerPrimaryData.number_background_color ? getColor(this.numbersBannerPrimaryData.number_background_color) : '#3498db' }} id={id}>
        <div className="container">
          <div className="row align-items-center justify-content-center">
            {this.numbersBannerPrimaryData.number_banner_heading && this.numbersBannerPrimaryData.number_banner_heading.text && (
              <div className="col-12 text-center mb-4">
                <h4 className="number-banner-title">{this.numbersBannerPrimaryData.number_banner_heading.text}</h4>
              </div>
            )}
            {this.numberBannerItems.map((item, i) => (
              <div key={i} className={`number-banner-item col-6 ${this.numberBannerItems.length <= 3 ? 'col-md-4' : 'col-md-3'} py-2 mb-2 mb-md-0`}>
                <p className="number-banner-item-value">
                  {/* {item.number_value.text} */}
                  <CountUp
                    end={parseInt(item.value)}
                    redraw={true}
                    suffix={item.suffix}
                    duration={1}>
                    {({ countUpRef, start }) => (
                      <VisibilitySensor onChange={start} delayedCall>
                        <span ref={countUpRef} />
                      </VisibilitySensor>
                    )}
                  </CountUp>
                  {/* <CountUp start={0} end={item.number_value.text} /> */}
                </p>
                <p className="number_banner_item_key">{item.label}</p>

              </div>

            ))}
          </div>
        </div>
      </div>

    );
  }
}
export default NumbersBanner;

NumbersBanner.propTypes = {
  input: PropTypes.object
};
