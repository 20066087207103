import { Component } from "react";
import * as React from 'react';
import "../styles/pageWithDynamicLinks.css";
import VideoOverlay from "./VideoOverlay";
class PageWithDynamicLinks extends Component {
  constructor(props) {
    super(props);
    this.pageWithDynamicLinksData = props.input;
    this.linksData = props.pagesLinksData;
    this.dynamicLinksData = props.dynamicLinks;
    this.state = {
      isMobileView: false
    }
    this.onResize = this.onResize.bind(this);
    this.scrollRef = React.createRef();
  }
  componentDidMount() {
    // window.addEventListener("scroll", this.handleScroll.bind(this));
    // this.setState({ breadcrumbTop: this.scrollRef.current.offsetTop });

    if (typeof window !== 'undefined') {
      window.addEventListener('resize', (e) => { this.onResize(); });
      if (window.innerWidth < 768) {
        this.setState({ isMobileView: true });
      }
    }
  }
  handleScroll(event) {
    let isStickyTopFlag = false;

    if (window.pageYOffset + 65 > this.state.breadcrumbTop - 62) {
      isStickyTopFlag = true;
    }

    this.setState({
      isStickyTop: isStickyTopFlag
    });
  }


  onResize() {
    if (typeof window !== 'undefined') {
      this.setState({ isMobileView: window.innerWidth < 768 ? true : false });
    }
  }

  redirectClick = (e, name) => {
    let sliceList = document.getElementById('slice-container').children;
    for (let i = 0; i < sliceList.length; i++) {
      if (sliceList[i].getAttribute('id') && sliceList[i].getAttribute('id').indexOf(name) >= 0) {
        let scrollTop = sliceList[i].offsetTop - 100;
        window.scroll({
          top: scrollTop, left: 0, behavior: 'smooth'
        });
        return;
      }
    }
  }


  render() {

    let videoDetails = this.pageWithDynamicLinksData.primary.video;
    let pageHeaderNormal = this.pageWithDynamicLinksData.primary.page_header_normal_text.text;
    let pageHeaderHighlightText = this.pageWithDynamicLinksData.primary.page_header_highlight_text.text;
    let description = this.pageWithDynamicLinksData.primary.page_description.html;
    let videolink = { url: this.pageWithDynamicLinksData.primary.adobe_link.url };
    let videothumbnail = { url: "https://i.vimeocdn.com/video/904157859?mw=700&mh=393&q=70" }
    let expertise = this.pageWithDynamicLinksData.primary.expertise.text;
    let dynamiclinks = (this.dynamicLinksData.map((ele, index) => {
      return <div className="expertise-blocks" key={ele.primary.breadcrumb_id.text}><a onClick={e => this.redirectClick(e, ele.primary.breadcrumb_id.text)} className="expertise-options">
        {ele.primary.breadcrumb_id.text}</a> {index !== this.dynamicLinksData.length - 1 && <span style={{ color: "#1783FF", marginRight: "15px", marginLeft: "10px", fontSize: "16px", fontWeight: "bold" }}> |  </span>}</div>
    }));

    const getColor = (elementColor) => {
      if (elementColor.indexOf('~') > -1) {
        elementColor = elementColor.slice(elementColor.indexOf('~') + 1).trim();
        elementColor = elementColor.slice(elementColor.indexOf('[') + 1, elementColor.indexOf(']'));
      }
      return elementColor;
    };
    const { pageId = '' } = this.props 
    return (

      <div className={`${pageId}`}>
        <div className="heading-section-container" >
          {!!pageHeaderNormal ? <div>
            <h2 className="header-font why-do-busines container " >{pageHeaderNormal} <span className="header-font page-heading-adobe">{pageHeaderHighlightText}</span></h2>
          </div> : null}
          {!this.state.isMobileView &&
            <React.Fragment><div >
              {/* <div className={`main-spacing-expertise ${this.state.isStickyTop ? 'sticky-link' : ''}`}  ref={this.scrollRef}>
        
        {dynamiclinks}
    </div> */}
            </div>
              <div className="container ">
                <div className="col-12">
                  <div className="header-description-top" dangerouslySetInnerHTML={{
                    __html: description

                  }} />


                  <div className={`hero-overlay-video-holder  col-12  overlay-spacing adding-padding`}>
                    <VideoOverlay iconclass='far fa-play-circle' color={getColor('Neon Blue ~ [#3742FA]')} videoThumbnail={videothumbnail} videoLink={videolink} videoDetails={videoDetails} pagesLinksData={this.linksData} />
                  </div>
                </div>

              </div></React.Fragment>}
          {this.state.isMobileView &&
            <div className="mobile-adobe-padding">

              <div style={{ textAlign: 'left' }}>
                {dynamiclinks}
              </div>
              <div>
                <div className="header-description" dangerouslySetInnerHTML={{
                  __html: description

                }} />


                <div className={`hero-overlay-video-holder  col-12  no-padding overlay-spacing`}>
                  <VideoOverlay iconclass='far fa-play-circle' color={getColor('Neon Blue ~ [#3742FA]')} videoThumbnail={videothumbnail} videoLink={videolink} videoDetails={videoDetails} pagesLinksData={this.linksData} />
                </div>
              </div>
            </div>
          }

        </div>


      </div>

    );

  }

}

export default PageWithDynamicLinks;