import React from "react";
import authorImg from "../../static/img_avatar.png";

function BlogAuthor({ author }) {
  const {
    author_image = {},
    author_name = {},
    designation = {},
    banner_image = {},
    banner_link = {},
  } = author || {};
  const { url: authorImage, alt: authorAlt  } = author_image;
  const { url: bannerImage, alt: bannerAlt  } = banner_image;
  const { text: authorName } = author_name;
  const { text: desinationLabel } = designation;
  if(!authorImage && !authorName && !desinationLabel && !bannerImage) {
    return <></>
  }
  return (
    <div className="ui-author">
      {(authorImage) && <img
        src={authorImage}
        alt={authorAlt}
        style={{ width: "60px", height: "60px", borderRadius: "50%" }}
      />}
      {(authorName) && <p style={{ fontSize: "14px", marginBottom: "0", marginTop: "10px" }}>
        {authorName}
      </p>}
      {(desinationLabel) && <p style={{ fontSize: "14px", marginBottom: "0", color: "#7F8C8D" }}>
        {desinationLabel}
      </p>
      }
      {(bannerImage) &&<br/>}
      {(bannerImage) && <a href={author.banner_link ? author.banner_link.url : ""} hrefLang="en">
      <img
        src={bannerImage}
        alt={bannerAlt}
        style={{ width: "200px", height: "300px"}}
      />
      </a>}
    </div>
  );
}

export default BlogAuthor;