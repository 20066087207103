import React, { Component } from "react";
import "../styles/call-to-action-strip.css";
import {
  AvForm,
  AvField
} from "availity-reactstrap-validation";
import axios from "axios";
import {
  Col,
  Row,
  Button,
  FormGroup,
  Container
} from "reactstrap";
import { PropTypes } from "prop-types";
import { createUrl, getColor, checkCorporateEmail } from "../utils/universalHelperFunctions";

class CallToActionStrip extends Component {
  constructor(props) {
    super(props);
    this.linksData = props.pagesLinksData;
    this.CareerBannerStripData = props.input;
    this.pageOverViewData = this.props.pageOverViewData;
    this.pageHeaderBodyData = this.props.pageHeaderBodyData;
    this.pageHeroData = this.props.pageHeroData;
    this.handleValidSubmit = this.handleValidSubmit.bind(this);
    this.handleInvalidSubmit = this.handleInvalidSubmit.bind(this);
    this.hideFormSection = this.hideFormSection.bind(this);
    this.submitFormData = this.submitFormData.bind(this);
    this.validateEmailField = this.validateEmailField.bind(this);
    this.createEmailDataObject = this.createEmailDataObject.bind(this);
    this.domainName;

    this.state = {
      showBannerSection: true,
      showForm: true,
      showThanks: false,
      showSpinner: false,
      serverError: '',
      emailValidationError: '',
      formId: '',
      hideSubmit: false,
      isMobileView: false
    };

    if (this.CareerBannerStripData.primary.hasOwnProperty("node_api_url") && this.CareerBannerStripData.primary.node_api_url.text) {
      this.formAPIUrl = this.CareerBannerStripData.primary.node_api_url.text;
    }
  }
  onResize() {
    if (typeof window !== 'undefined') {
      this.setState({ isMobileView: window.innerWidth < 768 ? true : false });
    }
  }
  componentDidMount() {
    let pathName = window.location.pathname ? window.location.pathname.replace(/\//g, ' ') : '';
    pathName = pathName.trim();
    pathName = pathName.split(' ');
    if (pathName.length && pathName[0]) {
      this.setState({ formId: `${pathName[0].toLocaleLowerCase()}` });
    }
    window.addEventListener('resize', (e) => { this.onResize(); });
    if (typeof window !== 'undefined') {
      this.setState({ isMobileView: window.innerWidth < 768 ? true : false });
    }
  }

  showFormSection = () => {
    this.setState({ showForm: true });
  };

  hideFormSection = () => {
    this.setState({ showForm: false, emailValidationError: '' });
    this.setState({ showSpinner: false, hideSubmit: false });
    this.refs.form.reset();
  };

  showThanksSection = () => {
    this.setState({ showThanks: true, showBannerSection: false });
    setTimeout(() => {
      this.hideThanksSection();
      this.showFormSection();
    }, 10000);
  };

  hideThanksSection = () => {
    this.setState({ showThanks: false, showBannerSection: true });
  };

  generateKeyForEmailData(element) {
    let pos = element.indexOf("_");
    while (pos > -1) {
      element = element.slice(0, pos + 1) + element.charAt(pos + 1).toUpperCase() + element.slice(pos + 2);
      pos = element.indexOf('_', pos + 1);
    }
    element = element.replace(new RegExp('_', 'g'), '');

    return element;
  }

  createEmailDataObject(values) {
    let emailData = [];

    if (this.props.emailTemplateData && this.props.emailTemplateData.length > 0) {
      if (this.CareerBannerStripData.primary.form_identifier) {
        this.props.emailTemplateData.forEach(template => {
          if (this.CareerBannerStripData.primary.form_identifier && template.primary.form_identifier_for_teamplate === this.CareerBannerStripData.primary.form_identifier) {
            this.emailTemplate = template.items;
          }
        });
      } else {
        this.emailTemplate = this.props.emailTemplateData[0].items;
      }
    }
    if (this.emailTemplate) {
      this.emailTemplate.forEach(element => {

        if (element.email_template_link && element.email_template_link.document.length > 0) {
          let emailObj = {};
          let emailTemp = element.email_template_link.document[0].data;
          let signature = emailTemp.signature && emailTemp.signature.raw.length > 1 ? emailTemp.signature.html : emailTemp.signature.raw[0].text;
          let content = emailTemp.email_content ? emailTemp.email_content.html ? emailTemp.email_content.html : emailTemp.email_content.raw[0].text : '';
          let salutation = emailTemp.salutation ? emailTemp.salutation.html ? emailTemp.salutation.html : emailTemp.salutation.raw[0].text : '';

          for (let key in values) {
            let expression = '\{' + key + '\}';
            let regEx = new RegExp(expression, 'gi');
            if (content.match(regEx) != null) {
              content = content.replace(regEx, values[key]);
            }
            if (salutation.match(regEx) != null) {
              salutation = salutation.replace(regEx, values[key]);
            }
            if (signature.match(regEx) != null) {
              signature = signature.replace(regEx, values[key]);
            }
          }

          if (this.pageOverViewData) {
            for (let element in this.pageOverViewData.primary) {
              let key = this.generateKeyForEmailData(element);

              let expression = '\{' + key + '\}';
              let regEx = new RegExp(expression, 'gi');
              if (content.match(regEx) != null) {
                content = content.replace(regEx, this.pageOverViewData.primary[element].text);
              }
              if (salutation.match(regEx) != null) {
                salutation = salutation.replace(regEx, this.pageOverViewData.primary[element].text);
              }
              if (signature.match(regEx) != null) {
                signature = signature.replace(regEx, this.pageOverViewData.primary[element].text);
              }
            }
          }

          if (this.pageHeroData) {
            this.pageHeroData.items.forEach(item => {
              for (let element in item) {
                let key = this.generateKeyForEmailData(element);
                let expression = '\{' + key + '\}';
                let regEx = new RegExp(expression, 'gi');
                if (content.match(regEx) != null) {
                  content = content.replace(regEx, item[element].text);
                }
                if (salutation.match(regEx) != null) {
                  salutation = salutation.replace(regEx, item[element].text);
                }
                if (signature.match(regEx) != null) {
                  signature = signature.replace(regEx, item[element].text);
                }
              }
            });
          }

          if (this.pageHeaderBodyData) {
            for (let element in this.pageHeaderBodyData.primary) {
              let key = this.generateKeyForEmailData(element);

              let expression = '\{' + key + '\}';
              let regEx = new RegExp(expression, 'gi');
              if (content.match(regEx) != null) {
                content = content.replace(regEx, this.pageHeaderBodyData.primary[element].text);
              }
              if (salutation.match(regEx) != null) {
                salutation = salutation.replace(regEx, this.pageHeaderBodyData.primary[element].text);
              }
              if (signature.match(regEx) != null) {
                signature = signature.replace(regEx, this.pageHeaderBodyData.primary[element].text);
              }
            }
          }

          if (element.description && element.description.html) {
            let descriptionList = element.description.html.split(new RegExp('<p>\\s*@+\\*+@+\\s*<\/p>', 'g'));
            if (descriptionList) {
              let descriptionObj = {}
              descriptionList.forEach(element => {
                let key = element.match(new RegExp('<p>.*(::)\s*<\/p>'));
                if (key) {
                  key = key[0].replace(new RegExp('\s*<\/?[^>]+(>|$)\s*', 'g'), '').replace('::', '').trim();
                  descriptionObj[key] = element.split(new RegExp('<p>.*(::)\s*<\/p>'))[2];
                } else if (element.match(new RegExp('<p>.*(::).*<\/p>'))) {
                  key = element.match(new RegExp('<p>.*(::).*<\/p>'));
                  key = key[0].replace(new RegExp('\s*<\/?[^>]+(>|$)\s*', 'g'), '').split('::');
                  if (key) {
                    descriptionObj[key[0]] = key[1];
                  }
                } else {
                  return;
                }
              });
              if (descriptionObj && Object.entries(descriptionObj).length > 0) {
                for (let key in descriptionObj) {
                  let expression = '\{' + key + '\}';
                  let regEx = new RegExp(expression, 'gi');
                  if (content.match(regEx) != null) {
                    content = content.replace(regEx, descriptionObj[key]);
                  }
                  if (salutation.match(regEx) != null) {
                    salutation = salutation.replace(regEx, descriptionObj[key]);
                  }
                  if (signature.match(regEx) != null) {
                    signature = signature.replace(regEx, descriptionObj[key]);
                  }
                }
              }
            }
          }

          if (emailTemp.body && emailTemp.body.length > 0) {
            emailTemp.body.forEach(element => {
              if (element.slice_type === 'recipient_list' && element.items && element.items.length) {
                element.items.forEach(element => {
                  let expression = `\{${element.team_name.text}\}`;
                  let regEx = new RegExp(expression, 'gi');

                  if (emailTemp.recipient && emailTemp.recipient.text && emailTemp.recipient.text.match(regEx) != null) {
                    emailTemp.recipient.text = emailTemp.recipient.text.replace(regEx, element.recipients.text);
                  }
                  if (emailTemp.cc_recipient && emailTemp.cc_recipient.text && emailTemp.cc_recipient.text.match(regEx) != null) {
                    emailTemp.cc_recipient.text = emailTemp.cc_recipient.text.replace(regEx, element.recipients.text);
                  }
                  if (emailTemp.bcc_recipient && emailTemp.bcc_recipient.text && emailTemp.bcc_recipient.text.match(regEx) != null) {
                    emailTemp.bcc_recipient.text = emailTemp.bcc_recipient.text.replace(regEx, element.recipients.text);
                  }
                  if (emailTemp.from_recipient && emailTemp.from_recipient.text && emailTemp.from_recipient.text.match(regEx) != null) {
                    emailTemp.from_recipient.text = emailTemp.from_recipient.text.replace(regEx, element.recipients.text);
                  }
                });
              } else if (element.slice_type === 'default_key_value' && element.items && element.items.length) {
                element.items.forEach(element => {
                  if (element.key.text) {
                    let expression = '\{' + element.key.text + '\}';
                    let regEx = new RegExp(expression, 'gi');
                    let value = element.default_value.text || element.multiline_default_value.html;
                    if (value) {
                      if (content.match(regEx) != null) {
                        content = content.replace(regEx, value);
                      }
                      if (salutation.match(regEx) != null) {
                        salutation = salutation.replace(regEx, value);
                      }
                      if (signature.match(regEx) != null) {
                        signature = signature.replace(regEx, value);
                      }
                    }

                  }
                });
              }
            });
          }
          if (process.env.GATSBY_ACTIVE_ENV && process.env.GATSBY_ACTIVE_ENV === "staging") {
            if (element.user_type === "User") {
              emailObj.to = values.workEmail || values.emailAddress;
            } else {
              if (values.workEmail && this.domainName === "gspann.com") {
                emailObj.to = values.workEmail;
              } else if (values.emailAddress && values.emailAddress.split('@')[1] === 'gspann.com') {
                emailObj.to = values.emailAddress;
              } else {
                emailObj.to = emailTemp.recipient && emailTemp.recipient.text ? emailTemp.recipient.text : '';
              }
            }
          } else {
            if (element.user_type === "User") {
              emailObj.to = emailTemp.recipient && emailTemp.recipient.text ? emailTemp.recipient.text : values.workEmail || values.emailAddress;
            } else {
              emailObj.to = emailTemp.recipient && emailTemp.recipient.text ? emailTemp.recipient.text : '';
            }
          }
          salutation = !salutation ? "Hello Visitor" : salutation;

          if (emailTemp.cc_recipient && emailTemp.cc_recipient.text) {
            emailObj.cc = emailTemp.cc_recipient.text;
          }

          if (emailTemp.bcc_recipient && emailTemp.bcc_recipient.text) {
            emailObj.bcc = emailTemp.bcc_recipient.text;
          }

          if (emailTemp.from_recipient && emailTemp.from_recipient.text) {
            emailObj.from = emailTemp.from_recipient.text;
          }

          if (emailTemp.subject.text) {
            emailObj.sub = emailTemp.subject.text;
          }

          emailObj.emailTempStr = salutation.concat(content, signature);
          emailObj.userType = element.user_type;

          emailData[emailData.length] = emailObj;
        }
      });
    }

    return emailData;
  }

  submitFormData(values) {
    this.setState({
      data: values
    });
    let errorMsg = this.CareerBannerStripData.primary.failure_message.text;
    let requestObject = {
      formData: [],
    }

    let pathName = window.location.pathname ? window.location.pathname.replace(/\//g, ' ') : '';
    pathName = pathName.trim();
    pathName = pathName.split(' ');

    if (pathName.length > 2) {
      requestObject.categoryName = pathName[0].toUpperCase();
      requestObject.subCategoryName = pathName[1].toUpperCase();
    } else if (pathName.length == 2) {
      requestObject.categoryName = pathName[0].toUpperCase();
    } else if (pathName.length < 2) {
      requestObject.categoryName = this.props.pageId.toUpperCase();
    }
    requestObject.sourceId = this.props.pageId;

    let formObj = {};
    formObj.event = this.CareerBannerStripData.primary.event.text || 'test';
    formObj.eventData = this.CareerBannerStripData.primary.event_data.text || 'test';

    for (let key in values) {
      if (key === "firstName" || key === "lastName" || key === "workEmail" || key === "emailAddress") {
        if (key === "workEmail" || key === "emailAddress") {
          requestObject.email = values[key];
        } else {
          requestObject[key] = values[key];
        }
      } else {
        formObj[key] = values[key];
      }
    }

    if (this.props.emailTemplateData && this.props.emailTemplateData.length > 0) {
      requestObject.emailData = this.createEmailDataObject(values);
    }

    requestObject.formData[0] = formObj;
    const adobeSingleTextBox = this.CareerBannerStripData.primary.adobe_single;
    if (adobeSingleTextBox) {
      requestObject.subCategoryName = 'Adobe'
    }

    if (this.formAPIUrl) {
      if (this.formAPIUrl.indexOf(process.env.GATSBY_API_URL) < 0) {
        this.formAPIUrl = this.formAPIUrl.includes('/', 0) ? `${process.env.GATSBY_API_URL}${this.formAPIUrl}` : `${process.env.GATSBY_API_URL}/${this.formAPIUrl}`;
      }
    } else {
      if (requestObject.categoryName === "SERVICES") {
        this.formAPIUrl = `${process.env.GATSBY_API_URL}/v1/Forms/services`;
      } else if (requestObject.categoryName === "INDUSTRIES") {
        this.formAPIUrl = `${process.env.GATSBY_API_URL}/v1/Forms/industries`;
      } else if (adobeSingleTextBox) {
        this.formAPIUrl = `${process.env.GATSBY_API_URL}/v1/Forms/services`;
      }
    }

    axios.post(this.formAPIUrl, requestObject)
      .then(res => {
        this.hideFormSection();
        this.showThanksSection();
      })
      .catch(err => {
        console.log(err.response);
        if (err.response == undefined) {
          this.hideFormSection();
          this.showThanksSection();
        } else {
          this.setState({ showSpinner: false, hideSubmit: true });
          this.setState({
            serverError: errorMsg
          });
          setTimeout(() => {
            this.setState({
              serverError: "",
              hideSubmit: false,
            });
            this.refs.form.reset();
            this.setState({ hideSubmit: false });
          }, 500);
        }
      });
  }
  handleValidSubmit(event, values) {
    if (values.workEmail) {
      this.setState({ showSpinner: true, hideSubmit: true });
      checkCorporateEmail(values.workEmail)
        .then(response => {
          if (response.valid || this.props.pageId === 'freshers') {
            values.pageTitle = this.pageOverViewData.primary.page_title.text;
            this.domainName = values.workEmail.substring(values.workEmail.lastIndexOf("@") + 1)
            this.setState({
              data: values
            });
            values.PageUrl = window.location.href;
            this.submitFormData(values);
          } else {
            this.setState({
              showSpinner: false,
              hideSubmit: false,
              emailValidationError: 'Please enter a valid corporate email.'
            });
          }
        })
        .catch(err => {
          this.setState({ showSpinner: false, hideSubmit: true, emailValidationError: err });
          setTimeout(() => {
            this.setState({
              emailValidationError: "",
              hideSubmit: false
            });
            this.refs.form.reset();
          }, 500);
        });
          if (this.props.pageId === 'freshers') {
            values.pageTitle = this.pageOverViewData.primary.page_title.text;
            this.domainName = values.workEmail.substring(values.workEmail.lastIndexOf("@") + 1)
            this.setState({
              data: values
            });
            values.PageUrl = window.location.href;
            this.submitFormData(values);
          }
      // this.setState({ showSpinner: false });
    }
  }

  handleInvalidSubmit(event, errors, values) {
    console.log(errors, values);
  }

  validateEmailField(event) {
    if (this.state.emailValidationError !== '') {
      this.setState({ emailValidationError: '' });
    }
  }

  render() {
    const bannerProps = this.CareerBannerStripData.primary;
    const bannerBackground = bannerProps.banner_job_background_color ? getColor(bannerProps.banner_job_background_color) : "#2bcbba";
    const btnBackground = bannerProps.job_search_button_color ? getColor(bannerProps.job_search_button_color) : "#007aff";
    const bannerFont = bannerProps.banner_job_font_color ? getColor(bannerProps.banner_job_font_color) : "#F6F6F6";
    const btnFont = bannerProps.banner_button_font_color ? getColor(bannerProps.banner_button_font_color) : "#fff";
    const isFormCta = bannerProps.cta_title.text || false;
    const submitBtnClass = "get-in-touch-tag-manager-btn";
    const successMsg = bannerProps.success_message.text;
    const adobeSingleTextBox = true; // bannerProps.adobe_single

    let displayMsgContainer = '';
    if (this.state.showThanks && isFormCta) {
      displayMsgContainer = (
        <div className='thank-you-message msg-container'>
          <div style={{ padding: "5px 0px 40px", margin: "0px auto" }}>
            <span className="thanku-text" style={{ color: "#ffffff" }}>
              {successMsg}
            </span>
          </div>
        </div>
      );
    }

    let serverErrorContainer = '';
    if (this.state.serverError) {
      serverErrorContainer = (
        <div className="invalid-server-error">{this.state.serverError}</div>
      );
    }

    const { showBannerSection, showForm, showThanks } = this.state;

    let id = "";
    if (bannerProps.hasOwnProperty("breadcrumb_id")) {
      id = bannerProps.breadcrumb_id.text;
    }

    let emailValidationError;
    if (this.state.emailValidationError) {
      emailValidationError = (
        <div className="invalid-feedback">{this.state.emailValidationError}</div>
      )
    }

    return (
      <div id={id}>
        <div
          className={`banner-container ${this.state.formId}-container`}
          style={{
            backgroundColor: bannerBackground,
            color: bannerFont
          }}
        >
          <h2
            className="cta-title"
            style={{ display: isFormCta ? "block" : "none", textTransform: "capitalize", color: bannerFont }}
          >
            {bannerProps.cta_title && bannerProps.cta_title.text && bannerProps.cta_title.text.toLowerCase()}
          </h2>
          <p
            className="banner-text"
            style={{ display: isFormCta ? "none" : "inline-block", color: bannerFont }}
          >
            {bannerProps.banner_job_body.text}
          </p>
          <p
            className="banner-text-form-cta"
            style={{ display: (isFormCta) ? "inline-block" : "none", color: bannerFont }}
          >
            {bannerProps.banner_job_body.text}
          </p>
          <div
            className="banner-btn-wrapper"
            style={{
              backgroundColor: btnBackground,
              display: isFormCta ? "none" : "inline-block"
            }}
          >
            {bannerProps.job_button_text_link && bannerProps.job_button_text_link.url ?
              (<a
                href={createUrl(bannerProps.job_button_text_link.url, this.linksData)}
                className="banner-btn-link"
                style={{ color: isFormCta ? bannerBackground : "#FFFFFF" }}
                hrefLang="en"

              >
                {bannerProps.job_button_text.text}
              </a>)
              :
              (<a
                href="#"
                onClick={e => e.preventDefault()}
                className="banner-btn-link"
                style={{ color: isFormCta ? bannerBackground : "#FFFFFF" }}
                hrefLang="en"
              ></a>)
            }
          </div>
          <Container className={`d-block`} style={{ overflow: 'auto' }}>
            <AvForm
              ref="form"
              className={`form-container get-in-touch-form-container ${showForm ? "d-flex" : "d-none"}`}
              onValidSubmit={this.handleValidSubmit}
              onInvalidSubmit={this.handleInvalidSubmit}
            >
              {adobeSingleTextBox == null && <Row form>
                <Col xs={12} md={6}>
                  <AvField
                    type="text"
                    name="firstName"
                    id="fName"
                    placeholder="First name*"
                    bsSize="lg"
                    className="all-form-input"
                    validate={{
                      required: { value: true, errorMessage: `Please enter First name.` },
                      pattern: { value: "^[A-Z a-z]*$", errorMessage: `Only letters are allowed in the First name.` },
                    }}
                  />

                </Col>
                <Col xs={12} md={6} className={`${this.state.emailValidationError ? "email-input-row" : ''}`}>
                  <AvField
                    type="email"
                    name="workEmail"
                    id="email"
                    placeholder="Work Email*"
                    bsSize="lg"
                    className={`all-form-input ${this.state.emailValidationError ? 'email-input-field' : ''}`}
                    validate={{
                      required: { value: true, errorMessage: 'Field can’t be left blank. Please enter a corporate email.' },
                      email: { value: true, errorMessage: "This is not a valid email format. Please enter a valid corporate email." },
                    }}
                    onChange={this.validateEmailField}
                  />
                  {emailValidationError}
                </Col>
              </Row>}
              {adobeSingleTextBox &&
                <Row form>
                  {/* {!this.state.isMobileView && <Col xs={12} md={1}>&nbsp;</Col>} */}
                  <Col xs={12} md={6} className={`${this.state.emailValidationError ? "email-input-row" : ''}`}>
                    <AvField
                      type="email"
                      name="workEmail"
                      id="email"
                      placeholder="Email"
                      bsSize="lg"
                      className={`all-form-input ${this.state.emailValidationError ? 'email-input-field' : ''}`}
                      validate={{
                        required: { value: true, errorMessage: 'Field can’t be left blank. Please enter a corporate email.' },
                        email: { value: true, errorMessage: "This is not a valid email format. Please enter a valid corporate email." },
                      }}
                      onChange={this.validateEmailField}
                    />
                    {emailValidationError}
                    </Col>
                    <Col Col xs={12} md={6}>

                    {adobeSingleTextBox && <div className={`${this.state.showSpinner ? "overlay-container" : ""} button-spacing-submit text-center`}>
                      <Button
                        color="primary"
                        className={`submit-btn ${submitBtnClass} `}
                        style={{
                          display: `${this.state.hideSubmit ? 'none' : 'inline-block'}`,
                          color: btnFont,
                          backgroundColor: btnBackground,
                        }}
                      >
                        Submit
                      </Button>
                      <div
                        className="detail-page-loading"
                        onClick={e => e.preventDefault()}
                        style={{ display: this.state.showSpinner ? 'flex' : 'none', height: '40px' }}
                      >
                        <div className="spinner" />
                      </div>
                    </div>}
                  </Col>

                </Row>}
              {serverErrorContainer}
              {adobeSingleTextBox == null && <div className={this.state.showSpinner ? "overlay-container" : ""}>
                <Button
                  color="primary"
                  className={`submit-btn ${submitBtnClass}`}
                  style={{
                    display: `${this.state.hideSubmit ? 'none' : 'inline-block'}`,
                    color: btnFont,
                    backgroundColor: btnBackground
                  }}
                >
                  Submit
                </Button>
                <div
                  className="detail-page-loading"
                  onClick={e => e.preventDefault()}
                  style={{ display: this.state.showSpinner ? 'flex' : 'none', height: '40px' }}
                >
                  <div className="spinner" />
                </div>
              </div>}
            </AvForm>
          </Container>
          <div className="msg-wrapper">
            {displayMsgContainer}
          </div>
        </div>
      </div>
    );
  }
}
export default CallToActionStrip;

CallToActionStrip.propTypes = {
  input: PropTypes.object.isRequired,
  pagesLinksData: PropTypes.array
};
