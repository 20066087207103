import React, { Component } from "react";
import { Container } from 'reactstrap';
import "../styles/policies.css";

class Policies extends Component {
  constructor(props) {
    super(props);
    this.policyContent = props.policyData.primary;
    this.state = {};
  }
  render() {
    return (
      <Container>
        <div className="policy-container">
          <h1 className="policy-title">{this.policyContent.content_title.text}</h1>
          <div dangerouslySetInnerHTML={{
            __html: this.policyContent.content_body.text
          }}>
          </div>
        </div>
      </Container>
    );
  }
}
export default Policies;
