import React, { Component } from 'react';
import '../styles/card-design.css';
import { createUrl, getColor } from "../utils/universalHelperFunctions";

class RelatedResource extends Component {
  constructor(props) {
    super(props);
    this.cardData = this.props.cardData;
    this.linksData = props.pagesLinksData;
    //this.handleResourceTagClick = this.handleResourceTagClick.bind(this);
  }

  render() {
 
    const { related_resource_background_color, document, url } = this.props;
    const { data: { body, page_path, page_url, resource_display_name } } = document[0];
    console.log('related resource: ', document[0]);

    const pageOverview = body.find(item => item.__typename === "PrismicResourceBodyPageOverview");
    const resourceOverview = body.find(item => item.__typename === "PrismicResourceBodyResourceOverview");
    //const {resource_display_name} = data[0]
    return (
      <div className='col-12 col-md-12'>
        <div className='no-gutters row' style={{ backgroundColor: related_resource_background_color }}>
          <div className='col-3 colo-md-3'>
            <img src={pageOverview?.primary?.page_thumbnail?.url} loading="lazy" alt="" style={{ height: '211px', padding: '24px 24px 24px 24px' }}></img>
          </div>
          <div className='col-9 col-md-9 relatedResource'>
            <div className='no-gutters row' style={{ paddingTop: '24px', paddingBottom: '24px' }}>
              <div className='col-9'>
                <span className='resource-type'>
                  {`RELATED ${resourceOverview?.primary?.resource_type.toUpperCase()}`}
                </span>
              </div>
              <div className='col-9'>
                <span className='resource-title'>{pageOverview?.primary?.page_title?.text}</span>
              </div>
              <div className='col-9'>
                <a href={`/${page_path?.text}/${page_url?.text ? page_url.text : resource_display_name?.text.split(" ").join("-").toLowerCase()}`}>
                  <span className='read-now'>
                    Read Now
                  </span>
                </a>
              </div>
            </div>

          </div>
        </div>
      </div>
    );
  }
}
export default RelatedResource;