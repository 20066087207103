import { Component } from "react";
import PropTypes from 'prop-types';
import "../styles/pageWithCorousel.css";
import "../styles/walk-through.css";
import VideoOverlay from "./VideoOverlay";
import { getColor, createUrl, checkCorporateEmail } from "../utils/universalHelperFunctions";

class WalkThrough extends Component {
  constructor(props) {
    super(props);
    this.walkThroughData = props.input;
    this.linksData = props.pagesLinksData;
  }

  render() {

    let pageHeader = this.walkThroughData.primary.page_header.text;
    let description = this.walkThroughData.primary.page_description.html;
    let carouselCardElements = [];
    if (this.walkThroughData.items && this.walkThroughData.items.length > 0) {
      this.walkThroughData.items.forEach((element, key) => {
        let cardImageElement =
          (element.walk_through_video && element.walk_through_video.embed_url) ? (
            <div className={`hero-overlay-video-holder walkthrough-video  no-padding overlay-spacing`}>
              <VideoOverlay iconclass={element.walk_through_video_icon_class &&
                element.walk_through_video_icon_class.text ?
                element.walk_through_video_icon_class.text : 'far fa-play-circle'}
                color={getColor(element.walk_through_video_icon_color)}
                videoLink={element.walk_through_video_link} videoDetails={element.video_description.text}
                pagesLinksData={this.linksData} />
            </div>
          ) : ''


        let descriptionElement = (
          <div> <p className="walkthroughdescription">{element.video_description.text}</p>

          </div>
        )

        carouselCardElements.push(
          <div className=" walk-image">
            <div className='fresher-1-2-years ' style={{ color: element.image_color }}>{element.image_text.text}</div>
            <div className="line-3 " style={{ border: `2px solid ${element.image_color}` }}></div>
            <div className="arrow-right-line-3" style={{ borderLeft: `14px solid ${element.image_color}` }}></div>

            <div className="walkthrough-cards" key={key}>
              {cardImageElement}
              {descriptionElement}
            </div>
          </div>
        );
      });
    }

    return (
      <div className="walkthrough-container">
        <div className="container heading-section-container" id={pageHeader}>
          <div >
            <h2 className="page-heading">{pageHeader}</h2>
          </div>
          <div className="header-description" dangerouslySetInnerHTML={{
            __html: description

          }} />
          <div className="row">

          </div>

          <div className="add-display-figure row">
          </div>

          <div className="row">
            {carouselCardElements}
          </div>
        </div>

      </div>
    )

  }
}

export default WalkThrough;

WalkThrough.propTypes = {
  input: PropTypes.object,
  pagesLinksData: PropTypes.array
};
