import React, { Component } from "react";
import { Container, Row, Col } from "reactstrap";
import "../styles/job-position.css";

class JobFilteredList extends Component {
    render() {
        let data = this.props.input;
        return (
            <Container className="no-padding">
                <Row>
                    <Col md={12} className="filter-chips">
                        <Row className="display-chips">
                            {data.map((item, i) => (
                                <Col xs="auto" key={i} className="chip" onClick={() => this.props.actionRemove(item.value, item.key)}>
                                    {item.value}
                                    <button className="remove-chip">
                                        <i className="fas fa-times remove-chip-icon" aria-hidden="true" />
                                    </button>
                                </Col>
                            ))}
                        </Row>
                    </Col>
                </Row>
            </Container>
        );
    }
}

export default JobFilteredList;
