import React, { Component } from 'react';
import PropTypes, { array } from 'prop-types';
import { Container, Row, Col } from 'reactstrap';
import { getColor, createUrl, filterLocationBasedJobs, fetchUserLocation } from "../utils/universalHelperFunctions";
import '../styles/home-page-resource-component.css';
import '../styles/card-design.css';

class HomePageResouceComponent extends Component {
  constructor(props) {
    super(props);
    this.linksData = props.pagesLinksData;
    this.HomePageResouceComponentData = props.input;
    this.state = {
      data: [],
      dataUI: [],
      featuredJobs: [],
      isCareerFocused: false,
      isEventFocused: false,
      isFeaturedResourceFocused: false,
    };
    this.getLocationName = this.getLocationName.bind(this);
    this.handleResourceTagClick = this.handleResourceTagClick.bind(this);
    this.getHashUrl = this.getHashUrl.bind(this);
    this.sectionHeadingFontColor = this.props.input.eventsData.primary.section_heading_font_color ? getColor(this.props.input.eventsData.primary.section_heading_font_color) : '#fff';
    this.sectionSubheadingFontColor = this.props.input.eventsData.primary.section_subheading_font_color ? getColor(this.props.input.eventsData.primary.section_subheading_font_color) : '#e7eeef';
    this.sectionDescriptionFontColor = this.props.input.eventsData.primary.section_description_font_color ? getColor(this.props.input.eventsData.primary.section_description_font_color) : '#000';
    this.sectionBtnFontColor = this.props.input.eventsData.primary.section_button_font_color ? getColor(this.props.input.eventsData.primary.section_button_font_color) : '#313542';
    this.sectionBtnBackgroundColor = this.props.input.eventsData.primary.section_button_background_color ? getColor(this.props.input.eventsData.primary.section_button_background_color) : '#fff';
    this.careerBtnFontColor = this.props.input.eventsData.primary.career_button_font_color ? getColor(this.props.input.eventsData.primary.career_button_font_color) : '#e7eeef';
    this.careerBtnBackgroundColor = this.props.input.eventsData.primary.career_button_background_color ? getColor(this.props.input.eventsData.primary.career_button_background_color) : '#000';
    this.sectionBackground = this.props.input.eventsData.primary.resource_background_color ? getColor(this.props.input.eventsData.primary.resource_background_color) : '#fff';

    this.buttonStyleObj = {
      color: this.sectionBtnFontColor,
      background: this.sectionBtnBackgroundColor,
      borderColor: this.sectionBtnBackgroundColor
    }

    this.buttonHoverStyleObj = {
      color: this.sectionBtnBackgroundColor,
      background: this.sectionBtnFontColor,
      borderColor: this.sectionBtnBackgroundColor
    }
  }

  componentDidMount() {
    window.addEventListener('resize', (e) => { this.onResize(); });
    fetchUserLocation().then(() => {
      let data = filterLocationBasedJobs(this.props.input.jobListData);
      data && data.map((item, index) => {
        if (item.node.data.job_visible_on_homepage) {
          this.state.featuredJobs.push(item);
        }
      });
      this.setState({ data });
      this.setState({ dataUI: data });
    })

  }

  onResize() {
    this.setState({ "resized": true });
  }

  getHashUrl = () => {
    return localStorage.getItem('resourceTag') ? `#${localStorage.getItem("resourceFilter")}#${localStorage.getItem('resourceTag')}` : `#${localStorage.getItem("resourceFilter")}`;
  }

  handleResourceTagClick(e, linkData) {
    e.preventDefault();
    e.stopPropagation();
    localStorage.setItem('resourceTagFlag', 'true');
    localStorage.setItem('resourceTag', JSON.stringify(e.target.id));
    let flag = localStorage.getItem('resourceFlag');
    if (flag === null || flag === 'false') {
      localStorage.setItem('resourceFlag', 'false');
    } else {
      localStorage.setItem('resourceFlag', 'true');
    }
    // window.location.href = createUrl(linkData.resourceTags.primary.redirection_link ? linkData.resourceTags.primary.redirection_link.url: 'resources/all-resources', this.linksData);
    let firstPart = "resources/all-resources/";
    window.location.hash = this.getHashUrl();
    window.location.pathname = `${firstPart}`;
  }

  handleMouseEnter(eventName) {
    this.setState({ [eventName]: true });
  }

  handleMouseLeave(eventName) {
    this.setState({ [eventName]: false });
  }

  render() {
    if (this.HomePageResouceComponentData.sliceName === 'events') {
      return this.renderEventSlice();
    }
    if (this.HomePageResouceComponentData.sliceName === 'featuredResource') {
      return this.renderFeaturedResourceSlice();
    }
    if (this.HomePageResouceComponentData.sliceName === 'Careers') {
      return this.renderCareerSlice();
    }
  }

  handleEventBtnClick() {
    localStorage.setItem('resourceFlag', 'true');
    localStorage.setItem('resourceFilter', 'Event');
    window.location.href = "/resources/all-resources/#Events";
  }

  setBackgroundImage(imagePath) {
    var url;
    if (!url && typeof window !== 'undefined' && window.innerWidth <= 767 && imagePath.Tablet && imagePath.Tablet.url) {
      url = imagePath.Tablet.url;
    } else {
      url = imagePath.url;
    }

    return url;
  }


  // this will return the design of Event slice.
  renderEventSlice() {
    // console.log('eventCardData : ', this.HomePageResouceComponentData.eventCardData)
    const upcomingEvent = this.HomePageResouceComponentData.eventCardData.filter(item => item.event_type === 'Upcoming');
    const pastEvent = this.HomePageResouceComponentData.eventCardData.filter(item => item.event_type === 'Past');
    return (
      <div className="slice-width-global events-container-component container">
        {upcomingEvent.length > 0 && <div className='row'>
          <div className='col-md-12'>
            <h3 className="careers-sub-heading" style={{ paddingBottom: '45px', paddingTop: '45px' }}>
              Where to Meet Us
            </h3>
          </div>

        </div>
        }
        <div className="row">
          {upcomingEvent.map((item, index) => (
            <div key={index} className={`${item.event_size === 'Large' ? 'col-12' : 'col-md-6'} ${this.state.resized === true ? 'resized-image' : ''}`}>
              <a
                href={createUrl('/' + item.uid, this.linksData)}
                className={`row-first event-images`}
                hrefLang="en"
              >
                <img alt={item.title} className="w-100" src={this.setBackgroundImage(item.page_thumbnail)} />
              </a>
            </div>
          ))}
          <div className='col-md-12'>
            <h3 className="careers-sub-heading" style={{ paddingBottom: '45px', paddingTop: '45px' }}>
              We may have missed you at
            </h3>
          </div>
          {pastEvent.map((item, index) => (
            <div key={index} className={`${item.event_size === 'Large' ? 'col-12' : 'col-md-6'} ${this.state.resized === true ? 'resized-image' : ''}`}>
              <a
                href={createUrl('/' + item.uid, this.linksData)}
                className={`row-first event-images`}
                hrefLang="en"
              >
                <img alt={item.title} className="w-100" src={this.setBackgroundImage(item.page_thumbnail)} />
              </a>
            </div>
          ))}
          <div className="container-button">
            <a
              onClick={this.handleEventBtnClick}
              className="button-events"
              onMouseEnter={e => this.handleMouseEnter('isEventFocused')}
              onMouseLeave={e => this.handleMouseLeave('isEventFocused')}
              style={this.state.isEventFocused ? this.buttonHoverStyleObj : this.buttonStyleObj}
            >
              See All Events
            </a>
          </div>
        </div>
      </div>
    );
  }

  renderFeaturedResourceSlice() {
    return (
      <Container className="slice-width-global">
        <h2 className="featured-heading" style={{ color: this.sectionHeadingFontColor }}>
          {this.HomePageResouceComponentData.eventsData.primary.resource_section_subheading.text}
        </h2>

        <h3 className="featured-sub-heading" style={{ color: this.sectionSubheadingFontColor }}>
          {this.HomePageResouceComponentData.eventsData.primary.resource_section_heading.text}
        </h3>
        <div className="featured-card-container">
          {this.HomePageResouceComponentData.eventCardData.map((item, index) => {
            let cardResourceButtonText;
            let cardResourceButtonTextElement = {};
            let uniqueId = item.uid;

            // checking card button text property
            if (item.resource_type) {
              cardResourceButtonText = item.resource_type;
            } else {
              cardResourceButtonText = null;
            }
            let cardResourceDescriptionText;
            let cardResourceDescriptionElement = {};

            // checking card description property
            if (item.title) {
              cardResourceDescriptionText = item.title;
            } else {
              cardResourceDescriptionText = null;
            }


            if (cardResourceDescriptionText) {
              cardResourceDescriptionElement = <span className="card-resource-description">{cardResourceDescriptionText}</span>;
            } else {
              cardResourceDescriptionElement = null;
            }

            // checking visibility for card heading
            if (cardResourceButtonText) {
              cardResourceButtonTextElement = <span className="card-resource-button">{cardResourceButtonText}</span>;
            } else {
              cardResourceButtonTextElement = null;
            }

            let cardResourceIconElement;
            if (cardResourceButtonText) {
              let logo = `icon-${cardResourceButtonText.toLowerCase().split(' ').join('-').trim()}`;
              cardResourceIconElement = <img src={require(`./../../static/${logo}.svg`).default} alt={`${cardResourceButtonText} logo`} className="resource-icon" />
            } else {
              cardResourceIconElement = null;
            }

            let resourceTagElement;
            if (item.resourceTags && item.resourceTags.items && item.resourceTags.items.length > 0) {
              let tagBackgroundColor = item.resourceTags.primary.tag_background_color ? getColor(item.resourceTags.primary.tag_background_color) : '#E3E9EC';
              let tagColor = item.resourceTags.primary.tag_font_color ? getColor(item.resourceTags.primary.tag_font_color) : '#000000';
              resourceTagElement = item.resourceTags.items.map(data => {
                if (data.tag_keywords_list?.toLowerCase() !== 'all topics') {
                  return (
                    <div
                      key={data.tag_keywords_list}
                      style={{ display: 'inline-block' }}
                    >
                      <button
                        className='resource-card-tag'
                        style={{ color: tagColor, backgroundColor: tagBackgroundColor }}
                        id={data.tag_keywords_list}
                        onClick={e => this.handleResourceTagClick(e, item)}
                      >
                        {data.tag_keywords_list}
                      </button>
                    </div>
                  )
                }
              })
              resourceTagElement = <div className="tag-container" style={{ marginTop: '10px' }}>{resourceTagElement}</div>
            } else {
              resourceTagElement = null;
            }

            return (
              <Col key={index} className="card-wrapper col-12 col-sm-6 col-lg-4 no-padding">
                <div className="resource-card-container">
                  <a href={`${createUrl('/' + uniqueId, this.linksData)}`} hrefLang="en">
                    <div className='card-background-img'>
                      <img src={item.page_thumbnail.url} alt={item.page_thumbnail.alt} className="resource-card-img"></img>
                    </div>
                    <div className="resource-content-wrapper">
                      <div className="resource-title-container">
                        {cardResourceIconElement}
                        {cardResourceButtonTextElement}
                      </div>
                      {cardResourceDescriptionElement}
                      {resourceTagElement}
                    </div>
                  </a>
                </div>
              </Col>
            )
          }
          )}
        </div>
      </Container>
    );
  }

  getLocationName(name) {
    if (name.includes("USA")) {
      return name.slice(0, name.indexOf(","));
    }
    return name;
  }

  renderCareerSlice() {
    return (
      <div className="slice-width-global carriers-container-component jobs-sec">
        <div className="work-with-us-div" style={{ background: this.sectionBackground }}>
          <h2 className="careers-heading" style={{ color: this.sectionHeadingFontColor }}>
            {this.HomePageResouceComponentData.eventsData.primary.resource_section_subheading.text}
          </h2>
          <h3 className="careers-sub-heading" style={{ color: this.sectionSubheadingFontColor }}>
            {this.HomePageResouceComponentData.eventsData.primary.resource_section_heading.text}
          </h3>

          <p className="careers-section-description" style={{ color: this.sectionDescriptionFontColor }}>
            {this.HomePageResouceComponentData.eventsData.primary.section_description.text}
          </p>
          <div className="container-button">
            <a
              className="button-careers-learn-more"
              href={createUrl(this.HomePageResouceComponentData.eventsData.primary.career_button_link.url, this.linksData)}
              hrefLang="en"
              onMouseEnter={e => this.handleMouseEnter('isCareerFocused')}
              onMouseLeave={e => this.handleMouseLeave('isCareerFocused')}
              style={this.state.isCareerFocused ? this.buttonHoverStyleObj : this.buttonStyleObj}
            >
              {this.HomePageResouceComponentData.eventsData.primary.career_button_text.text}
            </a>
          </div>

          <div className="careers-job-list-container">
            {this.state.featuredJobs.slice(0, 3).map((item, index) => {
              let jobDescription = item.node.data.body[item.node.data.body.findIndex(obj => obj.__typename === "PrismicJobDescriptionPageBodyJobDescription")];
              let jobKeyDetails = item.node.data.body[item.node.data.body.findIndex(obj => obj.__typename === "PrismicJobDescriptionPageBodyKeyDetails")];

              return (
                <Container className="border-bottom career-jobs-list-items" key={index}>
                  <Row className="noGutters">
                    <Col className="col-sm-8">
                      <p className="career-list-job-location">{jobKeyDetails ? this.getLocationName(jobKeyDetails.primary.job_location.text) : ''}</p>
                      <p className="career-list-job-title"> {jobDescription ? jobDescription.primary.job_title.text : ''} </p>
                    </Col>
                    <Col className="col-sm-4 career-list-button-container">
                      <a className="career-list-job-button" href={createUrl(`${item.node.data.page_path.text}/${item.node.data.page_display_name.text.split(" ").join("-")}`, this.props.pagesLinksData)} hrefLang="en">
                        Job Details
                      </a>
                    </Col>
                  </Row>
                </Container>
              )
            })}
          </div>

          <div className="career-job-see-all-jobs">
            <a
              href={createUrl(this.HomePageResouceComponentData.eventsData.primary.resource_button_link.url, this.linksData)}
              hrefLang="en"
              style={{ color: this.careerBtnFontColor }}>See All Jobs</a>
          </div>
        </div>
      </div>
    );
  }
}

export default HomePageResouceComponent;

HomePageResouceComponent.propTypes = {
  input: PropTypes.object.isRequired,
  pagesLinksData: PropTypes.array
};
