import React, { Component } from "react";
import PropTypes from "prop-types";
import { getColor } from "../utils/universalHelperFunctions";
import "../styles/content-award-partnership.css";

class ContentAwardAndPartnerships extends Component {
  constructor(props) {
    super(props);
    this.state = {
      width: 800,
      height: 182,
      cardColumns: []
    };
    this.contentAwardAndPartnershipsData = this.props.input;
    //this.spinnerDisplay = "block"
  }

  /**
   * Calculate & Update state of new dimensions
   */
  updateDimensions() {
    if (window.innerWidth < 500) {
      this.setState({ width: 450, height: 102 });

    } else {
      let update_width = window.innerWidth - 100;
      let update_height = Math.round(update_width / 4.4);
      this.setState({ width: update_width, height: update_height });
    }
  }

  /**
   * Add event listener
   */
  componentDidMount() {
    this.updateDimensions();
    window.addEventListener("resize", this.updateDimensions.bind(this));
  }

  /**
   * Remove event listener
   */
  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions.bind(this));
  }

  componentDidMount() { }

  render() {
    let awardImageElement;
    let awardDescriptionElement;

    let allCardsElement = [];
    let sectionBackground = this.contentAwardAndPartnershipsData.primary.partnership_section_background ?  getColor(this.contentAwardAndPartnershipsData.primary.partnership_section_background) : '#fff';
    let logoborder = this.contentAwardAndPartnershipsData.primary.partner_logo_border_color ? getColor( this.contentAwardAndPartnershipsData.primary.partner_logo_border_color) : '#e7eeef';
    let headingFontColor = this.contentAwardAndPartnershipsData.primary.partnership_heading_font_color ? getColor(this.contentAwardAndPartnershipsData.primary.partnership_heading_font_color) : '#000';
    let sectionFontColor = this.contentAwardAndPartnershipsData.primary.partnership_section_font_color ? getColor(this.contentAwardAndPartnershipsData.primary.partnership_section_font_color) : '#313542';

    this.contentAwardAndPartnershipsData.items.map((item, key) => {
      // defining cardBackground element and ojbect
      var awardImageURL = {};
      let altText = '';
      //checking card image property
      if (
        this.contentAwardAndPartnershipsData.items[key].hasOwnProperty(
          "award_image"
        )
      ) {
        awardImageURL = this.contentAwardAndPartnershipsData.items[key]
          .award_image.url;

        altText = this.contentAwardAndPartnershipsData.items[key].award_image.alt;
      } else {
        awardImageURL = null;
      }

      // defining cardBackground element and ojbect
      var awardDescription;
      //checking card image property
      if (
        this.contentAwardAndPartnershipsData.items[key].hasOwnProperty(
          "award_description"
        )
      ) {
        awardDescription = this.contentAwardAndPartnershipsData.items[key]
          .award_description.text;
      } else {
        awardDescription = null;
      }

      if (awardImageURL) {
        awardImageElement = (
          <div className="partnerShipLogoWrapper" style={{borderColor: logoborder}}>
          <img
            src={awardImageURL} width="70%" alt={altText}
          />
          </div>
        );
      } else {
        awardImageElement = null;
      }

      if (awardDescription) {
        awardDescriptionElement = (
          <p className={"card-desc-content"} style={{color: sectionFontColor}}>{awardDescription}</p>
        );
      } else {
        cardDescriptionElement = null;
      }

      var singleCardElement = {};
      singleCardElement = (
        <div className="card-design-content text-center bg-transparent col-12 col-sm-6 col-md-4" key={key}>
          {awardImageElement}
          {awardDescriptionElement}
        </div>
      );

      allCardsElement.push(singleCardElement);
    });

    let id = "";
    if (this.contentAwardAndPartnershipsData.primary.hasOwnProperty("breadcrumb_id")) {
      id = this.contentAwardAndPartnershipsData.primary.breadcrumb_id.text;
    }

    return (
      <div className="slice-width-global container" id={id} style={{background: sectionBackground}}>
        <h2 className="heading-partnership" style={{color: headingFontColor}}>
          {
            this.contentAwardAndPartnershipsData.primary.award_section_heading
              .text
          }
        </h2>
        <h3 className="sub-heading-partnership" style={{color: sectionFontColor}}>
          {
            this.contentAwardAndPartnershipsData.primary
              .award_section_subheading.text
          }
        </h3>
        <div className="parent-container-partnership row"> {allCardsElement}</div>
      </div>
    );
  }
}
export default ContentAwardAndPartnerships;

ContentAwardAndPartnerships.propTypes = {
  input: PropTypes.object
};
