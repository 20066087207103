import React, { Component } from "react";
import PropTypes from "prop-types";
import "../styles/banner-twitter.css";
import {
     Button
} from "reactstrap";
class BannerTwitter extends Component {
  constructor(props) {
    super(props);
    this.bannerObj = props.bannerData;
    this.state = {};
    // this.dynamicStyles = { backgroundColor:this.partnersObj.primary.tech_background_color};
    this.dynamicStyles = { backgroundColor: "#3498DB" };
  }
  render() {
    let id = "";
    if(this.bannerObj.primary.hasOwnProperty("breadcrumb_id")) {
      id = this.bannerObj.primary.breadcrumb_id.text;
    }

    return (
      <div className="banner-twitter-section" style={this.dynamicStyles} id={id}>
         <h6 className="banner-title">{this.bannerObj.primary.twitter_title.text}</h6>
         <p className="banner-desc">{this.bannerObj.primary.twitter_description.text}</p>
         <Button color="primary" className="share-btn">
            <a href="#" className="fa fa-twitter twitter-share-icon" hrefLang="en"></a>
            <a className="share-label">{this.bannerObj.primary.twitter_button_text.text}</a>
         </Button>
      </div>
    );
  }
}

export default BannerTwitter;

BannerTwitter.propTypes = {
  bannerData: PropTypes.object.isRequired
};
