import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Container, Row, Col } from 'reactstrap';
import {getColor, createUrl} from '../utils/universalHelperFunctions';
import '../styles/footer.css';
import '@fortawesome/fontawesome-pro/css/all.css';

class Footer extends Component {
  constructor(props) {
    super(props);
    this.footerData = props.footerData;
    this.isFooterPipe = true;
  }

  render() {
    // getting GSPANN footer url from footer data
    const footerLogo = this.footerData.footer_logo.url;
    const address = this.footerData.address.text;
    const addressContactNo = address.split('|');

    return (
      <Container className="footerStyles" fluid style={this.footerData.footer_background_color ? { backgroundColor: getColor(this.footerData.footer_background_color) } : {}}>
        <div className="container">
          <div className="slice-width-global">
            <Row noGutters>
              <Col md="4">
                <img src={footerLogo} width="130" height="30" alt="GSPANN Logo" />
              </Col>
            </Row>

            <Row noGutters className="mt-4">
              <Col md="4">
                <ul className="list-unstyled">
                  <li className="footerItemsHeading1" style={this.footerData.body[1].primary.menu_type_font_color ? { color: getColor(this.footerData.body[1].primary.menu_type_font_color) } : {}}>{this.footerData.body[1].primary.footer_menu_type.text}</li>
                  {this.footerData.body[1].items.map((item, key) => (
                    <li className="m-0" key={item.footer_menu_text.text}>
                      <a
                        className="footer-items-content1"
                        href={item.footer_menu_link.url ? createUrl(item.footer_menu_link.url, this.props.pagesLinksData) : ''}
                        style={item.footer_menu_font_color ? { color: getColor(item.footer_menu_font_color) } : {}}
                        hrefLang="en"
                      >
                        {item.footer_menu_text.text}
                      </a>
                    </li>
                  ))}
                </ul>
              </Col>

              {/* second column i.e -  Products */}
              <Col md>
                <ul className="list-unstyled">
                  <li className="footerItemsHeading" style={this.footerData.body[2].primary.menu_type_font_color ? { color: getColor(this.footerData.body[2].primary.menu_type_font_color) } : {}}>{this.footerData.body[2].primary.footer_menu_type.text}</li>
                  {this.footerData.body[2].items.map((item, key) => (
                    <li className="m-0" key={item.footer_menu_text.text}>
                      <a
                        className="footer-items-content"
                        href={item.footer_menu_link.url ? createUrl(item.footer_menu_link.url, this.props.pagesLinksData) : ''}
                        style={item.footer_menu_font_color ? { color: getColor(item.footer_menu_font_color) } : {}}
                        hrefLang="en"
                      >
                        {item.footer_menu_text.text}
                      </a>
                    </li>
                  ))}
                </ul>
              </Col>

              {/* Third column i.e -  About us */}
              <Col md>
                <ul className="list-unstyled">
                  <li className="m-0">
                    <a className="footerItemsHeading"
                      href={this.footerData.body[3].primary.footer_menu_link.url ? createUrl(this.footerData.body[3].primary.footer_menu_link.url, this.props.pagesLinksData): ''}
                      style={this.footerData.body[3].primary.menu_type_font_color ? { color: getColor(this.footerData.body[3].primary.menu_type_font_color) } : {}}
                      hrefLang="en"
                    >
                      {this.footerData.body[3].primary.footer_menu_type.text}
                    </a>
                  </li>
                  {this.footerData.body[3].items.map((item, key) => (
                    <li className="m-0" key={item.footer_menu_text.text}>
                      <a
                        className="footer-items-content"
                        href={item.footer_menu_link.url ? createUrl(item.footer_menu_link.url, this.props.pagesLinksData) : ''}
                        style={item.footer_menu_font_color ? { color: getColor(item.footer_menu_font_color) } : {}}
                        hrefLang="en"
                      >
                        {item.footer_menu_text.text}
                      </a>
                    </li>
                  ))}
                </ul>
              </Col>

              {/* Forth column i.e -  Connect with us */}
              <Col md="4">
                <ul className="list-unstyled">
                  <li className="m-0 footerItemsHeading" style={this.footerData.body[4].primary.social_connection_font_color ? { color: getColor(this.footerData.body[4].primary.social_connection_font_color) } : {}}>
                    {this.footerData.body[4].primary.social_connection_type.text}
                  </li>
                  <li className="footer-items-content" style={this.footerData.footer_font_color ? { color: getColor(this.footerData.footer_font_color) } : {}}>
                    {addressContactNo[0]}
                  </li>
                  <li className="footer-items-content" style={this.footerData.footer_font_color ? { color: getColor(this.footerData.footer_font_color) } : {}}>
                    {addressContactNo[1]}
                  </li>
                  <li className="footer-items-content" style={this.footerData.footer_font_color ? { color: getColor(this.footerData.footer_font_color) } : {}}>
                    <a href='https://www.google.com/maps/search/362+Fairview+Way,+Milpitas,+CA+95035+408-263-3435/@37.4446653,-121.9188663,17z/data=!3m1!4b1?entry=ttu' target='_blank' style={{color:'rgb(181, 182, 182)'}} >Directions</a>
                  </li>
                </ul>

                {/* Row for social Icons */}
                <Row className="footer-social-icon-container" sm="12" md="6">
                  {this.footerData.body[4].items && this.footerData.body[4].items.length > 0 && this.footerData.body[4].items.map((item, key) => (
                    <Col className="pr-4" xs="1" key={key}>
                      <a href={item.social_icon_link.url} target="_blank" hrefLang="en">
                        <i className={`socialIcons ${getColor(item.social_icon)}`} style={item.social_icon_font_color ? { color: getColor(item.social_icon_font_color) } : {}}></i>
                      </a>
                    </Col>
                  ))}
                </Row>
              </Col>
            </Row>

            {/* Copyright content Row */}

            <Row noGutters className="mt-5">
              <Col xs="12" sm="auto" className="footerTrademarkContent">
                <a href="/" className="footerTrademarkContent" style={this.footerData.footer_font_color ? { color: getColor(this.footerData.footer_font_color) } : {}}>
                  {this.footerData.copyright_text.text}
                </a>
              </Col>
              <Col xs="12" sm="auto" className="footerTrademarkContent">
                {
                  this.footerData.body[0].items.map((item, index) => (
                    <React.Fragment key={item.policy_name.text}>
                      <span className={`${!index ? 'd-none' : ''} d-sm-inline px-2`} style={this.footerData.footer_font_color ? { color: getColor(this.footerData.footer_font_color) } : {}}>
                        |
                      </span>
                      <a
                        className="footerTrademarkContent"
                        href={item.policy_link.url ? createUrl(item.policy_link.url, this.props.pagesLinksData) : '/'}
                        style={item.policy_font_color ? { color: getColor(item.policy_font_color) } : {}}
                        hrefLang="en"
                      >
                        {item.policy_name.text}
                      </a>
                    </React.Fragment>
                  ))
                }
              </Col>
            </Row>
          </div>
        </div>
      </Container >
    );
  }
}

export default Footer;

Footer.propTypes = {
  footerData: PropTypes.object.isRequired,
  pagesLinksData: PropTypes.array
};
