import React, { Component, useState } from 'react';
// import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types';
import '../styles/experts.css';import {
  Col,
  Row,
  Button,
  Label,
  Container,
  Modal,
  ModalBody
} from "reactstrap";
import { FormDisplaySection2 } from "slices";
import {
  Card, CardImg, CardBody,
  CardTitle, CardSubtitle, Media
} from 'reactstrap';
import { getColor } from "../utils/universalHelperFunctions";
import PopupModal from './../components/PopupModal';
// import PopupModal from './../slices/PopupModal';


class Experts extends Component {
  constructor(props) {
    super(props);
    console.log('Experts props: ', props);
    this.expertsData = props.expertsData;
    let emailTemplateData = props.allSlices.filter((slice) =>
    [
      "PrismicPopupModalBodyEmailTemplateLinks",
      "PrismicResourceBodyEmailTemplateLinks",
      "PrismicPagePageContentEmailTemplateLinks",
      "PrismicHomepagePageContentEmailTemplateLinks",
      "PrismicJobDescriptionPageBodyEmailTemplateLinks",
    ].includes(slice.__typename)
  );
  let expertsPopupData = {};
  props.allSlices.forEach(element => {
    if (element.slice_type === 'page_popup_model') {
      expertsPopupData = element;
    } 
  });
    this.state = {
      showPopup : false,
      emailTemplateDataExperts : emailTemplateData,
      expertsPopupDataExperts : expertsPopupData,
      leaderName : ""
    };
    this.showPopup = false;
    this.HandleOutlookIconEvent = this.HandleOutlookIconEvent.bind(this);
    this.HandleOutlookIconCloseEvent = this.HandleOutlookIconCloseEvent.bind(this);
    let [s] = [props.prismicPopupModal];
    
  }
  
  HandleOutlookIconEvent(idx) {
    console.log("entered HandleOutlookIconEvent",idx);
    this.setState({showPopup: true});
    this.setState({leaderName: idx});
    console.log("entered experts popup props",this.props);
  }
  HandleOutlookIconCloseEvent() {
    console.log("entered showPopup",this.state.showPopup);
    // document.cookie = `isPopupClosed=${true};`;
    this.setState({showPopup: false});
  }
  render() {
    let id = "";
    if (this.expertsData.primary.hasOwnProperty("breadcrumb_id")) {
      id = this.expertsData.primary.breadcrumb_id.text;
    }
    console.log("showPopup",this.state.showPopup);
    let alignment = 'Vertical'
    if (this.expertsData.primary.hasOwnProperty('section_alignment') && this.expertsData.primary.section_alignment) {
      alignment = this.expertsData.primary.section_alignment;
    }
    console.log("expertsPopupDataExperts",this.state.expertsPopupDataExperts);
    console.log("emailTemplateDataExperts",this.state.emailTemplateDataExperts);
    // console.log("emailTemplateDataExperts",this.state.expertsPopupDataExperts.body[0].primary.popup_image.url);
    const form = <FormDisplaySection2
      key={this.state.expertsPopupDataExperts.id}
      input={this.state.expertsPopupDataExperts}
      emailTemplateData={this.state.emailTemplateDataExperts}
      leaderName = {this.state.leaderName}
      showPopupExperts = {this.state.showPopup}
    />
    // if(this.state.showPopup){
    //   this.state.showPopup = false;
    // }
    return (
      <div
        className="experts-section"
        style={this.expertsData.primary.expert_section_background_color ? { backgroundColor: getColor(this.expertsData.primary.expert_section_background_color), paddingTop: '50px' } : {}}
        id={id}
      >
        <div className="container">
          <div className={`row col-12 center-align-expert-icon`} style={{ margin: 0, padding: 0 }}>
            <h2 className={`experts-section-heading col-12 ${alignment === 'Horizontal' ? 'expert-card-heading' : ''}`} style={{ padding: 0 }}>{this.expertsData.primary.expert_section_heading.text}</h2>
            {this.state.showPopup && <div id="experts_popup">
              <div>
              <Container id="popupform-main-container">
              <Modal
                className="imageMagnifierModal container"
                isOpen={this.state.showPopup}
                toggle={this.HandleOutlookIconCloseEvent}
              >
                <ModalBody className="modal-body-container">
                <Row className='col-md-12' style = {{ paddingLeft:"0px",paddingRight:"0px",marginLeft:"0px",marginRight:"0px",}}>
                {/* <Col className='col-md-03' style = {{ width:"30%", padding:"0px"}}>
                  <img alt="Join Us" src={this.state.expertsPopupDataExperts ? this.state.expertsPopupDataExperts.primary.popup_image.url : ""} ></img>
                </Col> */}
                <Col className='col-md-12'style = {{ width:"100%", padding:"0px"}}>
                  <button className="close-btn btn" 
                  onClick={this.HandleOutlookIconCloseEvent}
                  >
                    <i className="far fa-times fa-1x"></i>
                  </button>
                    {form}
                </Col>
                </Row>
                </ModalBody>
              </Modal>
              </Container>
              </div>
            </div>}
            {this.expertsData.items.map((expert, i) => {
              let fontColor = null;
              if (expert.expert_font_color) {
                fontColor = getColor(expert.expert_font_color);
              }
              if (alignment === 'Vertical') {
                return (
                  <Media key={i} className="expert-container col-12 no-padding">
                    <Media left>
                      <Media
                        object
                        src={expert.expert_image.url}
                        className="expert-photo"
                        alt={expert.expert_image.alt}
                      />
                    </Media>
                    <Media body className="expert-text-section">
                      <h3 className="expert-text-name">
                        {expert.expert_name.text}
                      </h3>
                      <Media heading className="expert-text-position">
                        {expert.expert_position.text}
                      </Media>
                      <p className="expert-role-para" style={fontColor ? { color: fontColor } : {}}>
                        {expert.expert_role.text}
                      </p>

                      <Media className="">
                        {(expert.expert_link && expert.expert_link.text) ? (
                          <>
                            <a
                              href={expert.expert_link ? expert.expert_link.text : ''}
                              className="fab fa-linkedin-in"
                              style={{ marginTop: '4px', color: '#3498DB' }}
                            />
                            <span className="d-sm-inline px-2" style={{ color: '#7F8C8D' }}>
                              |
                            </span>
                            <a
                              // href=""
                              onClick={() => this.HandleOutlookIconEvent(expert.expert_name.text)}
                              className="far fa-envelope"
                              style={{ marginTop: '4px', color: '#3498DB', padding:"0px" }}
                            />
                          </>
                        ) : ''}
                        {(expert.expert_social_connection && expert.expert_social_connection.text) ? (
                          <a href={`mailto:${expert.expert_social_connection.text}`} className="expert-email" hrefLang="en">
                            <i className="far fa-envelope" style={{ marginTop: '4px', color: '#3498DB' }}></i>
                          </a>
                        ) : ''}
                      </Media>
                    </Media >
                  </Media >
                )
              } else {
                return (
                  <Card key={i} className="expert-card col-12 col-sm-6 col-lg-3">
                    <CardImg top width="100%" src={expert.expert_image.url}
                      className="expert-card-photo"
                      alt={expert.expert_image.alt} />
                    <CardBody className="expert-card-body">
                      <CardTitle className="expert-card-name">{expert.expert_name.text}</CardTitle>
                      <CardSubtitle className="expert-card-position">{expert.expert_position.text}</CardSubtitle>
                    </CardBody>
                  </Card>
                )
              }
            }
            )}
          </div >
        </div >
      </div >
    );
  }
}
export default Experts;
Experts.propTypes = {
  expertsData: PropTypes.object.isRequired,
};
