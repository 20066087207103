
import { Component } from 'react';
import { Col, Row, Container } from "reactstrap";
import { createUrl, getColor } from "../utils/universalHelperFunctions";
import "../styles/resource-description.css";
import RelatedResource from './RelatedResource';
import RelatedResourceCard from './RelatedResourceCard';

class TwoColumnDescription extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    let descriptionSection = null;
    let repeatableSection = null;
    let highlighterSection = null;
    let sectionBackgroundColor = "#F1F5F6";
    let sectionFontColor = "#000";
    let HeaderFontColor = "#000";
    let highlighterFontColor = "#000";
    let highlighterColor = "#000";
    let gridLayout = this.props.input.primary.section_partition
      ? getColor(this.props.input.primary.section_partition).split(",")
      : "col-md-9,col-md-3".split(",");
    if (
      this.props.input.primary.hasOwnProperty(
        "resource_section_background_color"
      ) &&
      this.props.input.primary.resource_section_background_color
    ) {
      sectionBackgroundColor = getColor(
        this.props.input.primary.resource_section_background_color
      );
    }
    if (
      this.props.input.primary.hasOwnProperty("resource_section_font_color") &&
      this.props.input.primary.resource_section_font_color
    ) {
      sectionFontColor = getColor(
        this.props.input.primary.resource_section_font_color
      );
    }
    let id = "";
    if (this.props.input.primary.hasOwnProperty("breadcrumb_id")) {
      id = this.props.input.primary.breadcrumb_id.text;
    }
    const resoureSectionHeading = this.props.input.primary.resource_section_heading?.text;
    const resourceSectionDescription = this.props.input.primary.resource_section_description;

    const items = this.props.input.items;
    
    const leftData = items.filter(item => item.position === 'Left');
    const rightData = items.filter(item => item.position === 'Right');
    console.log('this.props items: ', items);
    descriptionSection = (
      <div
        className={`col-12 ${this.props.input.primary.position_of_description === "Left"
          ? gridLayout[0]
          : `${gridLayout[1]}`
          } description-section`}
      >
        {this.props.input.primary.highlighter_position &&
          this.props.input.primary.highlighter_position === "Top" &&
          this.props.input.primary.highlighter_content &&
          this.props.input.primary.highlighter_content.html && (
            <div style={{ marginBottom: "40px" }}>{highlighterSection}</div>
          )}
        {this.props.input.primary.resource_section_description_in_bold &&
          this.props.input.primary.resource_section_description_in_bold
            .html && (
            <div
              className={`section-content repeatable-items-spacing`}
              dangerouslySetInnerHTML={{
                __html:
                  this.props.input.primary.resource_section_description_in_bold
                    .raw.length > 1
                    ? this.props.input.primary
                      .resource_section_description_in_bold.html
                    : this.props.input.primary
                      .resource_section_description_in_bold.raw[0].text,
              }}
            />
          )}
        {this.props.input.primary.resource_section_description && (
          <div
            className={`section-content`}
            dangerouslySetInnerHTML={{
              __html:
                this.props.input?.primary?.resource_section_description.raw?.length > 1
                  ? this.props.input.primary.resource_section_description.html
                  : (this.props.input?.primary?.resource_section_description?.raw ? this.props.input?.primary?.resource_section_description?.raw[0]?.text : ''),
            }}
          />
        )}

      </div>
    );

    return !this.props.isHidden ? <div
      className={`resource-description-container`}
      style={{ color: sectionFontColor, background: sectionBackgroundColor }}
      id={id}>
      <Container style={{ paddingBottom: '40px', paddingTop: '40px' }}>
        <Row noGutters>
          {resoureSectionHeading && <Col className=" col-sm-12 col-12 col-md-12" style={{paddingBottom: "25px"}}>
            <h2 className="two-column-section-heading">{resoureSectionHeading}</h2>
            <div className="repeatable-sub-description" dangerouslySetInnerHTML={{
              __html:
                resourceSectionDescription?.raw?.length > 1
                  ? resourceSectionDescription?.html
                  : resourceSectionDescription?.raw[0].text,
            }}></div>
          </Col>}
          <Col key={1} className="col-sm-12 col-md-6" sm="12" md="6" lg="6">
            {leftData.map(data => {
              return <Row noGutters style={{ paddingBottom: '15px'}}>
                <Col key={1} className="col-sm-12 col-12 col-md-12 " style={{paddingRight: '10px'}}>
                  {data.related_resource && <RelatedResourceCard related_resource_background_color={'rgba(28,53,94,0.9)'} {...data.related_resource} />}
                  { data.repeatable_section_title && <span className="subTitle">{data.repeatable_section_title.text}</span>}
                  {data.repeatable_section_description && <div className="repeatable-sub-description" dangerouslySetInnerHTML={{
                    __html:
                    data.repeatable_section_description.raw && (data.repeatable_section_description.raw?.length > 1
                        ? data.repeatable_section_description.html
                        : data.repeatable_section_description.raw[0].text)
                  }}></div>}
                  
                </Col>
              </Row>
            })}
          </Col>
          <Col key={2} className="col-md-6 col-sm-12" sm="12" md="6" lg="6">
          {rightData.map(data => {
              return <Row noGutters style={{ paddingBottom: '15px'}}>
                <Col key={1} className="col-12 col-md-12 col-sm-12" style={{paddingLeft: '10px'}}>
                  {data.related_resource && <RelatedResourceCard related_resource_background_color={'rgba(28,53,94,0.9)'} {...data.related_resource} />}
                  { data.repeatable_section_title && <span className="subTitle">{data.repeatable_section_title.text}</span>}
                  {data.repeatable_section_description && <div className="repeatable-sub-description" dangerouslySetInnerHTML={{
                    __html:
                    data.repeatable_section_description.raw && (data.repeatable_section_description.raw?.length > 1
                        ? data.repeatable_section_description.html
                        : data.repeatable_section_description.raw[0].text)
                  }}></div>}
                  
                </Col>
              </Row>
            })}
          </Col>
        </Row>
      </Container>
    </div> : <></>
  }
}

export default TwoColumnDescription;