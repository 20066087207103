import React, { useState } from "react";
import PropTypes from "prop-types";
import { Modal } from "reactstrap";
import "../styles/image-magnifier-component.css";

function ImageMagnifierComponent(props) {
  const [showOverlay, isOverlayOpen] = useState(false);

  function showHideOverlaySection() {
    isOverlayOpen(!showOverlay);
  }
  return (
    <>
      <img
        style={{ cursor: "zoom-in", maxWidth: "100%", height: "auto" }}
        src={
          props.imageData.Desktop && props.imageData.Desktop.url
            ? props.imageData.Desktop.url
            : props.imageData.url
        }
        alt={
          props.imageData.Desktop && props.imageData.Desktop.alt
            ? props.imageData.Desktop.alt
            : props.imageData.alt
        }
        onClick={showHideOverlaySection}
      />
      <Modal
        className="imageMagnifierModal container"
        isOpen={showOverlay}
        toggle={showHideOverlaySection}
      >
        <button className="close-btn btn" onClick={showHideOverlaySection}>
          <i className="far fa-times fa-2x"></i>
        </button>
        <div className="image-container">
          <img
            height="100%"
            src={props.imageData.url}
            alt={props.imageData.alt}
          />
        </div>
      </Modal>
    </>
  );
}
export default ImageMagnifierComponent;

ImageMagnifierComponent.propTypes = {
  imageData: PropTypes.object.isRequired,
};
