import { Component } from "react";
import { Col, Row, Container } from "reactstrap";
import { createUrl, getColor } from "../utils/universalHelperFunctions";
class RelatedResourceCard extends Component {
    constructor(props) {
        super(props);
        this.cardData = this.props.cardData;
        this.linksData = props.pagesLinksData;
    }

    render() {
        const { related_resource_background_color, document, url } = this.props;
        const { data: { body, page_path, page_url, resource_display_name } } = document ? document[0] : {};
        console.log('related resource card: ', document[0]);

        const pageOverview = body.find(item => item.__typename === "PrismicResourceBodyPageOverview");
        const resourceOverview = body.find(item => item.__typename === "PrismicResourceBodyResourceOverview");
        return (
            <Row noGutters style={{ backgroundImage: `url(${pageOverview?.primary?.page_thumbnail?.url})`, backgroundSize: 'contain' }}>
                <Col className="col-12 col-md-12 relatedResourceCard">
                    <div className="text-wrapper">
                        <span className='resource-title'>{`${resourceOverview.primary?.resource_type}: ${pageOverview?.primary?.page_title?.text}`}</span>
                        <a href={`/${page_path?.text}/${page_url?.text ? page_url.text : resource_display_name?.text.split(" ").join("-").toLowerCase()}`}>
                            <span className='read-now'>
                                Read Now
                            </span>
                        </a>
                    </div>

                </Col>
            </Row>
        );
    }
}

export default RelatedResourceCard;