import React, { Component } from "react";
import ReactDOM from 'react-dom';
import PropTypes, { element } from "prop-types";
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink
} from "reactstrap";
import { createUrl, getColor, setCountryInfo } from "../utils/universalHelperFunctions";
import SubHeader from "./SubHeader";
import SubHeaderMobile from "./SubHeaderMobile";
import { SearchFormModal } from "components";
import logo from "./../../static/logos/GSPANN-Logo.svg";
import { FaSearch } from "react-icons/fa";
import "../styles/header.css";
const countryCodeExpression = /loc=([\w]{2})/;
class Header extends Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    this.menuData = props.menuData;
    this.linksData = props.pagesLinksData;
    this._element = React.createRef();
    this.toggle = this.toggle.bind(this);
    this.toggleOne = this.toggleOne.bind(this);
    this.displaySearch = this.displaySearch.bind(this);
    this.removeActiveHeader = this.removeActiveHeader.bind(this);
    this.addActiveHeader = this.addActiveHeader.bind(this);
    this.isMenuOpen = this.isMenuOpen.bind(this);
    this.handleDocumentClick = this.handleDocumentClick.bind(this);
    this.onResize = this.onResize.bind(this);

    this.state = {
      navigationBackground: "rgba(255, 255, 255, 1)",
      subMenuItems: {},
      menuDisplay: true,
      searchDisplay: false,
      isOpen: false,
      activeHeader: [],
      isSubMenuOpen: false,
      activeLink: '',
      isMobileView: false,
      countryCode: ''
    };
    this.leftMenu = [];
    this.rightMenu = [];
    // console.log("props.menuData", props.menuData)
    props.menuData.forEach(element => {
      if (element.primary.nav_item_position === 'left') {
        this.leftMenu.push(element);
      } else {
        this.rightMenu.push(element);
      }
    });
  }
  componentDidMount() {
    this._isMounted = true;
    if (typeof window !== 'undefined') {
      window.addEventListener("scroll", this.handleScroll.bind(this));
      window.addEventListener('resize', (e) => { this.onResize(); });
      if (window.innerWidth < 768) {
        this.setState({ isMobileView: true });
      }
    }
    fetch("https://www.cloudflare.com/cdn-cgi/trace").then(response => response.text()).
      then(data => {
        const response = (data || "").split("\n").reduce((prev, curr) => {
          const [key, value] = (curr || "").split("=");
          return {
            ...prev,
            [key]: value
          }
        }, {});
        // console.log("Country Fetch", {data, response}, countryCodeExpression.exec(data)[1]);
        const countryCode = response.loc || countryCodeExpression.exec(data)[1];
        console.log("country2",countryCode);
        this.setState({ countryCode: countryCode }, () => {
          setCountryInfo(countryCode)
        })
      })
  }
  componentWillUnmount() {
    if (typeof window !== 'undefined') {
      window.removeEventListener("scroll", this.handleScroll.bind(this));
    }
    this._isMounted = false;
  }
  onResize() {
    if (typeof window !== 'undefined') {
      this.setState({ isMobileView: window.innerWidth < 768 ? true : false });
    }
  }
  handleDocumentClick(e) {
    const container = ReactDOM.findDOMNode(this._element.current);
    if (!document.querySelector('.navigation-toggle-button.navbar-toggler').contains(e.target)) {
      if (this.state.isOpen && e.target !== container && !container.contains(e.target)) {
        this.toggle();
      }
    }
  }
  handleScroll(event) {
    let scrollTop = document.documentElement.scrollTop;
    if (this._isMounted) {
      if (scrollTop <= 360) {
        this.setState({
          navigationBackground: "rgba(255, 255, 255, 1)"
        });
      } else {
        this.setState({
          navigationBackground: "rgba(255, 255, 255, 1)"
        });
      }
    }
  }
  toggle() {
    this.setState({
      isOpen: !this.state.isOpen,
      searchDisplay: false,
      menuDisplay: true,
      activeHeader: []
    }, () => {
      if (this.state.isOpen) {
        document.documentElement.style.overflow = "hidden";
        document.documentElement.addEventListener('click', this.handleDocumentClick, true);
      } else {
        document.documentElement.style.overflow = "auto";
        document.documentElement.removeEventListener('click', this.handleDocumentClick, true);
      }
    });
  }

  toggleOne() {
    this.setState({
      searchDisplay: !this.state.searchDisplay,
      menuDisplay: false
    });
  }

  displaySubMenu(menu, e) {
    // console.log({ menu, e, state: this.state });
    if (menu && menu.items) {
      let mainmenu = menu.items.filter(ele => { return ele.sub_nav_link.url !== '/freshers' });
      if (this.state.countryCode === 'IN') {
        mainmenu = menu.items;
      }
      menu.items = mainmenu;
      this.setState({
        subMenuItems: menu,
        activeLink: menu.primary.label.text, collapseSubMenu: this.state.collapseSubMenu === menu ? null : menu
      });

      this.state.activeHeader.find(element => element === menu.primary.label.text) ? this.removeActiveHeader(menu.primary.label.text) : this.addActiveHeader(menu.primary.label.text);
    }
  };

  removeActiveHeader(headerName) {
    let subMenu = [];
    subMenu = this.state.activeHeader.filter(element => {
      return element != headerName;
    });
    this.setState({ activeHeader: subMenu });
  }

  addActiveHeader(headerName) {
    let subMenu = this.state.activeHeader;
    subMenu.push(headerName);
    this.setState({ activeHeader: subMenu });
  }

  hideSubMenu = e => {
    this.setState({
      subMenuItems: {},
      activeLink: ''
    });
  };
  displaySearch = e => {
    this.setState({ menuDisplay: false });
    this.setState({ searchDisplay: true });
  };
  hideSearch = e => {
    this.setState({ menuDisplay: true });
    this.setState({ searchDisplay: false });
  };

  isMenuOpen(menu) {
    var filterData = this.state.activeHeader.filter(element => element === menu.primary.label.text);
    return filterData.length ? true : false;
  }

  handleClick = (e, menu) => {
    if (menu.primary.link) {
      this.displaySubMenu(menu, e);
    } else {
      window.innerWidth < 768 ? this.displaySubMenu(menu, e) : e.preventDefault();
    }
  }
  handleHover = (e, menu) => {
    window.innerWidth >= 768 ? this.displaySubMenu(menu, e) : e.preventDefault();
  }

  render() {
    const isMobile = typeof window !== 'undefined' && window.innerWidth < 768;
    return (
      <>
        <div
          className="navigation-container-parent"
          style={{ background: this.state.navigationBackground }}
        >
          <Navbar light expand="md" className="navigation-container container">
            <div className="header-bar">
              {this.state.menuDisplay &&
                <NavbarBrand href="/">
                  <img
                    src={logo}
                    alt="gspann logo"
                    className="navigation-brand-image d-md-block"
                  />
                </NavbarBrand>
              }
              <SearchFormModal openSearchModal={this.state.searchDisplay} hideSearch={this.hideSearch} />
              {/*<FaSearch className="search-icon-mobile" onClick={this.toggleOne} />*/}
              {this.state.menuDisplay && (
                <NavbarToggler
                  onClick={this.toggle}
                  className="navigation-toggle-button"
                  style={{ borderColor: "transparent" }}
                >
                  <i className="far fa-bars navbar-toggle"></i>
                </NavbarToggler>
              )}
            </div>
            <Collapse
              isOpen={this.state.isOpen}
              navbar
              className="navigation-collapse-section"
              ref={this._element}
            >
              {this.state.menuDisplay && (
                <Nav
                  className="header-nav"
                  navbar
                  onMouseLeave={e => this.setState({ subMenuItems: {}, activeLink: '' })}
                >
                  <li className="left-nav-section" style={{ margin: '0px' }}>
                    <ul className='list-partition' style={{ margin: '0px' }}>
                      {this.leftMenu.map((menu, i) => {
                        let fontStyleHoverObj = {
                          color: !this.state.isMobileView ? menu.primary.label_font_color_on_hover
                            ? getColor(menu.primary.label_font_color_on_hover)
                            : "#000" : '#fff',
                          fontWeight: 700
                        };
                        let fontStyleObj = {
                          color: !this.state.isMobileView ? menu.primary.label_font_color
                            ? getColor(menu.primary.label_font_color)
                            : "#000" : '#fff',
                          fontWeight: 'normal',

                        };
                        return (
                          <NavItem
                            style={{ boxShadow: `${!this.state.isMobileView && this.state.activeLink && this.state.activeLink === menu.primary.label.text ? 'inset 0 0 #CC3751,inset 0 -3px #CC3751' : 'none'}` }}
                            className={`navigation-item ${this.state.activeLink && this.state.activeLink === menu.primary.label.text ? 'active-navigation-header' : ''}`}
                            key={menu.primary.label.text}
                            onClick={e => this.handleClick(e, menu)}
                            onMouseOver={e => this.handleHover(e, menu)}
                          >
                            <NavLink
                              style={this.state.activeLink && this.state.activeLink === menu.primary.label.text ? fontStyleHoverObj : fontStyleObj}
                              className={`navigation-link left-navigation-link`}
                              href={
                                menu.primary.link && menu.primary.link.url
                                  ? createUrl(menu.primary.link.url, this.linksData)
                                  : ""
                              }
                              title={menu.primary.label.text}
                              onClick={e => { if (!menu.primary.link) e.preventDefault(); }}
                            >
                              {menu.primary.label.text}
                              <div className={`${this.isMenuOpen(menu) ? "d-inline" : "d-none"} up-down-arrow`}>
                                <i className={`d-inline-block d-md-none fal fa-chevron-up`}></i>
                              </div>
                              <div className={`${this.isMenuOpen(menu) ? "d-none" : "d-inline"} up-down-arrow`}>
                                <i className={`d-inline-block d-md-none fal fa-chevron-down`}></i>
                              </div>
                            </NavLink>
                            {
                              menu.items && menu.items.length > 0 && <Collapse
                                isOpen={this.isMenuOpen(menu)}
                                className="sub-header-collapse"
                                style={{ background: menu.primary.nav_item_background_color ? getColor(menu.primary.nav_item_background_color) : '#1C355E' }}
                              >
                                <SubHeaderMobile
                                  subMenuData={menu.items}
                                  menuLabel={menu.primary.label.text}
                                  pagesLinksData={this.linksData}
                                />
                              </Collapse>
                            }
                          </NavItem>
                          /* recursice menu items for submenus in mobile */
                        )
                      })}
                    </ul>
                  </li>
                  <li className="right-nav-section" style={{ margin: '0px' }}>
                    <ul className='list-partition' style={{ margin: '0px' }}>
                      {this.rightMenu.map((menu, i) => {
                        let fontStyleHoverObj = {
                          color: !this.state.isMobileView ? menu.primary.label_font_color_on_hover
                            ? getColor(menu.primary.label_font_color_on_hover)
                            : "#000" : '#fff',
                          fontWeight: 400,
                        };
                        let fontStyleObj = {
                          color: !this.state.isMobileView ? menu.primary.label_font_color
                            ? getColor(menu.primary.label_font_color)
                            : "#000" : '#fff',
                          fontWeight: 'lighter',
                        };
                        return (
                          <NavItem
                            style={{ boxShadow: `${!this.state.isMobileView && this.state.activeLink && this.state.activeLink === menu.primary.label.text ? 'inset 0 0 #CC3751,inset 0 -3px #CC3751' : 'none'}` }}
                            className="navigation-item"
                            key={menu.primary.label.text}
                            onClick={e => this.handleClick(e, menu)}
                            onMouseOver={e => this.handleHover(e, menu)}
                          >
                            <NavLink
                              className={`navigation-link right-navigation-link`}
                              style={this.state.activeLink && this.state.activeLink === menu.primary.label.text ? fontStyleHoverObj : fontStyleObj}
                              title={menu.primary.label.text}
                              href={
                                menu.primary.link && menu.primary.link.url
                                  ? createUrl(menu.primary.link.url, this.linksData)
                                  : ""
                              }
                              onClick={e => { if (!menu.primary.link) e.preventDefault(); }}
                            >
                              {menu.primary.label.text}
                              <div className={`${this.isMenuOpen(menu) ? "d-inline" : "d-none"} up-down-arrow`}>
                                <i className={`d-inline-block d-md-none fal fa-chevron-up`}></i>
                              </div>
                              <div className={`${this.isMenuOpen(menu) ? "d-none" : "d-inline"} up-down-arrow`}>
                                <i className={`d-inline-block d-md-none fal fa-chevron-down`}></i>
                              </div>
                            </NavLink>
                            {
                              menu.items && menu.items.length > 0 && <Collapse
                                isOpen={this.isMenuOpen(menu)}
                                className="sub-header-collapse"
                                style={{ background: menu.primary.nav_item_background_color ? getColor(menu.primary.nav_item_background_color) : '#1C355E' }}
                              >
                                <SubHeaderMobile
                                  subMenuData={menu.items}
                                  menuLabel={menu.primary.label.text}
                                  pagesLinksData={this.linksData}
                                />
                              </Collapse>
                            }
                          </NavItem>

                          /* recursice menu items for submenus in mobile */
                        )
                      })}
                    </ul>
                  </li>
                  <SubHeader
                    subMenuData={this.state.subMenuItems}
                    pagesLinksData={this.linksData}
                  />
                  {/*<FaSearch className="search-icon align-right" onClick={this.displaySearch} />*/}
                </Nav>
              )}
            </Collapse>
            {/* {(this.state.isMobileView && !this.state.isOpen) || !this.state.isMobileView ? (
              <div className="nav-custom" id="search-360-container">
                <FaSearch onClick={this.displaySearch} className={`searchBar-iconStyle`} id="globalSearch" />
              </div>
            ) : <></>} */}
            {!this.state.searchDisplay ? (
              <div className="nav-custom" id="search-360-container">
                <FaSearch onClick={this.displaySearch} className={`searchBar-iconStyle`} id="globalSearch" />
              </div>
            ) : <></>}
          </Navbar>
        </div>
      </>
    );
  }
}

export default Header;

Header.propTypes = {
  menuData: PropTypes.array.isRequired,
  pagesLinksData: PropTypes.array
};
