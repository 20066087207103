import React, { Component } from "react";
import "../styles/adobeEcosystem.css";

class AdobeEcosystem extends Component {
  constructor(props) {
    super(props);
    this.adobeEcosystemData = props.input;
    this.linksData = props.pagesLinksData;
    this.state = {
      isMobileView: false,
    };
    this.onResize = this.onResize.bind(this);
  }

  componentDidMount() {
    if (typeof window !== "undefined") {
      window.addEventListener("resize", (e) => {
        this.onResize();
      });
      if (window.innerWidth < 768) {
        this.setState({ isMobileView: true });
      }
    }
  }

  onResize() {
    if (typeof window !== "undefined") {
      this.setState({ isMobileView: window.innerWidth < 768 ? true : false });
    }
  }
  render() {
    return (
      <div style={{ backgroundColor: "rgb(241, 245, 246)" }}>
        <div className="container heading-section-container">
          <div>
            <h1 className="header-text">
              {this.adobeEcosystemData.primary.header.text}{" "}
            </h1>
          </div>
          {!this.state.isMobileView && (
            <div className="row">
              <div className="col-4 content-commerce">
                {this.adobeEcosystemData.primary.content_part_one.text}
              </div>
              <div className="col-4 content-commerce">
                {this.adobeEcosystemData.primary.content_part_two.text}
              </div>
              <div className="col-4 content-commerce">
                {this.adobeEcosystemData.primary.content_part_three.text}
              </div>
            </div>
          )}
          {!this.state.isMobileView && (
            <div className="row">
              <img
                src={this.adobeEcosystemData.primary.content_image.url}
                alt="gspann logo"
                className="d-md-block"
              />
            </div>
          )}
          {this.state.isMobileView && (
            <div className="row ml-0 mr-0 d-inline">
              <img
                src={this.adobeEcosystemData.primary.content_image_mobile.url}
                alt="gspann logo"
                className="d-md-block"
              />
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default AdobeEcosystem;
