import React, { Component } from "react";
import _debounce from 'lodash.debounce';
import {
    Collapse,
  } from "reactstrap";
import axios from "axios";
import '../styles/search-form-modal.css';

export default class SearchFormModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            value: '',
            isOpen: false,
            searchData: {}
        };
        this.debounceForSuggestions = this.debounceForSuggestions.bind(this);
    }
    
    closeSearch = () => {
        this.setState({
            value: '',
            isOpen: false
        });
        this.props.hideSearch();
    };

    getSuggestion(searchTerm) {
        let requestObject = {
            query: searchTerm,
            site: 'www.gspann.com',
            limit: 100
          }
      
        axios.get(`https://api.sitesearch360.com/sites/suggest`, {params: requestObject})
        .then(res => {
            this.setState({searchData:res.data})
        })
        .catch(err => {
        console.log(err);
        });
    }

    debounceForSuggestions = _debounce(() => {
    clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
        this.setState({isOpen: true});
        this.getSuggestion(this.state.value);
    }, 200);
    }, 100);

    handleSearchEnter = (event) =>{
        if(this.state.value.length === 0) {
            this.setState({isOpen: false});
        }
        if(this.state.value.length >= 3) {
            this.debounceForSuggestions();
        }
        if (event.keyCode === 13) {
            // Cancel the default action
            event.preventDefault();
            // Trigger the button element with a click
            this.goToSearchPage();
        }
    }

    goToSearchPage = () =>{
        if(this.state.value){
            window.location.href =  `/search?q=${this.state.value}`;
            sessionStorage.setItem('ss360Query', this.state.value);
            sessionStorage.setItem('pageNum', 1);
        }
    }

    displayMatches(text, searchTerm) {
        var regex = new RegExp(searchTerm, 'gi')
        var response = text.replace(regex, function(str) {
            return str.bold();
        })
        return response; 
    }

    render() {
        let result = [];
let keys=["Jobs","Blogs","Documents","Platforms","Services"]
        for (var key in this.state.searchData.suggests) {
          keys.forEach(ele=>{
              if(ele === key){
                let content = (
                    <div className="suggestionBoxContentGroupContainer" key={key}>
                    {/* <div className='suggestionBoxContentGroup'>{key}</div> */}
                    {
                        this.state.searchData.suggests[key].filter((item, index) => index < 2).map(item => {
                            return (
                                <div className="searchItemContainer" key={item.name}>
                                    <div className="search-link-container">
                                        <a href={item.link} className="suggestionBoxContentLink" dangerouslySetInnerHTML={{
                                        __html: this.displayMatches(item.name, this.state.searchData.query)
                                        }} hrefLang="en"></a>
                                    </div>
                                </div>
                            )
                        })
                    }
        
                   
                    </div>
                    
                )

                result.push(content);
              }
          })
        
       
        }

        return (
            <div className={!this.props.openSearchModal ? 'hideModal' : 'showModal'} id="search360">
                <div className="searchFormModalContainer">
                    <div className="searchInputContainer">
                        <input 
                        type="text"
                        className="ss360SearchBox"
                        autoComplete="off"
                        placeholder="What are you looking for?"
                        onChange = { (e) => this.setState({value: e.target.value})}
                        onKeyUp = { e => this.handleSearchEnter(e) }
                        value = {this.state.value}
                        id="searchBox"
                        ref={inputElement => {
                            // constructs a new function on each render
                            if (inputElement) {
                              inputElement.focus();
                            }
                          }}
                        />
                        <span size="sm" className="searchBoxSearchBtn" onClick={this.goToSearchPage}>
                          <i className="fas fa-search d-inline-block d-md-none"></i>
                        </span>
                        <div className="search-close-icon-container">
                            <button className="closeSearchModal" onClick={this.closeSearch}>
                                <i className="fal fa-times closeSearchModal"></i>
                            </button>
                        </div>
                    </div>
                    <div style={{width: '621px'}}>
                        <Collapse isOpen={this.state.isOpen} className="searchBoxCollapse">
                            <div className="suggestionBoxContainer">
                                {result}
                                {
                
                <button className="seachLinkBtn" onClick={this.goToSearchPage}>See all results</button>
                
            }
                            </div>
                        </Collapse>
                    </div>
                </div>
            </div>
        );
        if (!this.props.openSearchModal) {
            return null;
        }
    }
}