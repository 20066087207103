import { Component } from "react";
import PropTypes from 'prop-types';
import "../styles/adobeJourney.css";

export default class AdobeJourney extends Component {
  constructor(props) {
    super(props);
    this.AdobeJourneyData = props.input;
    this.linksData = props.pagesLinksData;
  }

  render() {
    let carouselCardElements = [];

    this.AdobeJourneyData.items.forEach((element, key) => {
      let cardImageElement = (
        <img
          src={element.adobejourenyimg.url}
          alt="header image"
          className="group"
        />
      );

      let descriptionElement = (
        <div className="journey_heading">
          <br></br>
          <div className="img_heading" ><b>{element.adobejourneyimgheading.text}</b></div>

          <br></br>
          <p className="img_desc" >{element.adobejourneyimgdesc.text}</p>
        </div>
      )

      carouselCardElements.push(<div className="group_top col-12 col-md-6" key={key}>
        {cardImageElement}
        {descriptionElement}
      </div>);
    });
    return (

      <div  >
        <div className="container heading-section-container"  >
          <div className="head_desc_dev">
            <h2 className="page_heading1">{this.AdobeJourneyData.primary.adobejourneyheader.text}</h2>

            <div className="page_desc" dangerouslySetInnerHTML={{
              __html: this.AdobeJourneyData.primary.adobejourneydesc.text

            }} /></div>

          <div className="img_head_desc py-4 row">
            {carouselCardElements}
          </div>

        </div>

      </div>
    )

  }
}


AdobeJourney.propTypes = {
  input: PropTypes.object,
  pagesLinksData: PropTypes.array
};