import React, { Component } from "react";
import PropTypes from "prop-types";
import "../styles/testimonial.css";
import { navigate } from "gatsby";
import ContentSlideshow from "./ContentSlideshow";
import {getColor, createUrl} from "../utils/universalHelperFunctions";
import BackgroundImage from 'gatsby-background-image';

class Testimonial extends Component{
  constructor(props) {
    super(props);
    this.state = {
      sliderHeight: 'auto',
    }
    this.linksData = props.pagesLinksData;
    this.testimonialPrimaryData = this.props.input.primary;
    this.handleFindJobBtn = this.handleFindJobBtn.bind(this);
    this.setMaxHeightToCarouselOverlay = this.setMaxHeightToCarouselOverlay.bind(this);
  }

  componentDidMount() {
    let maxHeight = this.setMaxHeightToCarouselOverlay();
    this.setState({ sliderHeight: maxHeight});
    window.addEventListener('resize', (e) => {
      this.setState({'resized': true});
    });
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    let maxHeight = this.setMaxHeightToCarouselOverlay()
    if(maxHeight !== prevState.sliderHeight) {
      this.setState({ sliderHeight: maxHeight});
    }
  }

  handleFindJobBtn(event, buttonLink) {
    event.preventDefault();
    history.pushState(null, null, createUrl(buttonLink.url, this.linksData));
    navigate(createUrl(buttonLink.url, this.linksData ));
  }

  setMaxHeightToCarouselOverlay() {
    let carouselItems = document.querySelectorAll('.testimonial-container-layer');
    let heights = [];
    [...carouselItems].forEach(carouselItem => {
      heights.push(carouselItem.clientHeight);
    });

    return Math.max(...heights);
  }

  render() {
    let id = "";
    if(this.testimonialPrimaryData.hasOwnProperty("breadcrumb_id")) {
      id = this.testimonialPrimaryData.breadcrumb_id.text;
    }

    let slides = this.props.input.items.map((item, index) => {
      const testimonialFontColor = item.testimonials_content_font_color
        ? getColor(item.testimonials_content_font_color)
        : "#000";
      const testimonialButtonBackground = item.testimonials_button_background_color
        ? getColor(item.testimonials_button_background_color)
        : "#f1f5f6ee";
      const testimonialButtonFontColor = item.testimonials_button_font__color
        ? getColor(item.testimonials_button_font__color)
        : "#000";
    
      const sources = [];
      if(item.testimonials_image.MobileHDPI && item.testimonials_image.MobileHDPI.localFile) {
        sources.push({
          ...item.testimonials_image.MobileHDPI?.localFile?.childImageSharp?.fluid,
          media: `(max-width: 767px)`
        })
      }
      if(item.testimonials_image.Tablet && item.testimonials_image.Tablet.localFile) {
        sources.push({
          ...item.testimonials_image.Tablet?.localFile?.childImageSharp?.fluid,
          media: `(min-width: 768px) and (max-width: 1023px)`,
        });
      }
      if(item.testimonials_image && item.testimonials_image.localFile) {
        sources.push({
          ...item.testimonials_image?.localFile?.childImageSharp?.fluid,
          media: `(min-width: 1024px)`,
        });
      }

      return (
        <BackgroundImage
          Tag="div"
          fluid={sources}
          className="testimonial-container"
          style={{position: 'inherit'}}
          key={item.author_position && item.author_position ? item.author_position.text : index}
        >
          <div className="testimonial-container-overlay" style={this.state.sliderHeight ? { minHeight: this.state.sliderHeight } : {}}>
            <div className="testimonial-container-layer">
              {
                item.testimonials_heading && item.testimonials_heading.text && (
                  <p className="quote-text" style={{color: testimonialFontColor}}>
                    {item.testimonials_heading.text}
                  </p>
                )
              }
              {
                item.author_position && item.author_position.text && (
                  <p className="quote-author" style={{color: testimonialFontColor}}>
                    {item.author_position.text}
                  </p>
                )
              }
              {
                item.testimonials_button_link && item.testimonials_button_link.url && (
                  <button 
                    className="card-button find-job-btn"
                    onClick={event => this.handleFindJobBtn(event, item.testimonials_button_link)}
                    style={{color: testimonialButtonFontColor, backgroundColor: testimonialButtonBackground}}
                  >
                    {item.testimonials_button_text.text}
                  </button>
                )
              }
            </div>
          </div>
        </BackgroundImage>
      )
    });

    return (
      <div id={id} className="testimonial-wrapper">
        <ContentSlideshow slides={slides}/>
      </div>
    );
  }
}
export default Testimonial;

Testimonial.propTypes = {
    input: PropTypes.object,
    pagesLinksData: PropTypes.array
};


